@charset "UTF-8";
::-webkit-scrollbar {
  width: 6px;
  /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent;
  /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #16a2e8;
  /* color of the scroll thumb */
  border-radius: 2px;
  /* roundness of the scroll thumb */
}

::placeholder {
  color: #EDEDED;
  font-weight: 300;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.dash-spinner-32x32 {
  animation: dash-spinner-32x32 0.2s steps(3) infinite;
  display: block;
  width: 32px;
  height: 32px;
  background-image: url("@Assets/loading.png");
}

@keyframes dash-spinner-32x32 {
  from {
    background-position: 0px 0px;
  }
  to {
    background-position: 0px -96px;
  }
}
/* Initial styles: you can get rid of these! */
body {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  color: #505050;
  font-size: 16px;
  overflow: hidden;
}
@media (display-mode: browser) {
  body .pwa-isntall-btn {
    display: flex !important;
  }
}
body ::-webkit-scrollbar {
  width: 2px;
  /* width of the entire scrollbar */
}
@keyframes bouncePress {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(0.92);
    opacity: 0.5;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
body a, body button, body image, body .btn, body i, body h2, body .category-item {
  position: relative;
}
body a:active, body button:active, body image:active, body .btn:active, body i:active, body h2:active, body .category-item:active {
  animation: bouncePress 0.2s 1 linear !important;
}
body #cta-whatsapp {
  width: 48px;
  height: 48px;
  background-color: #25d366;
  box-shadow: 1px 6px 24px 0 rgba(0, 0, 0, 0.75);
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: sticky;
  bottom: 10px;
  z-index: 1;
  left: 10px;
}
body .fixed {
  position: fixed;
  bottom: 30px;
  right: 0px;
  background-color: #000;
  color: #FFF;
  text-align: center;
  font-size: 12px;
  padding: 5px 10px;
  z-index: 9999;
}
body .btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 34px;
  width: 100%;
  border-radius: 29px;
  background-color: #EDEDED;
  font-size: 16px;
}
body .btn.primary {
  background-color: #EB6B2F;
  color: #FFF;
}
body .btn.secondary {
  background-color: #1289C5;
  color: #FFF;
}
body .btn.tertiary {
  background-color: #fff;
  border: 1px solid #1289C5;
  color: #1289C5;
}
body .btn.link {
  background-color: transparent;
  color: #1289C5;
  text-decoration: underline;
  margin-top: 10px;
}
body .Toastify .Toastify__toast {
  padding: 4px;
}
body .Toastify .Toastify__toast-body {
  font-size: 12px;
  padding: 0px 4px;
}
body .Toastify .Toastify__progress-bar {
  height: 3px;
}
body #closer-store-warning {
  background: #FFF1EB;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #f2f2f2;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -60px;
  color: #6C2300;
  font-size: 14px;
  width: auto;
}
body #closer-store-warning b {
  display: contents;
  font-weight: 500;
  padding: 5px 5px;
  cursor: pointer;
}
body #closer-store-warning b:hover {
  background-color: #FFF;
  border-radius: 4px;
}
body #closer-store-warning button {
  position: absolute;
  right: 40px;
}
body #loading {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
body #loading.fullsize {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  opacity: unset;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 9999;
}
body #modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.75);
  cursor: pointer;
}
body #modal .close-modal {
  color: #fff !important;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 10px;
  right: 10px;
}
body #modal .modal-confirm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
  background-color: #FFF;
  width: 300px;
  min-height: 100px;
  border-radius: 16px;
}
body #modal .modal-confirm h2 {
  display: flex;
  font-size: 18px;
  text-align: center;
  margin-bottom: 32px;
}
body #modal .modal-confirm h3 {
  display: flex;
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  color: #000;
  margin-bottom: 30px;
}
body #modal .modal-confirm .buttons-holder {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}
body #modal .modal-confirm .buttons-holder .btn {
  width: 50%;
  margin-bottom: 10px;
}
body #modal .modal-confirm .buttons-holder .btn:last-child {
  margin: 0px;
  height: unset;
}
body #modal .modal-location {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 20px;
  background-color: #FFF;
  width: 300px;
  min-height: 100px;
  border-radius: 16px;
}
body #modal .modal-location svg {
  margin-bottom: 20px;
}
body #modal .modal-location h2 {
  display: flex;
  font-size: 18px;
  text-align: center;
  margin-bottom: 32px;
}
body #modal .modal-location .btn {
  margin-bottom: 15px;
  height: 58px;
  width: 80%;
}
body #modal .modal-location .btn:last-child {
  margin-bottom: 0px;
}
body #modal .modal-location small {
  font-size: 12px;
  margin-top: 20px;
}
body #modal .modal-location small b {
  font-weight: 500;
}
body #empty {
  display: block;
  background-color: #FFF;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  padding: 40px 0px;
  height: auto;
}
body #empty h1 {
  color: #00000035;
  height: 34px;
  line-height: 34px;
  display: block;
}
body #empty img {
  height: 200px;
}
body .box {
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgba(0, 69, 104, 0.25);
  border-radius: 8px;
  overflow: hidden;
}
body #flyout {
  display: flex;
  position: fixed;
  padding-top: 110px;
  cursor: pointer;
  width: 100%;
  height: 100vh;
}
body #flyout::after {
  content: " ";
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  width: 100%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.75);
}
body #flyout .content {
  position: relative;
  background-color: #ffff;
  width: 100%;
  height: 520px;
  cursor: auto;
  padding-left: 32px;
  padding-right: 32px;
  z-index: 1;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  padding-top: 40px;
}
body .flyout-categories {
  display: flex;
  flex-direction: column;
}
body .flyout-categories h2 {
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 35px;
}
body .flyout-categories h2 svg {
  display: none;
}
body .flyout-categories .categories-and-promos {
  display: flex;
  flex-direction: row;
}
body .flyout-categories .categories-and-promos .holder-categories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
body .flyout-categories .categories-and-promos .holder-categories p {
  display: flex;
  font-size: 16px;
  line-height: 20px;
  padding-right: 32%;
  margin-bottom: 60px;
}
body .flyout-categories .categories-and-promos .holder-categories .item {
  display: flex;
  flex-direction: row;
  width: 280px;
  margin-bottom: 60px;
  align-items: center;
  cursor: pointer;
}
body .flyout-categories .categories-and-promos .holder-categories .item .img-holder {
  display: flex;
  width: 70px;
  height: 70px;
  border-radius: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}
body .flyout-categories .categories-and-promos .holder-categories .item label {
  display: flex;
  margin-left: 15px;
  font-size: 21px;
  cursor: pointer;
}
body .flyout-collections {
  display: flex;
  flex-direction: column;
}
body .flyout-collections h2 {
  font-weight: 500;
  font-size: 28px;
  margin-bottom: 10px;
}
body .flyout-collections h2 svg {
  display: none;
}
body .flyout-collections p {
  display: flex;
  font-size: 18px;
  line-height: 24px;
  padding-right: 32%;
}
body .flyout-collections .items {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  gap: 40px;
}
body .flyout-collections .items button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
body .flyout-collections .items button::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 20, 39, 0.55);
  content: " ";
}
body .flyout-collections .items button span {
  color: #FFFFFF;
  position: relative;
  font-size: 20px;
  font-weight: 500;
}
body .flyout-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}
body .flyout-search .content-area {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100vw - 200px);
  align-self: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 710px;
  margin-bottom: 20px;
  justify-content: flex-start;
}
body .flyout-search .content-area .materials-and-colors {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
body .flyout-search .content-area .materials-and-colors .search-materials {
  width: 60%;
}
body .flyout-search .content-area .materials-and-colors .search-colors {
  width: 30%;
}
body .flyout-search .content-area .buttons-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
  align-self: center;
  margin-top: 10px;
}
body .flyout-search .content-area .buttons-holder .primary {
  height: 50px;
}
body #promos-swiper {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 440px;
}
body #promos-swiper .controls {
  display: flex;
  padding: 0px;
  margin: 0px;
}
body #promos-swiper .slider {
  padding: 0px 20px;
}
body #promos-swiper .slider .slide-item {
  padding-bottom: 10px;
}
body #promos-swiper .slider .slide-item .card {
  display: flex;
  flex-direction: column;
  background: linear-gradient(180deg, #000000 24.23%, #303030 77.85%);
  border-radius: 8px;
  padding: 40px 32px;
  color: #FFFFFF;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
}
body #promos-swiper .slider .slide-item .card .img-holder {
  height: 126px;
  width: 100%;
  background-image: url("@Assets/promo-thumb.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 40px;
}
body #promos-swiper .slider .slide-item .card .texts-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
body #promos-swiper .slider .slide-item .card .texts-holder h2 {
  font-size: 16px;
  font-weight: 300;
  margin: 0px;
  margin-bottom: 10px;
  width: 100%;
}
body #promos-swiper .slider .slide-item .card .texts-holder big {
  display: flex;
  font-size: 64px;
  font-weight: 700;
  width: 45%;
}
body #promos-swiper .slider .slide-item .card .texts-holder span {
  display: flex;
  font-size: 16px;
  font-weight: 700;
  margin-left: 10px;
  width: 50%;
}
body #promos-swiper .slider .slide-item .card button {
  display: flex;
  flex-direction: row;
  justify-self: center;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 58px;
  background-color: #fff;
  border-radius: 29px;
  text-align: center;
  color: #000;
  margin-top: 30px;
  padding: 0px 24px;
}
body #promos-swiper .slider .swiper-pagination .swiper-pagination-bullet {
  background-color: #CCCCCC;
  width: 40px;
  border-radius: unset;
  height: 4px;
}
body #promos-swiper .slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #0686C9;
}
body #promos-swiper.compact {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 80px;
}
body #promos-swiper.compact .slider {
  width: 410px;
}
body #promos-swiper.compact .slider .slide-item .card {
  height: 88px;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  padding: 0px;
  align-items: center;
}
body #promos-swiper.compact .slider .slide-item .card .img-holder {
  width: 140px;
  height: 80px;
  margin: 0px;
}
body #promos-swiper.compact .slider .slide-item .card .texts-holder {
  margin: 0px;
  width: calc(100% - 150px);
}
body #promos-swiper.compact .slider .slide-item .card .texts-holder h2 {
  font-size: 12px;
}
body #promos-swiper.compact .slider .slide-item .card .texts-holder big {
  font-size: 35px;
  width: 35%;
}
body #promos-swiper.compact .slider .slide-item .card .texts-holder span {
  font-size: 12px;
  font-weight: 300;
  width: 58%;
}
body #promos-swiper.compact .slider .slide-item .card button {
  display: none;
}
body #stores {
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(0, 69, 104, 0.1);
  border-radius: 16px;
  display: flex;
  width: 400px;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 18px;
  align-self: center;
  align-items: center;
  position: relative;
}
body #stores .btn-radio {
  position: absolute;
  right: 15px;
}
body #stores p {
  margin: 16px 0px;
  font-size: 13px;
}
body #stores h2 {
  font-weight: 400;
  font-size: 19px;
  margin-bottom: 16px;
}
body #stores .btn {
  font-size: 13px;
  margin-bottom: 12px;
  width: 65%;
}
body #stores .btn.default {
  background-color: #F5F9FA;
}
@keyframes slideFromRight {
  0% {
    transform: translateX(200%);
  }
  100% {
    transform: translateX(0);
  }
}
body #side-panel {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100vh;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  cursor: pointer;
}
body #side-panel .side-content {
  animation: 0.15s ease-in-out 0s 1 slideFromRight;
  background-color: #fff;
  width: 445px;
  box-shadow: -8px 8px 8px rgba(0, 0, 0, 0.25);
  cursor: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
body #side-panel .side-content .close-btn {
  display: none;
}
body #side-panel .button-helper {
  animation: 0.15s ease-in-out 0s 1 slideFromRight;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  height: 34px;
  width: 160px;
}
body #side-panel .button-helper span {
  display: flex;
  margin-left: 10px;
}
body .btn-radio {
  display: flex;
  border-radius: 100%;
  width: 18px;
  height: 18px;
  align-items: center;
  justify-content: center;
  border: 1px solid #898989;
  margin: 0px;
  padding: 0px;
}
body .btn-radio.selected {
  border-color: #007FC7;
}
body .btn-radio.selected::after {
  content: " ";
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #007FC7;
}
body .table-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 1;
  margin-top: -45px;
  padding-left: 140px;
}
body .table-row .table-col {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}
body .table-row .table-col label {
  font-size: 14px;
  margin-bottom: 7px;
}
body .table-row .table-col span {
  font-size: 16px;
  font-weight: 400;
}
body .table-row .table-col:first-child span {
  color: #34C240;
}
body .table-row .table-col:last-child span {
  color: #EB6B2F;
}
body .custom-collpaser {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}
body .custom-collpaser .collapser-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  cursor: pointer;
}
body .custom-collpaser .collapser-header .content {
  display: flex;
  width: 95%;
}
body .custom-collpaser .collapser-header .content .table-row {
  margin: 0px;
}
body .custom-collpaser .collapser-header button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  transition: all 0.35s;
  margin: 0px;
  padding: 0px;
  margin-right: -5px;
}
body .custom-collpaser .collapser-content {
  height: 0px;
  transition: all 0.15s;
  overflow: hidden;
}
body .custom-collpaser .collapser-content.open {
  height: unset;
}
body .overlay-layer {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
body .custom-input, body .custom-textarea, body .custom-file {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 21px;
}
body .custom-input label, body .custom-textarea label, body .custom-file label {
  color: "#202020";
  margin-bottom: 8px;
  font-size: 14px;
}
body .custom-input .field-wrapper, body .custom-textarea .field-wrapper, body .custom-file .field-wrapper {
  display: flex;
  flex-direction: row;
  border: 1px solid #CDEDFF;
  background-color: #FFF;
  height: 40px;
  border-radius: 8px;
  align-items: center;
  padding: 0px 21px;
  overflow: hidden;
}
body .custom-input .field-wrapper input, body .custom-input .field-wrapper select, body .custom-input .field-wrapper textarea, body .custom-textarea .field-wrapper input, body .custom-textarea .field-wrapper select, body .custom-textarea .field-wrapper textarea, body .custom-file .field-wrapper input, body .custom-file .field-wrapper select, body .custom-file .field-wrapper textarea {
  width: 100%;
  display: flex;
  flex: 1;
  background-color: transparent;
  border: none;
  color: #202020;
}
body .custom-input .field-wrapper select, body .custom-textarea .field-wrapper select, body .custom-file .field-wrapper select {
  width: 140% !important;
}
body .custom-input .field-wrapper svg, body .custom-textarea .field-wrapper svg, body .custom-file .field-wrapper svg {
  cursor: pointer;
}
body .custom-input.error .field-wrapper, body .custom-textarea.error .field-wrapper, body .custom-file.error .field-wrapper {
  border: 2px solid #CF0043;
  background-color: #FFF8F9;
}
body .custom-input.error input, body .custom-textarea.error input, body .custom-file.error input {
  color: #CF0043;
}
body .custom-input.success .field-wrapper, body .custom-textarea.success .field-wrapper, body .custom-file.success .field-wrapper {
  border: 2px solid #16AC69;
  background-color: #FFF8F9;
}
body .custom-input.success input, body .custom-textarea.success input, body .custom-file.success input {
  color: #2B8519;
}
body .custom-textarea .field-wrapper {
  height: 100px;
}
body .custom-textarea .field-wrapper textarea {
  height: 80%;
  resize: none;
  padding: 0px;
  line-height: 18px;
}
body .custom-checkbox {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 21px;
  cursor: pointer;
}
body .custom-checkbox .chkbox {
  display: flex;
  width: 14px;
  height: 14px;
  border: 1px solid #898989;
  border-radius: 4px;
  margin-right: 11px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
body .custom-checkbox span {
  color: #505050;
}
body .custom-checkbox.checked .chkbox {
  background-color: #007FC7;
  border: 1px solid #007FC7;
}
body .custom-checkbox.checked span {
  color: #007FC7;
}
body .custom-radio {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin-bottom: 21px;
  cursor: pointer;
}
body .custom-radio .radio {
  display: flex;
  width: 18px;
  height: 18px;
  border: 1px solid #898989;
  margin-right: 11px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}
body .custom-radio span {
  color: #505050;
}
body .custom-radio input[type=radio] {
  display: none;
}
body .custom-radio.checked .radio {
  border: 1px solid #007FC7;
}
body .custom-radio.checked .radio span {
  color: #007FC7;
}
body .custom-radio.checked .radio::after {
  content: " ";
  width: 14px;
  height: 14px;
  background-color: #007FC7;
  border-radius: 100%;
}
body .linex-switch {
  border: 1px solid #898989;
  display: inline-flex;
  border-radius: 29px !important;
  margin: 0px !important;
}
body main {
  max-height: calc(100vh - 0px);
  overflow: auto;
  overflow-x: hidden;
}
body header {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transition: top 0.5s ease-in-out;
  height: 110px;
  z-index: 2;
  border-bottom: 1px solid #00000010;
  background-color: #FFF;
}
body header.hidden {
  top: -110px;
}
body header .gray-bar {
  padding-left: 32px;
  padding-right: 32px;
  background-color: #F9FCFE;
  display: flex;
  flex-direction: row;
  height: 34px;
  align-items: stretch;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
}
body header .gray-bar span {
  display: flex;
  font-size: 16px;
  align-items: center;
}
body header .gray-bar .global-back {
  display: flex;
  align-items: center;
  padding: 0px;
  margin: 0px;
  margin-right: 20px;
}
body header .gray-bar .global-back svg {
  width: calc(34px - 17px) !important;
  height: calc(34px - 17px) !important;
  box-shadow: 0px 0px 12px rgba(0, 69, 104, 0.25);
  border-radius: 100%;
  padding: 5px;
  color: #1289C5;
}
body header .gray-bar .buttons-holder {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-end;
  flex-basis: 60%;
}
body header .gray-bar .buttons-holder button {
  background-color: transparent;
  border: none;
  padding: 0px;
  margin: 0px;
  font-size: 16px;
  display: flex;
  margin-left: 30px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
}
body header .gray-bar .buttons-holder button.logged-in {
  display: flex;
  flex-direction: row;
  background-color: #EDEDED;
  padding: 0px 20px 0px 20px;
  border-radius: 20px;
  position: relative;
}
body header .gray-bar .buttons-holder button.logged-in .auth-initials {
  width: 32px;
  height: 32px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-radius: 100%;
  background-color: #EB6B2F;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: -10px;
}
body header .gray-bar .buttons-holder button span {
  display: inline-block;
  margin-left: 10px;
}
body header .gray-bar .buttons-holder label {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 30px;
  cursor: pointer;
}
body header .gray-bar .buttons-holder label span {
  display: inline-block;
  margin-left: 10px;
}
body header nav {
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #FFF;
  padding-top: 5px;
  padding-bottom: 5px;
}
body header nav .logo-holder {
  display: inline-flex;
  width: 243px;
  height: 45px;
  background-image: url("@Assets/logo.png");
}
body header nav section {
  display: flex;
  flex-direction: row;
  align-items: center;
}
body header nav section a {
  font-size: 16px;
  margin-left: 30px;
  cursor: pointer;
  text-decoration: none;
  color: #505050;
  padding: 4px 4px;
}
body header nav section a.in-outlet {
  color: #FF7433;
  background-color: #F2F2F2;
  border-radius: 4px;
}
body header nav section button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
  /* Hides icons that appears only in mobile */
}
body header nav section button:first-of-type {
  margin-left: 70px;
}
body header nav section button.cart-icon {
  position: relative;
}
body header nav section button.cart-icon span {
  width: 25px;
  height: 25px;
  background-color: #EB6B2F;
  color: #FFF;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 11px;
  position: absolute;
  bottom: -5px;
  left: -5px;
}
body header nav section button .auth-initials {
  width: 32px;
  height: 32px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-radius: 100%;
  background-color: #EB6B2F;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
body header nav section button:nth-last-child(1), body header nav section button:nth-last-child(2), body header nav section button:nth-last-child(4) {
  display: none;
}
body .product-gallery {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  overflow: hidden;
}
body .product-gallery img {
  height: 200px;
}
body .product-discount {
  background-color: #EB6B2F;
  color: #FFF;
  height: 24px;
  width: 48px;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
body .product-variants {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid #F2F2F2;
  width: 100%;
  justify-content: flex-start;
}
body .product-variants label {
  margin-right: 20px;
}
body .product-variants .variants {
  display: flex;
  flex-direction: row;
  padding: 10px 10px;
  z-index: 0;
}
body .product-variants .variants .swiper-slide {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  line-height: unset;
  position: relative;
  padding: 0px;
  border-radius: 100%;
  cursor: pointer;
  border: 1px solid #F2F2F2;
  font-size: 12px;
}
body .product-variants .variants .swiper-slide.selected {
  color: #EB6B2F;
  font-weight: 500;
}
body .product-variants .variants .swiper-slide.selected::before {
  position: absolute;
  content: " ";
  border: 2px solid #1289C5;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  top: -6px;
  left: -6px;
}
body .product-prices {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
}
body .product-prices big {
  font-size: 32px;
  font-weight: 700;
  width: 70%;
  display: flex;
  flex-direction: row;
}
body .product-prices small {
  font-size: 18px;
  text-decoration: line-through;
  width: 100%;
  margin-top: 16px;
}
body .product-prices .product-discount {
  display: none;
}
body .product-hearth {
  display: inline-flex;
  width: 10%;
  justify-content: flex-end;
}
body .product-hearth svg {
  cursor: pointer;
}
body .product-texts {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}
body .product-texts span {
  color: #EB6B2F;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
body .product-texts h2 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  margin-bottom: 16px;
  display: flex;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  height: 35px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
body .product-texts h3 {
  font-size: 16px;
  display: flex;
  width: 100%;
  margin-bottom: 14px;
}
body .product-texts a {
  color: #1289C5;
  font-size: 14px;
  display: none;
}
body .product-texts .product-hearth {
  display: none;
}
body .product-increaser {
  display: flex;
  flex-direction: column;
}
body .product-increaser label {
  font-size: 14px;
  display: none;
  margin-bottom: 10px;
}
body .product-increaser .container {
  display: flex;
  flex-direction: row;
  border: 1px solid #C4C4C4;
  border-radius: 400px;
  height: 58px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 12px;
  cursor: auto;
}
body .product-increaser .container button {
  margin: 0px;
  padding: 0px;
  width: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
}
body .product-increaser .container button svg {
  margin: 0px;
  padding: 0px;
}
body .product-increaser .container span {
  font-weight: 500;
  font-size: 18px;
}
body .product-buttons button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  border-radius: 64px;
  padding: 0px 22px;
  font-size: 18px;
}
body .product-buttons button small {
  font-size: 16px;
  font-weight: 200;
}
body .product-buttons a {
  color: #1289C5;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
body .product-buttons legend {
  display: none;
  flex-direction: row;
  justify-content: center;
  font-weight: 300;
  margin-top: 10px;
}
body .product-popup {
  display: flex;
  flex-direction: row;
  align-items: center;
}
body .product-popup .gallery {
  display: flex;
  background-color: #F9FCFE;
  box-shadow: 0px 0px 12px rgba(0, 69, 104, 0.25);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  width: 440px;
  height: 350px;
  align-items: center;
}
body .product-popup .gallery .product-gallery {
  display: flex;
  flex-direction: column;
  height: 95%;
  align-items: center;
  justify-content: center;
}
body .product-popup .gallery .product-gallery img {
  height: 100%;
}
body .product-popup .info {
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  box-shadow: 0px 0px 12px rgba(0, 69, 104, 0.25);
  border-radius: 12px;
  width: calc(410px - 48px);
  padding: 30px 24px;
  min-height: 350px;
}
body .product-popup .info .product-texts {
  border-bottom: 1px solid #F2F2F2;
  padding-bottom: 15px;
}
body .product-popup .info .product-texts span {
  margin-bottom: 25px;
  display: inline-flex;
}
body .product-popup .info .product-texts h2 {
  margin-bottom: 10px;
}
body .product-popup .info .product-texts h3 {
  margin-bottom: 10px;
}
body .product-popup .info .product-texts .product-hearth {
  display: flex;
}
body .product-popup .info .product-texts a {
  display: flex;
}
body .product-popup .info .product-prices {
  border-bottom: 1px solid #F2F2F2;
  padding: 15px 0px;
  margin: 0px;
}
body .product-popup .info .product-prices small {
  width: unset;
}
body .product-popup .info .product-prices .product-hearth {
  display: none;
}
body .product-popup .info .product-prices .product-discount {
  display: flex;
}
body .product-popup .info .product-variants {
  margin: 0px;
  padding: 15px 0px;
  border: none;
}
body .product-popup .info .product-increaser {
  margin: 23px 0px;
}
body .product-stars {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 15px;
}
body .product-stars svg {
  margin-right: 6px;
  cursor: pointer;
}
body .product-stars svg:hover {
  color: #F6C000 !important;
}
body .product-stars svg:hover ~ svg {
  color: #F6C000 !important;
}
body .product-item {
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  grid-column: span 1;
  box-shadow: 0px 4px 12px rgba(0, 69, 104, 0.25);
  border-radius: 8px;
  padding: 18px;
  align-items: center;
  text-align: left;
  position: relative;
  justify-content: space-between;
}
body .product-item .product-discount {
  position: absolute;
  top: 18px;
  right: 0px;
}
body .product-item .product-increaser-holder {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #1289c5;
  color: #1289c5;
  height: 34px;
  justify-content: center;
  padding: 0px 15px;
  max-width: calc(100% - 20px);
}
body .product-item .product-increaser-holder .product-increaser {
  display: none;
}
body .product-item .product-increaser-holder .product-increaser + .product-buttons button {
  height: unset;
  background-color: transparent;
  color: #007FC7;
}
body .product-item .product-increaser-holder .product-increaser + .product-buttons button small {
  display: none;
}
body .product-item .product-increaser-holder .product-increaser + .product-buttons button span {
  font-size: 14px;
}
body .product-item .product-increaser-holder .product-increaser + .product-buttons a {
  display: none;
}
body .product-item .product-increaser-holder.in-cart {
  background-color: #1289C5;
  justify-content: space-between;
}
body .product-item .product-increaser-holder.in-cart #loading {
  display: none;
}
body .product-item .product-increaser-holder.in-cart .product-increaser {
  display: flex;
  width: 100%;
}
body .product-item .product-increaser-holder.in-cart .product-increaser .container {
  height: unset;
  border: none;
  padding: 0px;
  border-radius: 0px;
}
body .product-item .product-increaser-holder.in-cart .product-increaser .container svg {
  width: 18px !important;
  height: 18px !important;
  color: #fff !important;
  border-radius: 100%;
  border: 1px solid #FFFFFF;
}
body .product-item .product-increaser-holder.in-cart .product-increaser .container span {
  font-size: 18px;
  color: #FFF;
  padding: 0px 2px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 4px;
}
body .product-item .product-increaser-holder.in-cart .product-increaser + .product-buttons {
  display: none;
}
body #home {
  padding-top: 110px;
  position: relative;
}
body #home .home-ribbon {
  background-color: #FF7433;
  color: #FFF;
  text-align: center;
  height: 30px;
  display: none;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}
body #home .home-banners {
  display: flex;
  cursor: pointer;
}
body #home .home-banners .banner-item {
  height: 450px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
body #home .home-categories {
  padding: 30px 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}
body #home .home-categories h2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  font-size: 32px;
  width: 100%;
}
body #home .home-categories .controllers {
  position: absolute;
  width: 100%;
  height: 35px;
  top: calc(50% + 5px);
}
body #home .home-categories .controllers button {
  position: absolute;
  left: 0px;
  cursor: pointer;
}
body #home .home-categories .controllers button:last-child {
  left: unset;
  right: 0px;
}
body #home .home-categories .slider {
  padding: 20px 0px;
  width: calc(100% - 80px);
}
body #home .home-categories .slider .swiper-wrapper .item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
body #home .home-categories .slider .swiper-wrapper .item .category-item {
  width: 120px !important;
  height: 120px !important;
  border-radius: 100%;
  background-position: center center;
  background-size: cover;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}
body #home .home-categories .slider .swiper-wrapper .item h2 {
  font-size: 18px;
  width: 100%;
  margin-top: 10px;
}
body #home .home-special {
  background-color: #E8F5FB;
  padding: 30px 0px;
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
body #home .home-special .column {
  display: flex;
  flex: 1;
  flex-direction: column;
}
body #home .home-special .column h2 {
  font-size: 32px;
  font-weight: 200;
  margin-bottom: 16px;
}
body #home .home-special .column p {
  font-size: 18px;
  line-height: 24px;
  font-weight: 200;
  margin-bottom: 16px;
}
body #home .home-special .column .btn {
  width: 250px;
  border: 1px solid #001427;
  height: 50px;
  color: #001427;
}
body #home .home-special .column .random-products {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 30px;
}
body #home .home-special .column .random-products .product-holder {
  width: 10vw;
  height: 10vw;
  background: #FFFFFF;
  box-shadow: 0px 8px 16px rgba(0, 69, 104, 0.25);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  cursor: pointer;
}
body #home .home-special .column .random-products .product-holder .img-holder {
  display: flex;
  flex: 1;
  border: 1px solid #F2F2F2;
  border-radius: 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90%;
}
body #home .home-promote {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
body #home .home-promote .texts {
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  flex-direction: column;
  flex: 1;
}
body #home .home-promote .texts h2 {
  font-size: 32px;
  font-weight: 200;
  margin-bottom: 16px;
}
body #home .home-promote .texts p {
  font-size: 16px;
  line-height: 18px;
  font-weight: 200;
  margin-bottom: 16px;
}
body #home .home-promote .texts .btn {
  width: 250px;
  height: 50px;
}
body #home .home-subanner {
  padding-left: 32px;
  padding-right: 32px;
  height: 330px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-image: url("@Assets/home/subanner/subanner.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right top;
  position: relative;
}
body #home .home-subanner::before {
  content: " ";
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 68.63%);
}
body #home .home-subanner .texts {
  position: relative;
  z-index: 1;
  width: 50%;
}
body #home .home-subanner .texts h2 {
  font-size: 24px;
  margin-bottom: 16px;
}
body #home .home-subanner .texts p {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 16px;
  color: #001427;
}
body #home .home-subanner .texts .btn {
  width: 180px;
  height: 50px;
}
body #home .related-products {
  background-color: #F9FCFE;
}
body #home .related-products .title-and-controllers {
  flex-wrap: wrap;
  position: relative;
}
body #home .related-products .product-related-swiper + .btn {
  width: 250px;
  height: 50px;
  margin-top: 20px;
}
body #home .home-promo {
  position: fixed;
  top: 200px;
  right: 0px;
  box-shadow: 2px 2px 4px rgba(108, 35, 0, 0.16);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  z-index: 10;
  width: 400px;
  background-color: #FFFBFA;
  left: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 380px;
}
body #home .home-promo .band {
  background: #EB6B2F;
  overflow: hidden;
  width: 30px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  cursor: pointer;
}
body #home .home-promo .band .text {
  color: #FFF;
  font-size: 14px;
  transform: rotate(-90deg);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 300px;
  position: absolute;
  top: 0px;
  bottom: 0px;
}
body #home .home-promo .band .text svg {
  margin-left: 10px;
  margin-bottom: -5px;
  transform: rotate(180deg);
}
body #home .home-promo .content {
  display: flex;
  width: calc(100% - 30px);
  padding: 20px 20px 20px 50px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
body #home .home-promo .content .promo-applier {
  max-width: unset;
  background-color: transparent;
  border-radius: unset;
  padding: 0px;
  display: flex;
}
body #home .home-promo .content .promo-applier h2 {
  line-height: 28px;
}
body #home .home-promo .content .promo-applier .custom-input .field-wrapper {
  padding-right: 0px;
}
body #home .home-promo .content .promo-applier .custom-input .field-wrapper input[type=text] {
  text-transform: uppercase;
}
body #home .home-promo .content .promo-applier .custom-input .field-wrapper .btn {
  width: 120px;
  padding: 0px;
}
body #home .home-promo.open {
  left: unset;
  right: 0px;
}
body #home .home-promo.open .band .text svg {
  transform: unset;
}
body #about {
  padding-top: 110px;
}
body #about .about-banners {
  display: flex;
  margin-bottom: 27px;
}
body #about .about-banners .banner-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 559px;
  background: linear-gradient(90deg, #f1edec 60%, white 70%);
}
body #about .about-banners .banner-item .img-holder {
  display: flex;
  flex-direction: column;
  background-position: top left;
  background-repeat: no-repeat;
  background-size: cover;
  width: 70%;
}
body #about .about-banners .banner-item .texts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  margin-left: 80px;
  padding-right: 40px;
}
body #about .about-banners .banner-item .texts h1 {
  display: inline-block;
  color: #EB6B2F;
  font-size: 5vw;
  font-weight: 200;
  letter-spacing: -0.4rem;
  position: relative;
  height: 120px;
  margin-bottom: 20px;
}
body #about .about-banners .banner-item .texts h1::after {
  position: absolute;
  content: attr(data-black);
  color: #505050;
  top: 40%;
  left: 25%;
}
body #about .about-banners .banner-item .texts h3 {
  font-size: 1.7vw;
  font-weight: 200;
  margin-bottom: 40px;
}
body #about .about-banners .banner-item .texts p {
  font-size: 1.2vw;
  margin-bottom: 15px;
  font-weight: 200;
}
body #about .about-vmv {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  margin-bottom: 48px;
  padding: 0px 20px;
}
body #about .about-vmv .item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 25rem;
}
body #about .about-vmv .item img {
  height: 70px;
  display: flex;
  margin-bottom: 20px;
}
body #about .about-vmv .item .texts {
  display: flex;
  flex-direction: column;
  align-items: center;
}
body #about .about-vmv .item .texts h2 {
  display: flex;
  color: #000;
  font-size: 2vw;
  margin-bottom: 15px;
}
body #about .about-vmv .item .texts p {
  font-size: 1.4vw;
  font-weight: 100;
  text-align: center;
  line-height: 2vw;
}
body #about .about-subanner {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 1;
  align-items: center;
}
body #about .about-subanner::after {
  content: " ";
  position: absolute;
  background: linear-gradient(269.07deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%);
  transform: rotate(-180deg);
  left: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
}
body #about .about-subanner .texts {
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  padding-left: 146px;
}
body #about .about-subanner .texts h2 {
  font-size: 2vw;
  font-weight: 200;
}
body #about .about-subanner .texts h1 {
  font-size: 4vw;
  color: #EB6B2F;
  margin-bottom: 20px;
}
body #about .about-subanner .texts p {
  font-size: 1.4vw;
  font-weight: 200;
  line-height: 2.2vw;
  margin-bottom: 20px;
  text-align: justify;
  padding-right: 40px;
}
body #about .about-subanner .texts h3 {
  font-size: 2vw;
  font-weight: 200;
}
body #about .about-subanner img {
  width: 50%;
}
body #about .about-punch {
  background-color: #F9FCFE;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 69px 0px;
  gap: 50px;
}
body #about .about-punch .closer {
  display: flex;
  flex-direction: column;
  width: 30%;
}
body #about .about-punch .closer h2 {
  font-size: 2.5vw;
  font-weight: 200;
  margin-bottom: 20px;
}
body #about .about-punch .closer p {
  font-size: 1.8vw;
  font-weight: 200;
  margin-bottom: 30px;
}
body #about .about-punch .closer .city-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
  padding: 0px;
}
body #about .about-punch .closer .city-item:last-child {
  margin-bottom: 0px;
}
body #about .about-punch .closer .city-item big {
  display: flex;
  font-size: 2.5vw;
  color: #EB6B2F;
  font-weight: 400;
  margin: 0px 15px 0px 10px;
}
body #about .about-punch .closer .city-item a {
  display: flex;
  color: #1289C5;
  font-size: 1vw;
  text-decoration: underline;
}
body #about .about-punch .likeit {
  display: flex;
  flex-direction: column;
  padding: 46px;
  background: #E8F5FB;
  border-radius: 24px;
  width: 30%;
  align-items: center;
}
body #about .about-punch .likeit h2 {
  font-size: 2vw;
  font-weight: 400;
  margin-bottom: 16px;
}
body #about .about-punch .likeit h3 {
  font-size: 1.7vw;
  line-height: 30px;
  font-weight: 200;
  text-align: center;
  margin-bottom: 15px;
}
body #about .about-punch .likeit .btn {
  height: 58px;
  width: 60%;
  margin: 0px;
  text-decoration: none;
}
body #catalog {
  display: flex;
  min-height: calc(100vh - 110px);
  flex-direction: column;
  background-color: #F9FCFE;
  padding-top: 110px;
}
body #catalog .banner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 180px;
  background-image: url("@Assets/catalog/general-banner.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}
body #catalog .banner span {
  font-size: 42px;
  color: #FFF;
  position: relative;
  border: 1px;
  text-shadow: 0.01em 0.01em black;
  z-index: 1;
}
body #catalog .banner::after {
  content: " ";
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(0, 69, 104, 0.85);
}
body #catalog .banner.is-outlet {
  background-image: url("@Assets/catalog/outlet-banner.png");
}
body #catalog .banner.is-outlet::after {
  display: none;
}
body #catalog .filters-and-sorters {
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  min-height: 50px;
  justify-content: center;
  position: sticky;
  top: 110px;
  z-index: 1;
  padding-top: 10px;
  transition: top 0.5s ease-in-out;
}
body #catalog .filters-and-sorters.hidden {
  top: 0px;
}
body #catalog .filters-and-sorters .content {
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
body #catalog .filters-and-sorters .content .other-filters {
  display: none;
}
body #catalog .filters-and-sorters .content .filters-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
}
body #catalog .filters-and-sorters .content .filters-holder h2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
}
body #catalog .filters-and-sorters .content .filters-holder h2 svg {
  display: none;
}
body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .item {
  display: flex;
  margin-left: 30px;
  position: relative;
}
body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .item button {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: 1px solid #F2F2F2;
  padding-right: 20px;
}
body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .item button svg {
  display: inline-flex;
  margin-left: 20px;
}
body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .item .box {
  position: absolute;
  width: 220px;
  max-height: 200px;
  overflow: auto;
  top: calc(100% + 5px);
  z-index: 1;
}
body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .item .box .custom-checkbox {
  height: 47px;
  margin: 0px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0px 14px;
  width: calc(100% - 28px);
  position: relative;
}
body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .item .box .custom-checkbox::after {
  position: absolute;
  height: 1px;
  content: " ";
  background-color: #F2F2F2;
  width: 90%;
  left: 5%;
  bottom: 0px;
}
body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .item .box .custom-checkbox .chkbox {
  margin: 0px;
}
body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .item .box .custom-checkbox:nth-child(2n) {
  background-color: #F9FCFE;
}
body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .item .box .custom-checkbox:last-child::after {
  display: none;
}
body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .item:last-of-type button {
  border-right: none;
  padding-right: 0px;
}
body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .linex-holder {
  display: none;
}
body #catalog .filters-and-sorters .content .filters-holder .filterable-items .buttons-mobile {
  display: none;
}
body #catalog .filters-and-sorters .content .sorters-holder button {
  display: flex;
  flex-direction: row;
  align-items: center;
}
body #catalog .filters-and-sorters .content .sorters-holder button svg:last-child {
  display: none;
}
body #catalog .filters-and-sorters .content .sorters-holder .box {
  position: absolute;
  width: 220px;
  max-height: 200px;
  overflow: auto;
  top: calc(100% + 5px);
  z-index: 1;
  right: 30px;
}
body #catalog .filters-and-sorters .content .sorters-holder .box .custom-radio {
  height: 47px;
  margin: 0px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0px 14px;
  width: calc(100% - 28px);
  position: relative;
}
body #catalog .filters-and-sorters .content .sorters-holder .box .custom-radio::after {
  position: absolute;
  height: 1px;
  content: " ";
  background-color: #F2F2F2;
  width: 90%;
  left: 5%;
  bottom: 0px;
}
body #catalog .filters-and-sorters .content .sorters-holder .box .custom-radio .chkbox {
  margin: 0px;
}
body #catalog .filters-and-sorters .content .sorters-holder .box .custom-radio:nth-child(2n) {
  background-color: #F9FCFE;
}
body #catalog .filters-and-sorters .content .sorters-holder .box .custom-radio:last-child::after {
  display: none;
}
body #catalog .filters-and-sorters .applied-filters {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 32px;
  padding-right: 32px;
}
body #catalog .filters-and-sorters .applied-filters .btn {
  width: auto;
  padding: 0px 15px;
  font-size: 12px;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}
body #catalog .catalog-items {
  padding-left: 32px;
  padding-right: 32px;
  display: grid;
  gap: 1.5rem;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fit, minmax(14rem, 0.25fr));
  padding-top: 40px;
  padding-bottom: 40px;
}
body #catalog .catalog-empty {
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  margin-top: 80px;
}
body #catalog .catalog-empty h2 {
  margin: 20px 0px;
}
body #catalog .catalog-empty .search-keyword {
  width: 100%;
}
body #catalog .catalog-empty .buttons-holder {
  width: 70%;
}
body #catalog .catalog-empty .buttons-holder .btn {
  height: 48px;
}
body #catalog .catalog-end {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  box-shadow: 0px 4px 12px rgba(0, 69, 104, 0.25);
  border-radius: 8px;
  padding: 18px;
}
body #catalog .catalog-end h2 {
  display: flex;
  color: #C4C4C4;
  line-height: 18px;
  text-align: center;
}
body #catalog .catalog-end img {
  display: flex;
  width: 40%;
  margin-bottom: 30px;
}
body #catalog .catalog-end .search-keyword {
  width: 90%;
}
body #catalog .catalog-end .search-keyword input {
  text-align: center;
  width: 100%;
}
body #catalog .load-more-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 80px;
  margin-top: 40px;
}
body #catalog .load-more-holder .btn {
  width: 200px;
}
body #catalog .load-more-holder .btn svg {
  margin-right: 10px;
}
body #detail {
  display: flex;
  flex-direction: column;
  padding-top: calc(110px + 60px);
}
body #detail .content {
  display: flex;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100vw - 200px);
  align-self: center;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
body #detail .content .holder-gallery {
  width: 50%;
  justify-content: center;
}
body #detail .content .holder-gallery .product-gallery {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}
body #detail .content .holder-gallery .product-gallery img {
  height: 350px;
}
body #detail .content .holder-info {
  width: 40%;
}
body #detail .content .holder-info .product-texts span {
  display: flex;
  margin-bottom: 15px;
}
body #detail .content .holder-info .product-texts .product-hearth {
  display: flex;
}
body #detail .content .holder-info .product-texts h2 {
  margin-bottom: 0px;
  font-weight: 400;
}
body #detail .content .holder-info .product-texts h3 {
  margin-bottom: 30px;
}
body #detail .content .holder-info .product-prices {
  justify-content: space-between;
}
body #detail .content .holder-info .product-prices big, body #detail .content .holder-info .product-prices small {
  margin-right: 20px;
  width: 50%;
}
body #detail .content .holder-info .product-prices .product-discount {
  display: flex;
}
body #detail .content .holder-info .product-prices .product-hearth {
  display: none;
}
body #detail .content .holder-info .increaser-and-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
body #detail .content .holder-info .increaser-and-button .product-increaser {
  width: 30%;
}
body #detail .content .holder-info .increaser-and-button .product-buttons {
  width: 65%;
}
body #detail .content .holder-info .increaser-and-button .product-buttons .btn {
  justify-content: center;
  background-color: #EB6B2F;
}
body #detail .content .holder-info .increaser-and-button .product-buttons .btn small {
  display: none;
}
body #detail .content .holder-info .increaser-and-button .product-buttons a {
  display: none;
}
body #detail .content .holder-info .increaser-and-button .product-buttons legend {
  display: flex;
}
body #detail .content .holder-info .increaser-and-button .product-buttons legend span {
  margin-right: 10px;
}
body #detail .content .holder-characteristics {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
}
body #detail .content .holder-characteristics h2 {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 40px;
}
body #detail .content .holder-characteristics .table {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}
body #detail .content .holder-characteristics .table .item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(20% - 18px);
  margin-right: 18px;
  margin-bottom: 10px;
}
body #detail .content .holder-characteristics .table .item:last-child {
  margin-right: 0px;
}
body #detail .content .holder-characteristics .table .item label, body #detail .content .holder-characteristics .table .item span {
  height: 32px;
  display: flex;
  align-items: center;
  background-color: #E8F5FB;
  width: calc(50% - 32px);
  justify-content: center;
  padding: 0px 15px;
  font-size: 14px;
}
body #detail .content .holder-characteristics .table .item label {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  font-weight: 400;
}
body #detail .content .holder-characteristics .table .item span {
  background-color: #F9FCFE;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
body #detail .holder-opinions {
  width: 100%;
  display: flex;
  background-color: #E8F5FB;
  padding: 30px 0px;
  flex-direction: column;
  justify-content: center;
}
body #detail .holder-opinions .title-and-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
body #detail .holder-opinions .title-and-button h2 {
  font-size: 18px;
}
body #detail .holder-opinions .title-and-button .tools {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
body #detail .holder-opinions .title-and-button .tools .btn {
  width: 234px;
  height: 58px;
  font-weight: 400;
  margin-right: 30px;
  margin: 0px;
}
body #detail .holder-opinions .title-and-button .tools .controllers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
body #detail .holder-opinions .title-and-button .tools .controllers button {
  transition: all 0.35s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
body #detail .holder-opinions .title-and-button .tools .controllers button:hover {
  background-color: #F2F2F2;
}
body #detail .holder-opinions .opinions {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100vw - 200px);
  align-self: center;
  margin-top: 16px;
  padding: 20px 0px;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: space-between;
}
body #detail .holder-opinions .opinions .opinion {
  display: flex;
  flex-direction: row;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px rgba(0, 69, 104, 0.25);
  border-radius: 12px;
  width: calc(50% - 48px) !important;
  padding: 16px;
  justify-content: space-between;
}
body #detail .holder-opinions .opinions .opinion .img-holder {
  display: flex;
  width: 80px;
  height: 80px;
  border-radius: 100%;
  background-color: #C4C4C4;
}
body #detail .holder-opinions .opinions .opinion .info-holder {
  display: flex;
  width: calc(100% - 100px);
  flex-direction: column;
}
body #detail .holder-opinions .opinions .opinion .info-holder h2 {
  color: #001427;
  margin-bottom: 10px;
}
body #detail .holder-opinions .opinions .opinion .info-holder p {
  color: #898989;
  line-height: 22px;
}
body #detail .holder-opinions .opinion-form {
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: #FFF;
  margin-top: 30px;
  padding: 20px;
  gap: 20px;
  justify-content: space-between;
}
body #detail .holder-opinions .opinion-form .img-holder {
  width: 90px;
  height: 90px;
  border-radius: 100%;
  background-color: #f5f9fa;
  font-size: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
body #detail .holder-opinions .opinion-form .opinion-area {
  display: flex;
  flex: 1;
  flex-direction: column;
}
body #detail .holder-opinions .opinion-form .opinion-area h2 {
  color: #003D5C;
  font-size: 16px;
  margin-bottom: 10px;
}
body #detail .holder-opinions .opinion-form .opinion-area .custom-textarea {
  margin-top: 5px;
  margin-bottom: 0px;
}
body #detail .holder-opinions .opinion-form .opinion-area .custom-textarea .field-wrapper {
  background: #F9FCFE;
  border-radius: 0px;
  border: none;
}
body #detail .holder-opinions .opinion-form .opinion-area .custom-textarea .field-wrapper textarea {
  color: #004568;
  font-size: 14px;
}
body #detail .bottom-button {
  display: none;
}
body .related-products {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 40px 0px;
  overflow: hidden;
  flex-wrap: wrap;
}
body .related-products .title-and-controllers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 32px;
  padding-right: 32px;
}
body .related-products .title-and-controllers h2 {
  display: flex;
  font-size: 24px;
}
body .related-products .title-and-controllers .controllers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
body .related-products .title-and-controllers .controllers button {
  transition: all 0.35s;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
body .related-products .title-and-controllers .controllers button:hover {
  background-color: #F2F2F2;
}
body .related-products .product-related-swiper {
  display: flex;
  flex-direction: row;
  padding-bottom: 60px;
  overflow-x: hidden;
}
body .related-products .product-related-swiper .slide-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 1vw;
}
body .related-products .product-related-swiper .slide-item .product-item {
  height: calc(100% - 60px);
  width: calc(100% - 36px);
}
body .related-products .product-related-swiper + .btn {
  width: 280px;
}
body .search-keyword {
  display: flex;
  flex-direction: row;
  position: relative;
  border-bottom: 1px solid #C4C4C4;
  margin-bottom: 10px;
}
body .search-keyword input[type=text] {
  width: calc(100% - 30px);
}
body .search-categories {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
}
body .search-categories h2 {
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
}
body .search-categories .btn {
  display: flex;
  width: 18%;
  font-size: 14px;
  text-decoration: underline;
  height: 30px;
  margin-bottom: 10px;
}
body .search-categories .btn .default {
  background-color: #F9FCFE;
}
body .search-categories .btn .secondary {
  text-decoration: none;
}
body .search-prices {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
body .search-prices h2 {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
  justify-content: center;
  align-items: center;
  text-decoration: underline;
}
body .search-prices .range-holder {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}
body .search-prices .range-holder .range-slider-rail {
  height: 36px;
  display: flex;
  width: 98%;
}
body .search-prices .range-holder::before, body .search-prices .range-holder::after {
  content: " ";
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: #fff;
  position: absolute;
  left: -3px;
  bottom: 6px;
  border: 4px solid #C4C4C4;
  z-index: 0;
}
body .search-prices .range-holder::after {
  left: unset;
  right: -3px;
}
body .search-prices .range-holder .slider-thumb {
  width: 24px;
  height: 24px;
  background-color: #1B97D5;
  border-radius: 24px;
}
body .search-prices .range-holder .slider-thumb .slider-thumb-label {
  position: absolute;
  top: -28px;
  width: 45px;
  left: -50%;
  text-align: center;
  color: #505050;
  font-weight: 200;
  font-size: 14px;
  line-height: 18px;
  background-color: transparent;
  border-bottom: 1px solid #00000035;
}
body .search-materials {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
body .search-materials h2 {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  justify-content: flex-start;
  align-items: center;
  text-decoration: underline;
}
body .search-materials .custom-checkbox {
  width: 30%;
  margin: 0px 10px 10px 0px;
}
body .search-materials .custom-checkbox span {
  font-size: 14px;
}
body .search-colors {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
body .search-colors h2 {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  justify-content: flex-start;
  align-items: center;
  text-decoration: underline;
}
body .search-colors .product-variants {
  border: none;
  padding: 0px;
  margin: 0px;
}
body .search-colors .product-variants .variants {
  flex-wrap: wrap;
}
body .search-colors .product-variants .variants .color {
  margin: 0px 10px 10px 0px;
}
body #login {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 34px);
}
body #login .area-login {
  display: flex;
  flex-direction: column;
  padding: 40px 32px;
}
body #login .area-login h2 {
  font-size: 32px;
  margin-bottom: 20px;
  font-weight: 200;
}
body #login .area-login .custom-input .field-wrapper {
  height: 50px;
  border: 1px solid #C4C4C4;
  border-radius: 29px;
}
body #login .area-login .custom-input .field-wrapper input {
  text-align: center;
}
body #login .area-login .buttons-holder .primary {
  height: 58px;
}
body #login .area-register {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 40px 32px;
  background-color: #001427;
  color: #FFF;
}
body #login .area-register h2 {
  font-weight: 200;
  font-size: 28px;
  margin-bottom: 30px;
}
body #login .area-register p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 200;
  margin-bottom: 30px;
}
body #login .area-register .btn {
  height: 58px;
  background-color: #192b3d;
  color: #FFF;
  margin-bottom: 20px;
}
body #login .area-register legend {
  display: flex;
  width: 300px;
  align-self: center;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 200;
  border-bottom: 1px solid #FFFFFF55;
  padding-bottom: 5px;
}
body #user-panel {
  padding: 40px 0px;
}
body #user-panel h1 {
  padding-left: 22px;
  padding-right: 22px;
  font-size: 32px;
  font-weight: 200;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
body #user-panel hr {
  height: 1px;
  border: none;
  background-color: #F2F2F2;
  margin: 30px 0px;
}
body #user-panel nav {
  padding-left: 22px;
  padding-right: 22px;
  display: flex;
  flex-direction: column;
}
body #user-panel nav a {
  border-bottom: 1px solid #F2F2F2;
  height: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: #505050;
  cursor: pointer;
}
body #user-panel nav a span {
  margin-left: 10px;
}
body #user-panel .user-card {
  padding-left: 22px;
  padding-right: 22px;
  align-items: center;
}
body #user-panel .user-card .img-holder {
  width: 88px;
  height: 88px;
}
body #user-panel .user-card .info-holder {
  width: calc(100% - 120px);
}
body #user-panel .user-card .info-holder .btn.link {
  display: none;
}
body #user-panel .user-card .info-holder .userid-and-tag h2 {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 5px;
}
body #user-panel .user-card .info-holder .userid-and-tag .tag {
  display: none;
}
body #user-panel .user-card .info-holder .userid-and-tag .btn.link {
  display: flex;
}
body #user-panel .user-card .info-holder .userid-and-tag .btn-edit {
  display: none !important;
}
body #user-panel .user-card .table-row {
  display: none;
}
body .user-card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}
body .user-card .btn.link {
  justify-content: flex-end;
  height: unset;
  font-size: 14px;
  padding: 0px;
}
body .user-card .img-holder {
  width: 110px;
  height: 110px;
  background-color: #f5f9fa;
  border-radius: 100%;
  position: relative;
}
body .user-card .img-holder .icon-holder {
  display: flex;
  flex-direction: row;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 0px;
  background-color: #FFF1EB;
  justify-content: center;
  align-items: center;
}
body .user-card .img-holder .icon-holder svg {
  padding: 0px;
  margin: 0px;
  margin-bottom: -3px;
  margin-left: -2px;
}
body .user-card .info-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: calc(100% - 140px);
}
body .user-card .info-holder .userid-and-tag {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
body .user-card .info-holder .userid-and-tag .titles {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
}
body .user-card .info-holder .userid-and-tag .titles h2 {
  color: #001427;
  font-size: 24px;
  font-weight: 400;
}
body .user-card .info-holder .userid-and-tag .titles h3 {
  font-weight: 200;
  font-size: 14px;
}
body .user-card .info-holder .userid-and-tag .titles .btn.link {
  justify-content: flex-start;
}
body .user-card .info-holder .userid-and-tag .tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  height: 42px;
  background: #FFF1EB;
  border-radius: 29px;
  color: #EB6B2F;
  font-size: 14px;
}
body .user-card .info-holder .userid-and-tag .tag svg {
  margin-right: 5px;
}
body .initials {
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  margin-top: -30px;
  left: 50%;
  margin-left: -30px;
  font-size: 30px;
}
body .user-template {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: calc(110px + 0px);
  flex: 1;
}
body .user-template .user-template-side {
  width: 440px;
}
body .user-template .user-template-side #user-panel {
  padding: 40px 0px 20px 0px;
}
body .user-template .user-template-side #user-panel nav {
  margin-bottom: 40px;
}
body .user-template .user-template-content {
  width: calc(100% - 440px);
  background-color: #f5f9fa;
  padding: 50px 32px 24px 16px;
  overflow: auto;
}
body .user-template .user-template-content .content h1 {
  font-size: 24px;
  font-weight: 200;
  margin-bottom: 24px;
}
body .user-template .user-template-content .content h1 svg {
  display: none;
}
body .user-template .user-template-content .content .user-profile {
  display: flex;
  flex-direction: column;
}
body .user-template .user-template-content .content .user-profile .card {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(188, 188, 188, 0.15);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  padding: 18px;
  margin-bottom: 30px;
  justify-content: space-between;
}
body .user-template .user-template-content .content .user-profile .card .user-card .img-holder {
  width: 90px;
  height: 90px;
}
body .user-template .user-template-content .content .user-profile .card .user-card .info-holder .userid-and-tag .titles {
  width: 150px;
}
body .user-template .user-template-content .content .user-profile .card .user-card .info-holder .userid-and-tag .titles h2 {
  font-size: 18px;
}
body .user-template .user-template-content .content .user-profile .card .user-card .info-holder .userid-and-tag .titles .btn.link {
  display: none;
}
body .user-template .user-template-content .content .user-profile .card .user-card .info-holder .userid-and-tag .tag {
  display: none;
}
body .user-template .user-template-content .content .user-profile .region-and-seller {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
body .user-template .user-template-content .content .user-profile .region-and-seller .is-holder .card {
  flex: 1;
}
body .user-template .user-template-content .content .user-profile .region-and-seller .holder-region {
  display: flex;
  flex-direction: column;
  width: 28%;
}
body .user-template .user-template-content .content .user-profile .region-and-seller .holder-region .region-card {
  align-items: center;
  justify-content: center;
}
body .user-template .user-template-content .content .user-profile .region-and-seller .holder-region .region-card .icon-holder {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 80px;
}
body .user-template .user-template-content .content .user-profile .region-and-seller .holder-region .region-card label {
  font-size: 18px;
  color: #001427;
}
body .user-template .user-template-content .content .user-profile .region-and-seller .holder-seller {
  display: flex;
  flex-direction: column;
  width: 70%;
}
body .user-template .user-template-content .content .user-profile .region-and-seller .holder-seller .seller-card .img-holder {
  width: 60px;
  height: 60px;
  border-radius: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #EDEDED;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center center;
}
body .user-template .user-template-content .content .user-profile .region-and-seller .holder-seller .seller-card .info-holder {
  width: calc(100% - 110px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
body .user-template .user-template-content .content .user-profile .region-and-seller .holder-seller .seller-card .info-holder .titles h2 {
  color: #001427;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
}
body .user-template .user-template-content .content .user-profile .region-and-seller .holder-seller .seller-card .info-holder .titles a {
  color: #1289C5;
  text-decoration: underline;
}
body .user-template .user-template-content .content .user-profile .region-and-seller .holder-seller .seller-card .info-holder .whatsapp {
  height: 48px;
  padding: 0px 20px;
  border: 1px solid #34C240;
  border-radius: 29px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #34C240;
  font-size: 16px;
  text-decoration: none;
}
body .user-template .user-template-content .content .user-profile .region-and-seller .holder-seller .seller-card .info-holder .whatsapp svg {
  margin-right: 10px;
}
body .user-template .user-template-content .content .nothing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: calc(100vh - calc(110px + 120px));
}
body .user-template .user-template-content .content .nothing h2 {
  font-size: 36px;
  color: #898989;
  font-weight: 200;
  margin-bottom: 30px;
}
body .user-template .user-template-content .content .nothing p {
  font-size: 24px;
  line-height: 28px;
  width: 35%;
  font-weight: 200;
  color: #000;
  text-align: center;
  margin-bottom: 50px;
}
body .user-template .user-template-content .content .nothing a {
  color: #007FC7;
}
body .user-template .user-template-content .content .nothing .search-keyword {
  width: 60%;
}
body .user-template .user-template-content .content .nothing .btn {
  width: 200px;
  height: 58px;
}
body .order-box {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(188, 188, 188, 0.15);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  padding: 24px;
  margin-bottom: 20px;
  justify-content: space-between;
}
body .order-box .collapser-header .content .table-row {
  padding-left: unset;
}
body .order-box .collapser-header .content .table-row .table-col:first-of-type span {
  color: unset;
}
body .order-box .collapser-header .content .table-row .table-col:last-of-type span {
  color: unset;
}
body .order-box .collapser-header .content .table-row .table-col:nth-last-of-type(3) span {
  color: #34C240;
}
body .order-box .order-detail {
  transition: all 0.35s;
}
body .order-box .order-detail .order-detail-title {
  margin: 40px 0px 30px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
body .order-box .order-detail .order-detail-title span {
  display: flex;
  font-size: 14px;
  margin-left: 30px;
}
body .order-box .order-detail .order-detail-title span:first-of-type {
  margin-left: 5px;
}
body .order-box .order-detail .order-detail-title span b {
  font-weight: 400;
  margin-left: 5px;
}
body .order-box .order-detail .order-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #C4C4C485;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
body .order-box .order-detail .order-row:last-child {
  border-bottom: none;
}
body .order-box .order-detail .order-row .img-holder {
  width: 70px;
  height: 70px;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  margin-right: 20px;
  background-size: contain;
  background-position: center center;
}
body .order-box .order-detail .order-row .sections {
  display: flex;
  flex-direction: row;
  width: calc(100% - 100px);
  justify-content: space-between;
}
body .order-box .order-detail .order-row .sections section {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  width: 14%;
}
body .order-box .order-detail .order-row .sections section label {
  display: flex;
  flex-direction: row;
  margin-bottom: 5px;
}
body .order-box .order-detail .order-row .sections section span {
  display: flex;
  flex-direction: row;
  font-size: 16px;
}
body .order-box .order-detail .order-row .sections section:first-of-type span {
  color: #EB6B2F;
  font-size: 12px;
}
body .order-box .order-detail .order-row .sections section:nth-last-of-type(2) span {
  color: #34C240;
}
body .address-card {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(188, 188, 188, 0.15);
  border-radius: 12px;
  padding: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
body .address-card h2 {
  color: #0086D0;
  font-size: 14px;
  width: 70%;
}
body .address-card .btn-delete {
  display: flex;
  align-items: center;
  justify-content: center;
}
body .address-card label {
  display: flex;
  font-weight: 400;
  font-size: 11px;
  color: #898989;
  width: 100%;
  margin: 10px 0px 5px 0px;
}
body .address-card p {
  display: block;
  width: 100%;
  font-size: 14px;
  margin: 0px;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  height: 28px;
  margin-bottom: 5px;
}
body .address-card .table-row {
  display: flex;
  width: 100%;
  margin: 0px;
  padding-left: 0px;
}
body .address-card .table-row .table-col label {
  font-size: 11px;
}
body .address-card .table-row .table-col span {
  color: unset;
  font-size: 12px;
}
body .address-card .btn-edit {
  display: flex;
  width: 100%;
  padding: 0px;
  color: #FF7433;
  margin-top: 10px;
  font-size: 14px;
}
body #addresses {
  display: flex;
  flex-direction: column;
}
body #addresses .title-and-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}
body #addresses .title-and-button h1 {
  display: flex;
  margin: 0px;
}
body #addresses .title-and-button .btn {
  display: flex;
  width: 200px;
}
body #addresses .title-and-button .btn svg {
  display: none;
}
body #addresses .addresses-holder {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}
body #addresses .addresses-holder .address-card {
  width: 20%;
  margin-bottom: 20px;
  margin-right: 2%;
}
body #addresses .addresses-holder .address-card:nth-child(4n) {
  margin-right: 0px;
}
body .addresss-form {
  padding: 30px;
  max-width: 600px;
  background-color: #FFF;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  border-radius: 20px;
}
body .addresss-form h2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
  width: 100%;
  font-size: 24px;
}
body .addresss-form .custom-input {
  width: 48%;
}
body .addresss-form .custom-input .field-wrapper {
  background-color: #F2F2F2;
  border: 1px solid #C4C4C4;
  border-radius: 40px;
}
body .addresss-form .btn-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}
body .addresss-form .btn-holder .btn {
  height: 50px;
  width: 300px;
  justify-self: center;
}
body #cart {
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - calc(110px + 80px));
  padding-top: 110px;
  padding-bottom: 80px;
  background-color: #f9fcfe;
}
body #cart .cart-list {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(188, 188, 188, 0.15);
  border-bottom-right-radius: 16px;
  padding: 15px;
}
body #cart .cart-list .cart-list-header {
  display: flex;
  flex-direction: row;
  align-items: center;
}
body #cart .cart-list .cart-list-header h2 {
  font-size: 15px;
  margin-left: 10px;
}
body #cart .cart-list .cart-list-header h2 b {
  font-weight: 400;
}
body #cart .cart-list .cart-list-content {
  padding: 10px 0px;
}
body #cart .cart-item {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 20px 0px;
  justify-content: space-between;
}
body #cart .cart-item .img-holder {
  display: flex;
  width: 90px;
  height: 90px;
  border: 1px solid #C4C4C4;
  border-radius: 8px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}
body #cart .cart-item .content-holder {
  display: flex;
  flex-direction: row;
  width: calc(100% - 120px);
  flex-wrap: wrap;
}
body #cart .cart-item .content-holder h2 {
  display: flex;
  font-size: 18px;
  width: 100%;
}
body #cart .cart-item .content-holder .column {
  display: flex;
  flex-direction: column;
  width: 31%;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
}
body #cart .cart-item .content-holder .column small {
  font-size: 14px;
  color: #EB6B2F;
  margin-bottom: 10px;
}
body #cart .cart-item .content-holder .column span {
  color: #001427;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}
body #cart .cart-item .content-holder .column span b {
  font-weight: 200;
  margin: 0px 15px 0px 5px;
}
body #cart .cart-item .content-holder .column label {
  margin-bottom: 10px;
}
body #cart .cart-item .content-holder .column:last-of-type {
  width: 60%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
body #cart .cart-item .content-holder .column:last-of-type label {
  display: flex;
}
body #cart .cart-item .content-holder .column:last-of-type .product-increaser {
  height: 34px;
  display: flex;
  width: 30%;
  justify-content: center;
}
body #cart .cart-item .content-holder .column:last-of-type .product-increaser span {
  width: 60%;
  justify-content: center;
}
body #cart .cart-item .content-holder .column:last-of-type .product-buttons {
  display: flex;
  flex-direction: column;
  width: 60%;
}
body #cart .cart-item .content-holder .column:last-of-type .product-buttons .btn, body #cart .cart-item .content-holder .column:last-of-type .product-buttons a {
  display: none;
}
body #cart .cart-item .content-holder .column:last-of-type .product-buttons legend {
  display: flex;
  flex: 1;
  margin: 0px;
  flex-direction: column;
}
body #cart .cart-item .content-holder .column:last-of-type .product-buttons legend span {
  display: flex;
  width: 100%;
  font-size: 14px;
  margin-bottom: 10px;
}
body #cart .cart-item .content-holder .column:last-of-type .product-buttons legend b {
  font-weight: 700;
  font-size: 24px;
}
body #cart .cart-item::before {
  content: " ";
  position: absolute;
  top: 0px;
  left: 1.5%;
  width: 97%;
  height: 1px;
  background-color: #F2F2F2;
}
body #cart .cart-step-auth {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow-y: auto;
}
body #cart .cart-step-auth .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100vw - 200px);
  align-self: center;
  justify-content: center;
}
body #cart .cart-step-auth .content .header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
body #cart .cart-step-auth .content .header h1 {
  display: flex;
  width: 50%;
  font-size: 24px;
  font-weight: 300;
}
body #cart .cart-step-auth .content .header .btn {
  justify-content: flex-end;
  margin: 0px;
  width: 120px;
}
body #cart .cart-step-auth .content .login-holder {
  display: flex;
  margin-bottom: 40px;
  justify-content: space-between;
  flex-direction: row;
}
body #cart .cart-step-auth .content .login-holder #login {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 55vh;
  width: 100%;
}
body #cart .cart-step-auth .content .login-holder #login .area-login {
  display: flex;
  width: 45%;
  flex: unset;
  box-shadow: 0px 8px 16px rgba(0, 69, 104, 0.25);
  border-radius: 16px;
}
body #cart .cart-step-auth .content .login-holder #login .area-register {
  display: flex;
  width: 40%;
  flex: unset;
  box-shadow: 0px 8px 16px rgba(0, 69, 104, 0.25);
  border-radius: 16px;
  text-align: center;
}
body #cart .cart-motivation {
  background: conic-gradient(from 194.33deg at 59.07% 55.67%, #FD23BA -43.81deg, #FD5261 98.4deg, #FD23BA 316.19deg, #FD5261 458.4deg);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: column;
  color: #FFF;
  padding: 16px;
}
body #cart .cart-motivation h2 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
}
body #cart .cart-motivation h3 {
  font-size: 16px;
  margin-bottom: 15px;
}
body #cart .cart-motivation h4 {
  font-weight: 700;
  font-size: 21px;
}
body #cart .cart-motivation h4 big {
  font-size: 42px;
}
body #cart .cart-minicart {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(188, 188, 188, 0.15);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px;
  margin-bottom: 16px;
  width: 100%;
}
body #cart .cart-minicart h2 {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 10px;
}
body #cart .cart-minicart h2 .btn {
  display: flex;
  margin: 0px;
  padding: 0px;
  width: auto;
}
body #cart .cart-minicart h3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
  font-weight: 200;
  margin-bottom: 10px;
}
body #cart .cart-minicart h3 svg {
  margin-right: 10px;
}
body #cart .cart-minicart h3 b {
  font-weight: 300;
}
body #cart .cart-minicart .scroll-area {
  display: flex;
  flex-direction: column;
  height: 100px;
  overflow: auto;
}
body #cart .cart-minicart .scroll-area .cart-item {
  padding: 10px 0px;
}
body #cart .cart-minicart .scroll-area .cart-item .img-holder {
  width: 80px;
  height: 80px;
}
body #cart .cart-minicart .scroll-area .cart-item .content-holder {
  align-items: center;
  width: calc(100% - 90px);
}
body #cart .cart-minicart .scroll-area .cart-item .content-holder h2 {
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  border: 1px solid transparent;
}
body #cart .cart-minicart .scroll-area .cart-item .content-holder .column {
  display: none;
  width: 100%;
  height: 15px;
}
body #cart .cart-minicart .scroll-area .cart-item .content-holder .column:nth-of-type(2) {
  display: flex;
}
body #cart .cart-minicart .scroll-area .cart-item .content-holder .column:nth-of-type(2) .product-increaser {
  display: none;
}
body #cart .cart-minicart .scroll-area .cart-item .content-holder .column:nth-of-type(2) .product-buttons {
  width: 100%;
}
body #cart .cart-minicart .scroll-area .cart-item .content-holder .column:nth-of-type(2) .product-buttons legend {
  flex-direction: row;
  justify-content: flex-start;
}
body #cart .cart-minicart .scroll-area .cart-item .content-holder .column:nth-of-type(2) .product-buttons legend span {
  display: flex;
  width: auto;
  margin-right: 10px;
}
body #cart .cart-minicart .scroll-area .cart-item .content-holder .column:nth-of-type(2) .product-buttons legend b {
  width: auto;
  font-size: 14px !important;
  font-weight: 400;
}
body #cart .cart-minicart .scroll-area .cart-item .content-holder .icon-detelete {
  display: none;
}
body #cart .cart-template {
  display: flex;
  flex-direction: row;
  width: 100vw;
  justify-content: space-between;
  gap: 20px;
}
body #cart .cart-template .bg {
  visibility: hidden;
  opacity: 0;
}
body #cart .cart-template section {
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: calc(100vw - (380px - 20px));
}
body #cart .cart-template section:first-child {
  padding-right: 20px;
}
body #cart .cart-template section:last-child {
  width: calc(380px - 60px);
  align-items: flex-start;
  padding-right: 60px;
}
body #cart .cart-template section .content-header {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
  align-items: center;
}
body #cart .cart-template section .content-header h1 {
  display: flex;
  font-size: 24px;
  font-weight: 300;
  width: 40%;
  align-items: center;
}
body #cart .cart-template section .content-header h1 svg {
  margin-right: 10px;
}
body #cart .cart-template section .content-header .btn {
  width: 150px;
  margin: 0px;
}
body #cart .cart-template section .content-header .btn.tertiary {
  display: none;
}
body #cart .cart-template section .content-header .cart-stepper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc((56px * 3) + (24px * 3) - 20px);
}
body #cart .cart-template section .content-header .cart-stepper span {
  display: flex;
  width: 24px;
  height: 24px;
  background-color: #C4C4C4;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
}
body #cart .cart-template section .content-header .cart-stepper span::before {
  position: absolute;
  display: flex;
  content: " ";
  width: 56px;
  height: 4px;
  background-color: #C4C4C4;
  right: calc(100% + 5px);
}
body #cart .cart-template section .content-header .cart-stepper span:first-of-type::before {
  display: none;
}
body #cart .cart-template section .content-header .cart-stepper span.current {
  background-color: #FF7433;
}
body #cart .cart-template section .content-header .cart-stepper span.current::before {
  background-color: #FF7433;
}
body #cart .cart-template section .content-area {
  width: 90%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}
body #cart .cart-template section .content-area h1 svg {
  display: none;
}
body #cart .cart-template section .content-area #addresses .title-and-button .btn {
  background-color: #FFF;
  border: 1px solid #1289C5;
  color: #1289C5;
}
body #cart .cart-template section .content-area #addresses .title-and-button .btn svg {
  display: flex;
  margin-right: 10px;
}
body #cart .cart-template section .content-area .cart-compeleted h1 {
  font-size: 20px;
  margin: 20px 0px 40px 0px;
}
body #cart .cart-template section .content-area .cart-compeleted p {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 40px;
}
body #cart .cart-template section .content-area .cart-compeleted .btn {
  width: 220px;
  height: 50px;
}
body #cart .cart-template section .cart-summary {
  background: #FFFFFF;
  box-shadow: 0px 0px 12px rgba(0, 69, 104, 0.25);
  border-radius: 12px;
  padding: 14px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
}
body #cart .cart-template section .cart-summary h2 {
  font-size: 24px;
  font-weight: 200;
  margin-bottom: 30px;
}
body #cart .cart-template section .cart-summary article {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
body #cart .cart-template section .cart-summary article label {
  font-size: 14px;
  margin-bottom: 5px;
}
body #cart .cart-template section .cart-summary article span {
  color: #34C240;
  font-size: 18px;
  font-weight: 700;
}
body #cart .cart-template section .cart-summary article:nth-of-type(2) span {
  text-decoration: line-through;
  color: #505050;
  font-weight: 300;
}
body #cart .cart-template section .cart-summary article:last-of-type span {
  color: #505050;
}
body #cart .cart-template section .cart-summary .btn {
  margin-top: 0px;
  height: 58px;
}
body #cart .cart-template section .cart-summary .cart-motivation {
  display: none;
}
body #cart .cart-template section .cart-motivation {
  margin-top: 20px;
}
body #cart .cart-template section .cart-active-promo {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
}
body #cart .cart-template section .cart-active-promo h2 {
  color: #898989;
  font-size: 16px;
}
body #cart .cart-template section .cart-active-promo h3 {
  color: #34C240;
  font-size: 24px;
  font-weight: 500;
}
body #cart .cart-template.cart-step-1 .content-area, body #cart .cart-template.cart-step-2 .content-area, body #cart .cart-template.cart-step-3 .content-area {
  overflow: auto;
  padding-bottom: 40px;
}
body #cart .cart-template.cart-step-1 section:nth-of-type(1) #addresses + h2, body #cart .cart-template.cart-step-2 section:nth-of-type(1) #addresses + h2, body #cart .cart-template.cart-step-3 section:nth-of-type(1) #addresses + h2 {
  margin-top: 30px;
}
body #cart .cart-template.cart-step-1 section:nth-of-type(2) .cart-summary, body #cart .cart-template.cart-step-2 section:nth-of-type(2) .cart-summary, body #cart .cart-template.cart-step-3 section:nth-of-type(2) .cart-summary {
  margin-bottom: 16px;
}
body #cart .cart-template.cart-step-1 section:nth-of-type(2) .cart-summary h2, body #cart .cart-template.cart-step-2 section:nth-of-type(2) .cart-summary h2, body #cart .cart-template.cart-step-3 section:nth-of-type(2) .cart-summary h2 {
  font-size: 24px;
  margin-bottom: 15px;
}
body #cart .cart-template.cart-step-1 section:nth-of-type(2) .cart-summary article, body #cart .cart-template.cart-step-2 section:nth-of-type(2) .cart-summary article, body #cart .cart-template.cart-step-3 section:nth-of-type(2) .cart-summary article {
  display: none;
  margin-bottom: 20px;
}
body #cart .cart-template.cart-step-1 section:nth-of-type(2) .cart-summary article:nth-last-of-type(1), body #cart .cart-template.cart-step-1 section:nth-of-type(2) .cart-summary article:nth-last-of-type(2), body #cart .cart-template.cart-step-2 section:nth-of-type(2) .cart-summary article:nth-last-of-type(1), body #cart .cart-template.cart-step-2 section:nth-of-type(2) .cart-summary article:nth-last-of-type(2), body #cart .cart-template.cart-step-3 section:nth-of-type(2) .cart-summary article:nth-last-of-type(1), body #cart .cart-template.cart-step-3 section:nth-of-type(2) .cart-summary article:nth-last-of-type(2) {
  display: flex;
}
body #cart .cart-template.cart-step-1 section:nth-of-type(2) .cart-summary article:last-of-type, body #cart .cart-template.cart-step-2 section:nth-of-type(2) .cart-summary article:last-of-type, body #cart .cart-template.cart-step-3 section:nth-of-type(2) .cart-summary article:last-of-type {
  margin-bottom: 0px;
}
body #cart .cart-template.cart-step-1 section:nth-of-type(2) .cart-summary .btn, body #cart .cart-template.cart-step-2 section:nth-of-type(2) .cart-summary .btn, body #cart .cart-template.cart-step-3 section:nth-of-type(2) .cart-summary .btn {
  display: none;
}
body #cart .cart-template.cart-step-1 section:nth-of-type(2) .cart-motivation, body #cart .cart-template.cart-step-2 section:nth-of-type(2) .cart-motivation, body #cart .cart-template.cart-step-3 section:nth-of-type(2) .cart-motivation {
  margin-bottom: 15px;
}
body #cart .cart-template.cart-step-1 section:nth-of-type(2) .buttons-holder, body #cart .cart-template.cart-step-2 section:nth-of-type(2) .buttons-holder, body #cart .cart-template.cart-step-3 section:nth-of-type(2) .buttons-holder {
  display: flex;
  flex-direction: column;
  width: calc(100% + 32px);
}
body #cart .cart-template.cart-step-1 section:nth-of-type(2) .buttons-holder .btn, body #cart .cart-template.cart-step-2 section:nth-of-type(2) .buttons-holder .btn, body #cart .cart-template.cart-step-3 section:nth-of-type(2) .buttons-holder .btn {
  height: 58px;
  margin: 0px;
}
body #cart .cart-template.cart-step-1 section:nth-of-type(2) .buttons-holder .btn.link:last-of-type, body #cart .cart-template.cart-step-2 section:nth-of-type(2) .buttons-holder .btn.link:last-of-type, body #cart .cart-template.cart-step-3 section:nth-of-type(2) .buttons-holder .btn.link:last-of-type {
  display: none;
}
body #cart .cart-template.cart-step-1 section:nth-of-type(2) .cart-minicart .scroll-area, body #cart .cart-template.cart-step-2 section:nth-of-type(2) .cart-minicart .scroll-area, body #cart .cart-template.cart-step-3 section:nth-of-type(2) .cart-minicart .scroll-area {
  height: 250px;
}
body #cart .cart-template.cart-step-1 section .method-holder, body #cart .cart-template.cart-step-2 section .method-holder, body #cart .cart-template.cart-step-3 section .method-holder {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px rgba(188, 188, 188, 0.15);
  border-radius: 16px;
  margin-bottom: 25px;
  padding: 16px;
  padding-top: 24px;
  display: flex;
  flex-direction: column;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-header, body #cart .cart-template.cart-step-2 section .method-holder .method-header, body #cart .cart-template.cart-step-3 section .method-holder .method-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: space-between;
  cursor: pointer;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-header h2, body #cart .cart-template.cart-step-2 section .method-holder .method-header h2, body #cart .cart-template.cart-step-3 section .method-holder .method-header h2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-header h2 svg, body #cart .cart-template.cart-step-2 section .method-holder .method-header h2 svg, body #cart .cart-template.cart-step-3 section .method-holder .method-header h2 svg {
  display: flex;
  margin-right: 10px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-header::after, body #cart .cart-template.cart-step-2 section .method-holder .method-header::after, body #cart .cart-template.cart-step-3 section .method-holder .method-header::after {
  content: " ";
  display: flex;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid #898989;
  right: 0px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content, body #cart .cart-template.cart-step-2 section .method-holder .method-content, body #cart .cart-template.cart-step-3 section .method-holder .method-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 0px;
  overflow: hidden;
  transition: all 0.15s;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block h2, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block h2, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block h2 {
  display: flex;
  width: 100%;
  font-size: 24px;
  margin-bottom: 20px;
  font-weight: 200;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .logos-holder, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .logos-holder, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .logos-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .logos-holder img, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .logos-holder img, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .logos-holder img {
  margin: 0px 10px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block p, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block p, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block p {
  color: #898989;
  font-size: 14px;
  margin-bottom: 20px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block h3, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block h3, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block h3 {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 200;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .banks-holder, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .banks-holder, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .banks-holder {
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);
  padding: 0px 30px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .banks-holder .bank-row, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .banks-holder .bank-row, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .banks-holder .bank-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .banks-holder .bank-row span, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .banks-holder .bank-row span, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .banks-holder .bank-row span {
  font-size: 18px;
  font-weight: 200;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .gray-box, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .gray-box, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .gray-box {
  display: flex;
  flex-direction: column;
  background-color: #F9FCFE;
  padding: 18px 18px;
  border-radius: 16px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .gray-box h2, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .gray-box h2, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .gray-box h2 {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 15px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .gray-box p, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .gray-box p, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .gray-box p {
  font-weight: 200;
  margin-bottom: 15px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .gray-box p:last-child, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .gray-box p:last-child, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .gray-box p:last-child {
  margin-bottom: 0px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .deposit-holder, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .deposit-holder, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .deposit-holder {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding-right: 30px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .deposit-holder img, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .deposit-holder img, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .deposit-holder img {
  display: flex;
  margin-bottom: 15px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .deposit-holder .deposit-row, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .deposit-holder .deposit-row, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .deposit-holder .deposit-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .deposit-holder .deposit-row p, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .deposit-holder .deposit-row p, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .deposit-holder .deposit-row p {
  display: flex;
  margin: 0px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .deposit-holder .deposit-row span, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .deposit-holder .deposit-row span, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .deposit-holder .deposit-row span {
  display: flex;
  margin: 0px;
  margin-right: 10px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .inputs-row, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .inputs-row, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .inputs-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .inputs-row .custom-input, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .inputs-row .custom-input, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .inputs-row .custom-input {
  margin-right: 20px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .inputs-row .custom-input:last-child, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .inputs-row .custom-input:last-child, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .inputs-row .custom-input:last-child {
  margin-right: 0px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .inputs-row .custom-input label, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .inputs-row .custom-input label, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .inputs-row .custom-input label {
  font-size: 14px;
  font-weight: 300;
  color: unset;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .inputs-row .custom-input .field-wrapper, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .inputs-row .custom-input .field-wrapper, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .inputs-row .custom-input .field-wrapper {
  border: 1px solid #C4C4C4;
  border-radius: 29px;
  background: #F9FCFE;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block .custom-checkbox, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block .custom-checkbox, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block .custom-checkbox {
  margin: 10px 0px 15px 0px;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block input[type=checkbox] + .custom-input, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block input[type=checkbox] + .custom-input, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block input[type=checkbox] + .custom-input {
  display: none;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block input[type=checkbox] + .custom-input label, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block input[type=checkbox] + .custom-input label, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block input[type=checkbox] + .custom-input label {
  font-size: 14px;
  font-weight: 300;
  color: #007FC7;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block input[type=checkbox] + .custom-input .field-wrapper, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block input[type=checkbox] + .custom-input .field-wrapper, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block input[type=checkbox] + .custom-input .field-wrapper {
  border: 1px solid #C4C4C4;
  border-radius: 29px;
  background: #F9FCFE;
}
body #cart .cart-template.cart-step-1 section .method-holder .method-content .method-block input[type=checkbox]:checked + .custom-input, body #cart .cart-template.cart-step-2 section .method-holder .method-content .method-block input[type=checkbox]:checked + .custom-input, body #cart .cart-template.cart-step-3 section .method-holder .method-content .method-block input[type=checkbox]:checked + .custom-input {
  display: flex;
}
body #cart .cart-template.cart-step-1 section .method-holder.chosen .method-header::after, body #cart .cart-template.cart-step-2 section .method-holder.chosen .method-header::after, body #cart .cart-template.cart-step-3 section .method-holder.chosen .method-header::after {
  border: 2px solid #007FC7;
}
body #cart .cart-template.cart-step-1 section .method-holder.chosen .method-header::before, body #cart .cart-template.cart-step-2 section .method-holder.chosen .method-header::before, body #cart .cart-template.cart-step-3 section .method-holder.chosen .method-header::before {
  content: " ";
  width: 14px;
  height: 14px;
  position: absolute;
  background-color: #007FC7;
  right: 5px;
  border-radius: 100%;
}
body #cart .cart-template.cart-step-1 section .method-holder.chosen .method-content, body #cart .cart-template.cart-step-2 section .method-holder.chosen .method-content, body #cart .cart-template.cart-step-3 section .method-holder.chosen .method-content {
  height: auto;
  margin-top: 30px;
}
body #cart .cart-template.cart-step-4 section:first-child {
  background-color: #FFF;
}
body #cart .cart-template.cart-step-4 section:first-child .cart-stepper .current {
  background-color: #34C240;
}
body #cart .cart-template.cart-step-4 section:first-child .cart-stepper .current::before {
  background-color: #1B97D5;
}
body #cart .cart-template.cart-step-4 section:last-child .cart-summary article:nth-of-type(1), body #cart .cart-template.cart-step-4 section:last-child .cart-summary article:nth-of-type(2) {
  display: none;
}
body #cart .cart-template.cart-step-4 section:last-child .cart-summary .btn {
  display: none;
}
body #cart .cart-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFF;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;
  overflow: hidden;
}
body #cart .cart-empty .cart-empty-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  padding-left: 32px;
  padding-right: 32px;
}
body #cart .cart-empty .cart-empty-message h1 {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}
body #cart .cart-empty .cart-empty-message h2 {
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 40px;
}
body #cart .cart-empty .cart-empty-message .btn {
  margin-bottom: 120px;
  height: 58px;
  width: 50%;
}
body #cart .cart-empty .cart-empty-message .search-keyword {
  width: 100%;
}
body #cart .cart-empty .shuffled-products {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}
body #cart .cart-empty .shuffled-products .slide-title {
  font-size: 20px;
  text-align: center;
  margin-bottom: 40px;
}
body #cart .cart-empty .shuffled-products .holder {
  width: 100%;
}
body #cart .cart-empty .shuffled-products .holder .slide-item {
  padding: 15px;
  max-width: calc(20vw - 30px);
  width: unset;
}
body .promo-applier {
  background-color: #FFF1EB;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  padding: 24px 32px;
  max-width: 280px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
body .promo-applier h2 {
  color: #EB6B2F;
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 15px;
}
body .promo-applier p {
  color: #505050;
  font-weight: 200;
  line-height: 22px;
  margin-bottom: 15px;
}
body .promo-applier .custom-input label {
  font-size: 14px;
}
body .promo-applier .custom-input .field-wrapper {
  border-color: #C4C4C4;
  height: 50px;
  border-radius: 26px;
  background-color: #F9FCFE;
}
body .promo-applier .custom-input .field-wrapper input {
  text-align: center;
  text-transform: uppercase;
}
body .promo-applier .btn {
  height: 50px;
}
body #business {
  padding-top: 110px;
}
body #business .business-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 500px;
  background-image: url("@Assets/business/banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0px 100px;
  justify-content: flex-end;
}
body #business .business-banner h2, body #business .business-banner h3 {
  display: flex;
  font-size: 80px;
  color: #EB6B2F;
  line-height: 60px;
  font-weight: 100;
  width: 520px;
}
body #business .business-banner h3 {
  color: #505050;
  text-align: right;
  margin-top: 0px;
}
body #business .business-why {
  display: flex;
  flex-direction: column;
  padding: 60px 100px;
  align-items: center;
}
body #business .business-why h2 {
  font-size: 32px;
  font-weight: 200;
  margin-bottom: 50px;
}
body #business .business-why .why-block {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 60px;
}
body #business .business-why .why-block section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 25%;
  padding: 0px 50px;
}
body #business .business-why .why-block section img {
  display: flex;
  margin-bottom: 30px;
  height: 80px;
}
body #business .business-why .why-block section p {
  display: flex;
  font-size: 24px;
  font-weight: 200;
  text-align: center;
}
body #business .business-calculator {
  background-color: #EB6B2F;
  padding-left: 32px;
  padding-right: 32px;
  padding-top: 24px;
  padding-bottom: 24px;
  color: #fff;
  display: none;
  flex-direction: row;
  justify-content: center;
  gap: 150px;
  font-weight: 200;
  align-items: center;
}
body #business .business-calculator section {
  width: 550px;
  display: flex;
  flex-direction: column;
}
body #business .business-calculator section h2 {
  font-size: 80px;
  margin-bottom: 24px;
}
body #business .business-calculator section p {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 30px;
}
body #business .business-calculator .download-area {
  display: flex;
  flex-direction: column;
}
body #business .business-calculator .download-area h3 {
  font-size: 24px;
  margin-bottom: 16px;
}
body #business .business-calculator .download-area .btn {
  height: 58px;
  border-radius: 29px;
  width: 100%;
  color: #505050;
  text-decoration: none;
}
body #business .business-calculator aside {
  width: 420px;
  background: #EF763D;
  border-radius: 24px;
  padding: 24px;
  display: flex;
  flex-direction: column;
}
body #business .business-calculator aside h2 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 18px;
}
body #business .business-calculator aside fieldset {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
body #business .business-calculator aside fieldset label {
  font-size: 14px;
  font-weight: 300;
  margin-bottom: 10px;
}
body #business .business-calculator aside fieldset input {
  border: 1px solid #EB6B2F;
  border-radius: 29px;
  height: 50px;
  background-color: #FFF;
  text-align: center;
  font-size: 32px;
  color: #505050;
}
body #business .business-calculator aside p {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 10px;
}
body #business .business-calculator aside p b {
  font-weight: 500;
}
body #business .business-calculator aside big {
  display: flex;
  justify-content: center;
  font-size: 80px;
  margin-bottom: 20px;
}
body #business .business-calculator aside small {
  line-height: 22px;
}
body #business .business-calculator aside + .download-area {
  display: none;
}
body #business .business-start {
  background-color: #F9FCFE;
  display: flex;
  flex-direction: column;
  padding: 34px 100px;
  font-weight: 200;
}
body #business .business-start h2 {
  font-size: 32px;
  margin-bottom: 16px;
}
body #business .business-start h3 {
  font-size: 18px;
  margin-bottom: 40px;
}
body #business .business-start .blocks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 120px;
}
body #business .business-start .blocks section {
  display: flex;
  flex-direction: column;
  width: 25%;
}
body #business .business-start .blocks section .icon-holder {
  align-self: center;
  position: relative;
  margin-bottom: 26px;
}
body #business .business-start .blocks section .icon-holder::after {
  content: " ";
  position: absolute;
  width: 56px;
  height: 56px;
  border-radius: 100%;
  background-color: #FFF1EB;
  left: 5px;
  top: 5px;
}
body #business .business-start .blocks section .icon-holder svg {
  position: relative;
  z-index: 1;
}
body #business .business-start .blocks section h2 {
  font-size: 24px;
  font-weight: 400;
  display: flex;
  flex-direction: row;
  align-items: center;
}
body #business .business-start .blocks section h2 b {
  background-color: #EB6B2F;
  color: #FFF;
  display: flex;
  width: 32px;
  height: 32px;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-right: 10px;
  font-size: 18px;
}
body #business .business-start .blocks section p {
  font-size: 18px;
  line-height: 26px;
}
body #business .business-reasons {
  padding: 64px 100px;
  font-weight: 200;
  display: flex;
  flex-direction: column;
}
body #business .business-reasons h2 {
  font-size: 32px;
  margin-bottom: 16px;
}
body #business .business-reasons h3 {
  font-size: 18px;
  margin-bottom: 32px;
}
body #business .business-reasons .blocks {
  display: flex;
  flex-direction: row;
  gap: 120px;
}
body #business .business-reasons .blocks section {
  width: 25%;
}
body #business .business-reasons .blocks section h2 {
  font-size: 24px;
  font-weight: 300;
  margin-bottom: 8px;
}
body #business .business-reasons .blocks section p {
  font-size: 18px;
  line-height: 26px;
}
body #business .business-subanner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  align-items: center;
  font-weight: 200;
}
body #business .business-subanner::after {
  content: " ";
  position: absolute;
  z-index: 0;
  background: linear-gradient(269.07deg, #F9FCFE 60%, rgba(255, 255, 255, 0) 100%);
  transform: rotate(-180deg);
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}
body #business .business-subanner .texts {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding-left: 100px;
  width: 35rem;
}
body #business .business-subanner .texts h2 {
  font-size: 32px;
  margin-bottom: 16px;
  font-weight: 300;
}
body #business .business-subanner .texts p {
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 32px;
}
body #business .business-subanner .texts .buttons-holder {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
body #business .business-subanner .texts .buttons-holder .btn {
  height: 58px;
  border-radius: 29px;
  font-weight: 300;
}
body #faqs {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 110px;
  flex-wrap: wrap;
  padding-left: 32px;
  padding-right: 32px;
}
body #faqs .search-area {
  padding: 25px 0px;
  align-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
}
body #faqs .search-area .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  font-weight: 200;
}
body #faqs .search-area .content h1 {
  font-size: 32px;
  margin-bottom: 15px;
}
body #faqs .search-area .content p {
  font-size: 18px;
  line-height: 26px;
  text-align: center;
}
body #faqs .search-area .content fieldset {
  border: 1px solid #C4C4C4;
  border-radius: 29px;
  background: #F9FCFE;
  display: flex;
  flex-direction: row;
  width: 80%;
  align-self: center;
  margin: 20px 0px;
}
body #faqs .search-area .content fieldset input {
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 0px 20px;
}
body #faqs .search-area .content fieldset .btn {
  width: 138px;
}
body #faqs .questions-area {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
}
body #faqs .questions-area aside {
  display: flex;
  flex-direction: column;
  padding-right: 50px;
  flex-basis: 25rem;
}
body #faqs .questions-area aside h2 {
  font-size: 24px;
  margin-bottom: 42px;
}
body #faqs .questions-area aside .group {
  display: flex;
  flex-direction: column;
}
body #faqs .questions-area aside .group h3 {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  margin: 20px 0px;
}
body #faqs .questions-area aside .group h3 svg {
  margin-right: 10px;
}
body #faqs .questions-area aside .group .q-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid #F2F2F2;
  padding: 10px 0px;
  justify-content: space-between;
  line-height: 24px;
  cursor: pointer;
}
body #faqs .questions-area aside .group .q-item:last-child {
  margin-bottom: 30px;
}
body #faqs .questions-area aside .group .q-item.current {
  font-weight: 400;
}
body #faqs .questions-area aside .group .q-item.current svg {
  color: #007FC7 !important;
}
body #faqs .questions-area aside .group .a-item {
  display: none;
}
body #faqs .questions-area .answer-holder {
  position: sticky;
  background: #FFFBFA;
  padding: 40px 32px;
  max-height: 350px;
  margin-top: 110px;
  top: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
body #faqs .questions-area .answer-holder h4 {
  font-size: 24px;
  margin-bottom: 40px;
  display: flex;
  text-align: left;
  width: 100%;
}
body #faqs .questions-area .answer-holder p {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 20px;
  text-align: left;
  width: 100%;
}
body #faqs .questions-area .answer-holder p a {
  color: #007FC7;
  font-size: 18px;
  text-decoration: underline;
}
body #faqs .questions-area .answer-holder .contact-holder {
  margin-top: 40px;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
body #faqs .questions-area .answer-holder .contact-holder h2 {
  font-size: 26px;
  line-height: 32px;
  font-weight: 200;
  text-align: center;
  margin-bottom: 10px;
}
body #faqs .questions-area .answer-holder .contact-holder .whatsapp {
  height: 58px;
  color: #FFF;
  background-color: #34C240;
  border-radius: 29px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
body #faqs .questions-area .answer-holder .contact-holder .whatsapp svg {
  margin-right: 10px;
}
body #branches {
  padding-top: 110px;
  display: flex;
  flex-direction: column;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 80px;
}
body #branches h1 {
  font-size: 32px;
  margin: 25px 0px 25px 0px;
}
body #branches .content {
  display: flex;
  flex-direction: row;
}
body #branches .content aside {
  width: 25rem;
}
body #branches .content aside section {
  display: flex;
  flex-direction: column;
}
body #branches .content aside section h2 {
  display: flex;
  flex-direction: row;
  background-color: #EB6B2F;
  color: #fff;
  height: 40px;
  font-size: 18px;
  align-items: center;
  padding: 0px 30px;
  font-weight: 500;
}
body #branches .content aside section h2 svg {
  margin-right: 10px;
}
body #branches .content aside section address {
  background: #FFFBFA;
  padding: 20px;
  cursor: pointer;
  position: relative;
}
body #branches .content aside section address::after {
  content: " ";
  height: 1px;
  background-color: #FFF1EB;
  width: 90%;
  position: absolute;
  bottom: 0px;
}
body #branches .content aside section address:last-child::after {
  display: none;
}
body #branches .content aside section address h3 {
  font-size: 16px;
  font-weight: 300;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  transition: all 0.35s;
}
body #branches .content aside section address h3 svg {
  transition: all 0.35s;
}
body #branches .content aside section address p {
  display: flex;
  font-size: 14px;
  line-height: 18px;
  font-weight: 200;
}
body #branches .content aside section address .btn {
  display: none;
}
body #branches .content aside section address.selected h3 {
  font-weight: 500;
}
body #branches .content aside section address.selected h3 svg {
  color: #FF7433 !important;
  transform: rotate(180deg);
}
body #branches .content .map-holder {
  width: 80rem;
  display: flex;
  flex-direction: column;
}
body #branches .content .map-holder .map-info-window {
  max-width: 150px;
}
body #branches .content .map-holder .select-branch {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 40px;
  border-radius: 5px;
  width: 400px;
  align-self: center;
  background-color: #FCFCFC;
}
body #branches .content .map-holder .select-branch h2 {
  font-family: 18px;
  margin-top: 20px;
  text-align: center;
  color: #898989;
  font-weight: 200;
}
body #register {
  background-color: #f5f9fa;
  padding-top: 110px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
body #register h1 {
  width: 100%;
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 40px;
}
body #register .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100vw - 200px);
  align-self: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4%;
  justify-content: space-between;
}
body #register .content .column {
  width: 46%;
}
body #register .content .column p {
  font-size: 18px;
  line-height: 24px;
}
body #register .content .column .form-area {
  padding: 40px 30px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4%;
  margin-bottom: 30px;
}
body #register .content .column .form-area .custom-input {
  width: 46%;
}
body #register .content .column .form-area .custom-input .field-wrapper {
  border-color: #C4C4C4;
  background-color: #F5F9FA;
  border-radius: 20px;
}
body #register .content .column .button-holder {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
}
body #register .content .column .button-holder .btn {
  display: flex;
  width: 70%;
  height: 50px;
}
body #register .content .column legend {
  font-size: 14px;
  margin-bottom: 15px;
  font-weight: 300;
}
body #register .content .column legend a {
  color: #007FC7;
  text-decoration: none;
}
body .register-success {
  padding-top: calc(110px + 40px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
body .register-success .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100vw - 200px);
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
}
body .register-success .content .column h2 {
  color: #001427;
  font-size: 24px;
  margin: 24px 0px;
}
body .register-success .content .column p {
  font-size: 18px;
  line-height: 24px;
  width: 500px;
  margin-bottom: 80px;
}
body .register-success .content .column b {
  display: flex;
  flex-direction: row;
  width: 100%;
  font-weight: 400;
  margin-bottom: 10px;
}
body .register-success .content .column a {
  color: #1B97D5;
  text-decoration: none;
}
body .forgot-password {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFF;
  width: 340px;
  padding: 40px 20px;
  border-radius: 20px;
}
body .forgot-password h2 {
  font-size: 24px;
  margin-bottom: 15px;
}
body .forgot-password p {
  font-size: 14px;
  text-align: center;
  margin-bottom: 40px;
}
body .forgot-password .custom-input label {
  text-align: center;
}
body .forgot-password .custom-input .field-wrapper {
  background-color: #F9FCFE;
  border-radius: 20px;
  border-color: #C4C4C4;
}
body .forgot-password .custom-input .field-wrapper input {
  text-align: center;
}
body .forgot-password .btn {
  height: 50px;
}
body .verify-account {
  padding-top: 110px;
  background-color: #F5F9FA;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
body .verify-account .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: calc(100vw - 200px);
  align-self: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
body .verify-account .content #loading {
  justify-content: flex-start;
  width: 32px;
  flex: unset;
}
body .verify-account .content h1 {
  font-size: 24px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
body .verify-account .content h1 span {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 10px;
}
body .verify-account .content p {
  font-size: 18px;
}
body .recover-password {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: calc(110px + 40px) 0px 40px 0px;
  background-color: #f5f9fa;
}
body .recover-password .box {
  width: 320px;
  padding: 30px 20px;
}
body .recover-password .box h1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 30px;
  font-size: 20px;
}
body .recover-password .box h1 svg {
  margin-right: 10px;
}
body .recover-password .box .custom-input .field-wrapper {
  border-radius: 20px;
}
body .recover-password .box .buttons-holder {
  margin-top: 20px;
}
body .recover-password .box .buttons-holder .btn {
  height: 50px;
}
body .recover-password .box .buttons-holder .btn.link {
  height: 20px;
}
body .guest-user {
  max-width: 420px;
  padding: 30px 20px;
  text-align: center;
}
body .guest-user h2 {
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 20px;
}
body .guest-user p {
  font-weight: 200;
  font-size: 14px;
  margin-bottom: 40px;
}
body .guest-user .custom-input .field-wrapper {
  border-radius: 20px;
  background-color: #F2F2F2;
  border-color: #C4C4C4;
}
body .guest-user .btn-holder {
  margin-top: 30px;
}
body .guest-user .btn-holder .btn {
  height: 50px;
}
body .pop-detail {
  background-color: #FFF;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  max-height: 100vh;
  position: relative;
}
body .pop-detail .close-btn {
  top: 10px;
  right: 10px;
  padding: 0px;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  box-shadow: 0px 2px 6px rgba(80, 80, 80, 0.24);
  border-radius: 100%;
}
body .pop-detail .close-btn svg {
  background-color: #F2F2F2;
  padding: 10px;
  width: 18px;
  height: 18px;
  border-radius: 100%;
}
body .pop-detail #detail {
  padding-top: 20px;
}
body footer .footer-perks {
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 40px;
  padding-top: 56px;
  padding-bottom: 56px;
}
body footer .footer-perks .title {
  display: none;
}
body footer .footer-perks section {
  display: flex;
  flex-direction: column;
  width: 25%;
  position: relative;
}
body footer .footer-perks section::after {
  content: " ";
  position: absolute;
  top: 0px;
  width: 1px;
  background-color: #f2f2f2;
  right: -10px;
  height: 100%;
}
body footer .footer-perks section:last-child::after {
  display: none;
}
body footer .footer-perks section h2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  font-size: 1.6vw;
  margin-bottom: 14px;
}
body footer .footer-perks section h2 svg {
  margin-right: 20px;
}
body footer .footer-perks section p {
  font-size: 1vw;
  line-height: 24px;
  margin-bottom: 15px;
}
body footer .footer-perks section small {
  font-size: 0.9vw;
  color: #EB6B2F;
}
body footer .footer-perks section .btn {
  padding: 0px;
  justify-content: flex-start;
  height: unset;
  margin: 0px;
  font-size: 1vw;
}
body footer .footer-sitemap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 32px;
  padding-right: 32px;
  padding-bottom: 40px;
  padding-right: 0px;
}
body footer .footer-sitemap nav {
  display: flex;
  flex-direction: column;
}
body footer .footer-sitemap nav img {
  display: flex;
  margin-bottom: 20px;
}
body footer .footer-sitemap nav h2 {
  font-weight: 700;
  font-size: 1.4vw;
  margin-bottom: 16px;
  margin-top: 30px;
}
body footer .footer-sitemap nav section {
  display: flex;
  flex-direction: column;
}
body footer .footer-sitemap nav section a {
  color: #898989;
  text-decoration: underline;
  margin-bottom: 10px;
  cursor: pointer;
  font-size: 1vw;
}
body footer .footer-sitemap nav:first-child h2 {
  display: none;
}
body footer .footer-copyright {
  background-color: #EB6B2F;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-weight: 200;
  font-size: 1vw;
}
body footer .contact-area {
  display: flex;
  flex-direction: column;
}
body footer .contact-area h3 {
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
}
body footer .contact-area .phone {
  color: #0976AD;
  margin-top: 20px;
}
body footer .contact-area .social-links {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  gap: 30px;
}
/****************************************
    MOBILE VERSION
****************************************/
@media screen and (max-width: 1080px) {
  @keyframes slideFromRight {
    0% {
      transform: translateX(-200%);
    }
    100% {
      transform: translateX(0%);
    }
  }
  body #side-panel {
    align-items: flex-start;
  }
  body #side-panel .close-btn {
    display: flex !important;
    position: absolute;
    width: 35px;
    height: 35px;
    z-index: 1;
    right: 0px;
    top: 0px;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0px;
  }
  body #side-panel .close-btn svg {
    color: #CCC;
  }
  body #side-panel .side-content {
    width: 100%;
  }
  body #side-panel .button-helper {
    display: none;
  }
  body #side-panel #promos-swiper .slider .slide-item .card .img-holder {
    width: 80px !important;
    height: 60px !important;
  }
  body #side-panel #promos-swiper .slider .slide-item .card .texts-holder {
    width: calc(100% - 85px) !important;
  }
  body #side-panel #promos-swiper .slider .slide-item .card .texts-holder h2 {
    padding: 0px !important;
  }
  body #side-panel #promos-swiper .slider .slide-item .card .texts-holder big {
    font-size: 30px !important;
  }
  body main {
    max-height: -webkit-fill-available;
    position: relative;
  }
  body main:has(.cart-template) {
    overflow: hidden;
  }
  body main:has(.cart-template) #cta-whatsapp {
    display: none;
  }
}
@media only screen and (max-width: 1080px) and (hover: none) and (pointer: coarse) {
  body:not([data-browser=Safari]) main {
    max-height: calc(100vh - 60px);
  }
}
@media only screen and (max-width: 1080px) and (hover: none) and (pointer: coarse) and (display-mode: standalone) {
  body:not([data-browser=Safari]) main {
    max-height: calc(100vh - 0px);
  }
}
@media screen and (max-width: 1080px) {
  body header .gray-bar {
    padding: 0px;
    padding-left: 16px;
    padding-right: 16px;
  }
  body header .gray-bar span {
    font-size: 11px;
  }
  body header .gray-bar .buttons-holder {
    display: none;
  }
  body header nav {
    padding: 0px;
    justify-content: space-between;
    padding-left: 16px;
    padding-right: 16px;
  }
  body header nav .logo-holder {
    padding: 0px;
    width: 40px;
    background-image: url("@Assets/logo-mobile.png");
    background-repeat: no-repeat;
    background-position: center center;
  }
  body header nav section {
    flex-direction: row;
    justify-content: flex-end;
  }
  body header nav section a {
    display: none;
  }
  body header nav section button {
    margin-left: 30px;
    padding: 0px;
  }
  body header nav section button:nth-last-child(1), body header nav section button:nth-last-child(2), body header nav section button:nth-last-child(4) {
    display: flex;
  }
  body header nav section button:first-of-type {
    margin-left: 0px;
  }
}
@media screen and (max-width: 1080px) {
  body #home .home-banners .banner-item {
    height: 550px;
  }
  body #home .home-special {
    flex-direction: column;
  }
  body #home .home-special .column:first-child {
    margin-bottom: 40px;
  }
  body #home .home-special .column:first-child .btn {
    width: 100%;
  }
  body #home .home-special .column .random-products {
    gap: 15px;
  }
  body #home .home-special .column .random-products .product-holder {
    width: 20vw;
    height: 20vw;
  }
  body #home .home-promote {
    flex-direction: column;
  }
  body #home .home-promote img {
    width: 100%;
    margin-bottom: 24px;
  }
  body #home .home-promote .texts {
    width: calc(100% - 32px);
    padding-bottom: 30px;
  }
  body #home .home-promote .texts .btn {
    width: 100%;
  }
  body #home .home-subanner {
    background-position: left bottom;
    align-items: unset;
    padding-top: 40px;
    height: unset;
    background-size: cover;
  }
  body #home .home-subanner::before {
    background: linear-gradient(180deg, #FFFFFF 30%, rgba(255, 255, 255, 0) 68.63%);
  }
  body #home .home-subanner .texts {
    width: 100%;
    margin-bottom: 50px;
  }
  body #home .home-categories .slider .swiper-wrapper .item .category-item {
    width: 90px !important;
    height: 90px !important;
  }
  body #home .home-promo {
    display: none;
  }
}
@media screen and (max-width: 1080px) {
  body #about .about-banners .banner-item {
    flex-direction: column;
    height: unset;
    background: linear-gradient(180deg, #f1edec 30%, white 80%);
  }
  body #about .about-banners .banner-item .img-holder {
    width: 100%;
    height: 400px;
    background-size: cover;
    position: relative;
  }
  body #about .about-banners .banner-item .img-holder::after {
    content: " ";
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 0px;
    width: 100%;
    height: 100%;
    background: linear-gradient(360deg, #f1edec 1%, #FFFFFF01 50%);
  }
  body #about .about-banners .banner-item .texts {
    padding-left: 16px;
    padding-right: 16px;
    width: unset;
    margin: 0px;
  }
  body #about .about-banners .banner-item .texts h1 {
    font-size: 56px;
    margin-top: 10px;
    letter-spacing: -0.2rem;
    height: 85px;
  }
  body #about .about-banners .banner-item .texts h1::after {
    left: 5%;
    top: 40%;
  }
  body #about .about-banners .banner-item .texts h3 {
    font-size: 24px;
    margin-bottom: 25px;
  }
  body #about .about-banners .banner-item .texts p {
    font-size: 16px;
  }
  body #about .about-vmv {
    flex-direction: column;
    width: auto;
    gap: 20px;
  }
  body #about .about-vmv .item {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: auto;
    position: relative;
  }
  body #about .about-vmv .item img {
    height: unset;
    width: 60px;
  }
  body #about .about-vmv .item .texts {
    padding-left: 30px;
    align-items: flex-start;
  }
  body #about .about-vmv .item .texts h2 {
    font-size: 24px;
  }
  body #about .about-vmv .item .texts p {
    font-size: 16px;
    line-height: 20px;
    text-align: left;
  }
  body #about .about-subanner {
    flex-direction: column-reverse;
    align-items: flex-end;
  }
  body #about .about-subanner::after {
    display: none;
  }
  body #about .about-subanner img {
    width: 100%;
    position: relative;
  }
  body #about .about-subanner .texts {
    padding: 20px 40px;
  }
  body #about .about-subanner .texts h2 {
    font-size: 32px;
    margin-bottom: 10px;
  }
  body #about .about-subanner .texts h1 {
    font-size: 42px;
    font-weight: 200;
  }
  body #about .about-subanner .texts p {
    font-size: 16px;
    line-height: 20px;
    padding: unset;
  }
  body #about .about-subanner .texts h3 {
    font-size: 28px;
    line-height: 36px;
    text-align: center;
  }
  body #about .about-punch {
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: column-reverse;
    padding-bottom: 30px;
    padding-top: 0px;
    background-color: transparent;
    gap: 30px;
  }
  body #about .about-punch .likeit {
    width: unset;
  }
  body #about .about-punch .likeit h2 {
    font-size: 24px;
    text-align: center;
  }
  body #about .about-punch .likeit h3 {
    font-size: 16px;
    line-height: 20px;
  }
  body #about .about-punch .likeit .btn {
    width: 100%;
  }
  body #about .about-punch .closer {
    width: unset;
  }
  body #about .about-punch .closer h2 {
    font-size: 32px;
    margin-bottom: 10px;
  }
  body #about .about-punch .closer p {
    font-size: 18px;
    line-height: 24px;
  }
  body #about .about-punch .closer .city-item big {
    font-size: 24px;
  }
  body #about .about-punch .closer .city-item a {
    font-size: 14px;
  }
}
@media screen and (max-width: 1080px) {
  body #closer-store-warning {
    font-size: 12px;
    padding: 0px;
    padding-left: 16px;
    padding-right: 16px;
    padding-right: 30px;
  }
  body #closer-store-warning b {
    display: contents;
    width: auto;
    margin: 0px 2px;
  }
  body #closer-store-warning button {
    right: 16px;
  }
}
@media screen and (max-width: 1080px) {
  body #business .business-banner {
    padding: 0px;
    padding-left: 16px;
    padding-right: 16px;
    background-position: top center;
    height: 300px;
  }
  body #business .business-banner h2 {
    width: unset;
    font-size: 52px;
  }
  body #business .business-banner h3 {
    width: unset;
    font-size: 52px;
  }
  body #business .business-why {
    padding: 20px 0px;
    padding-left: 16px;
    padding-right: 16px;
  }
  body #business .business-why h2 {
    font-size: 26px;
    text-align: center;
    margin-bottom: 30px;
  }
  body #business .business-why .why-block {
    flex-direction: column;
    gap: 10px;
  }
  body #business .business-why .why-block section {
    width: unset;
    padding: 0px;
    flex-direction: row;
    align-items: center;
    border-bottom: 1px solid #F2F2F2;
    padding-bottom: 5px;
    margin-bottom: 5px;
  }
  body #business .business-why .why-block section:last-child {
    border-bottom: none;
  }
  body #business .business-why .why-block section img {
    width: 35px;
    height: unset;
    margin-bottom: 0px;
  }
  body #business .business-why .why-block section p {
    font-size: 16px;
    line-height: 20px;
    padding-left: 20px;
    text-align: left;
  }
  body #business .business-calculator {
    flex-direction: column;
    padding: 20px 0px;
    gap: unset;
    padding-left: 16px;
    padding-right: 16px;
  }
  body #business .business-calculator section {
    width: unset;
    padding-bottom: 0px;
  }
  body #business .business-calculator section h2 {
    font-size: 40px;
    text-align: center;
  }
  body #business .business-calculator section p {
    margin-bottom: 0px;
  }
  body #business .business-calculator .download-area {
    display: none;
  }
  body #business .business-calculator aside {
    width: auto;
    padding: 20px 0px;
    margin-top: 20px;
    width: 95%;
    padding-left: 16px;
    padding-right: 16px;
  }
  body #business .business-calculator aside h2 {
    font-size: 24px;
  }
  body #business .business-calculator aside fieldset input {
    width: calc(100% - 4px);
    font-size: 24px;
  }
  body #business .business-calculator aside p {
    font-size: 14px;
    line-height: 20px;
  }
  body #business .business-calculator aside big {
    font-size: 60px;
  }
  body #business .business-calculator aside small {
    font-size: 14px;
    line-height: 18px;
  }
  body #business .business-calculator aside + .download-area {
    display: flex;
    margin-top: 20px;
    text-align: center;
  }
  body #business .business-calculator aside + .download-area h3 {
    font-size: 18px;
  }
  body #business .business-start {
    padding: 30px 0px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: transparent;
  }
  body #business .business-start h2 {
    font-size: 24px;
  }
  body #business .business-start h3 {
    font-size: 16px;
    line-height: 20px;
  }
  body #business .business-start .blocks {
    gap: 20px;
    flex-direction: column;
  }
  body #business .business-start .blocks section {
    width: unset;
    background-color: #F9FCFE;
    border-radius: 24px;
    padding: 20px 10px;
  }
  body #business .business-start .blocks section .icon-holder {
    margin-bottom: 10px;
  }
  body #business .business-start .blocks section .icon-holder svg {
    width: 30px;
  }
  body #business .business-start .blocks section .icon-holder::after {
    width: 40px;
    height: 40px;
  }
  body #business .business-start .blocks section h2 {
    font-size: 18px;
    margin-bottom: 5px;
  }
  body #business .business-start .blocks section h2 b {
    width: 22px;
    height: 22px;
    font-size: 14px;
  }
  body #business .business-start .blocks section p {
    font-size: 14px;
    line-height: 18px;
  }
  body #business .business-reasons {
    display: none;
  }
  body #business .business-subanner {
    flex-direction: column-reverse;
  }
  body #business .business-subanner::after {
    background: linear-gradient(269.07deg, #F9FCFE 0%, rgba(255, 255, 255, 0) 40%);
  }
  body #business .business-subanner img {
    width: 100%;
  }
  body #business .business-subanner .texts {
    width: auto;
    padding: 20px 0px;
    padding-left: 16px;
    padding-right: 16px;
  }
  body #business .business-subanner .texts h2 {
    font-size: 24px;
  }
  body #business .business-subanner .texts p {
    font-size: 16px;
    line-height: 20px;
  }
  body #business .business-subanner .texts .buttons-holder {
    flex-direction: column;
    gap: 20px;
  }
}
@media screen and (max-width: 1080px) {
  body #branches {
    padding: 30px 0px;
    padding-top: 110px;
  }
  body #branches h1 {
    text-align: center;
    font-size: 22px;
    line-height: 28px;
    padding-left: 16px;
    padding-right: 16px;
  }
  body #branches .content {
    flex-direction: column-reverse;
  }
  body #branches .content aside {
    width: 100%;
  }
  body #branches .content aside section address h3 svg {
    display: none;
  }
  body #branches .content aside section address .btn {
    display: flex;
    float: right;
    width: 130px;
    height: 30px;
    margin-top: 10px;
  }
  body #branches .content .map-holder {
    width: 100%;
    height: 250px;
  }
}
@media screen and (max-width: 1080px) {
  body #faqs {
    padding: 0px;
    padding-top: 110px;
  }
  body #faqs .search-area {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  body #faqs .search-area .content {
    width: unset;
  }
  body #faqs .search-area .content h1 {
    font-size: 24px;
  }
  body #faqs .search-area .content p {
    font-size: 14px;
    line-height: 18px;
  }
  body #faqs .search-area .content fieldset {
    width: 100%;
  }
  body #faqs .search-area .content fieldset input {
    width: calc(100% - 40px);
  }
  body #faqs .questions-area {
    flex-direction: column;
    width: 100%;
  }
  body #faqs .questions-area aside {
    width: auto;
    padding: 0px;
  }
  body #faqs .questions-area aside h2 {
    font-size: 20px;
    margin-bottom: 20px;
    padding-left: 16px;
    padding-right: 16px;
  }
  body #faqs .questions-area aside .group h3 {
    font-size: 16px;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 20px;
  }
  body #faqs .questions-area aside .group .q-item {
    padding-left: 16px;
    padding-right: 16px;
    border-bottom: none;
  }
  body #faqs .questions-area aside .group .q-item.current {
    background: #FFFBFA;
  }
  body #faqs .questions-area aside .group .q-item.current + .a-item {
    background: #FFFBFA;
    display: flex;
    padding: 0px 20px 20px 20px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 14px;
    line-height: 18px;
  }
  body #faqs .questions-area aside .group .q-item.current + .a-item p {
    text-align: justify;
  }
  body #faqs .questions-area .answer-holder {
    top: unset;
    margin-top: unset;
    width: auto;
    padding: 40px 10px;
    min-height: unset;
    background-color: transparent;
  }
  body #faqs .questions-area .answer-holder h4, body #faqs .questions-area .answer-holder p {
    display: none;
  }
  body #faqs .questions-area .answer-holder .contact-holder {
    padding: 0px;
    margin: 0px;
  }
}
@media screen and (max-width: 1080px) {
  body #catalog {
    padding-bottom: 120px;
  }
  body #catalog .filters-and-sorters {
    z-index: 10;
  }
  body #catalog .filters-and-sorters .content {
    padding-left: 16px;
    padding-right: 16px;
  }
  body #catalog .filters-and-sorters .content .other-filters {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;
    height: 34px;
  }
  body #catalog .filters-and-sorters .content .other-filters a {
    font-size: 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    padding: 0px 10px;
    background-color: #007FC7;
    border-radius: 29px;
    color: #fff;
    padding: 0px 15px;
    font-size: 12px;
    height: 34px;
  }
  body #catalog .filters-and-sorters .content .other-filters a svg {
    color: #fff;
    margin-right: 5px;
  }
  body #catalog .filters-and-sorters .content .filters-holder h2 {
    cursor: pointer;
    border: 1px solid #1289C5;
    border-radius: 29px;
    color: #1289C5;
    font-size: 16px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 42vw;
  }
  body #catalog .filters-and-sorters .content .filters-holder h2 svg {
    display: flex;
    margin-left: 10px;
  }
  body #catalog .filters-and-sorters .content .filters-holder h2.active-filters {
    background-color: #007FC7;
    color: #FFF;
  }
  body #catalog .filters-and-sorters .content .filters-holder h2.active-filters svg {
    color: #FFF !important;
  }
  body #catalog .filters-and-sorters .content .filters-holder .overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1;
  }
  body #catalog .filters-and-sorters .content .filters-holder .overlay .close-btn {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 5px;
  }
  body #catalog .filters-and-sorters .content .filters-holder .overlay .close-btn svg {
    color: #CCC;
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items {
    padding: unset;
    position: fixed;
    left: 0px;
    right: 0px;
    border-radius: unset;
    width: auto;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    box-shadow: 0px -4px 6px rgba(80, 80, 80, 0.24);
    align-items: center;
    background-color: #FFF;
    flex-direction: column;
    padding-top: 80px;
    transition: all 0.15s;
    background-color: #fff;
    align-items: unset;
    overflow: hidden;
    overflow-y: auto;
    bottom: -100%;
    z-index: 1;
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items::after {
    content: " ";
    height: 4px;
    width: 32px;
    border-radius: 4px;
    background-color: #C4C4C4;
    position: absolute;
    top: 20px;
    left: 50%;
    margin-left: -16px;
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items.open {
    height: calc(100vh - 230px);
    bottom: 0PX;
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items::before {
    content: "Filtrar resultados";
    position: absolute;
    top: 45px;
    left: 0px;
    right: 0px;
    text-align: center;
    font-size: 18px;
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overlay-layer {
    display: none;
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 400px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: auto;
    margin: 0px;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .item button {
    display: flex;
    flex-direction: row;
    width: 100vw;
    height: 40px;
    border-bottom: 1px solid #F2F2F2;
    padding-left: 16px;
    padding-right: 16px;
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .item button span {
    display: flex;
    flex-direction: row;
    font-size: 18px;
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .item button svg {
    margin: 0px;
    position: absolute;
    right: 10px;
    transition: all 0.35s;
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .item .box {
    position: unset;
    top: unset;
    border-radius: unset;
    box-shadow: unset;
    max-height: unset;
    width: 100%;
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .item .box .custom-checkbox {
    border-bottom: none;
    font-size: 14px;
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .item .box .custom-checkbox:nth-of-type(2n) {
    background-color: #F9FCFE;
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .item.open button {
    font-weight: 400;
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .item.open button svg {
    transform: rotate(90deg);
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .linex-holder {
    display: flex;
    align-self: center;
    padding-left: 16px;
    padding-right: 16px;
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .linex-holder label {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items .overflow .linex-holder label span {
    margin-left: 10px;
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items .buttons-mobile {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  body #catalog .filters-and-sorters .content .filters-holder .filterable-items .buttons-mobile .primary {
    height: 50px;
  }
  body #catalog .filters-and-sorters .content .sorters-holder .overlay-layer::after {
    position: absolute;
    content: "✕";
    height: 20px;
    position: absolute;
    right: 10px;
    top: 5px;
    color: #CCC;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    line-height: 20px;
    vertical-align: middle;
  }
  body #catalog .filters-and-sorters .content .sorters-holder .overlay-layer .close-btn {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 10px;
    top: 5px;
  }
  body #catalog .filters-and-sorters .content .sorters-holder .overlay-layer .close-btn svg {
    color: #CCC;
  }
  body #catalog .filters-and-sorters .content .sorters-holder .item {
    display: flex;
    margin: 0px;
  }
  body #catalog .filters-and-sorters .content .sorters-holder .item button {
    cursor: pointer;
    border: 1px solid #1289C5;
    border-radius: 29px;
    color: #1289C5;
    font-size: 18px;
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 42vw;
    text-align: center;
  }
  body #catalog .filters-and-sorters .content .sorters-holder .item button svg {
    display: none;
  }
  body #catalog .filters-and-sorters .content .sorters-holder .item button svg:nth-of-type(2) {
    display: flex;
    margin-left: 5px;
  }
  body #catalog .filters-and-sorters .content .sorters-holder .item button span {
    font-size: 16px;
  }
  @keyframes showBoxBottomTop {
    0% {
      transform: translateY(200%);
    }
    100% {
      transform: translateY(0%);
    }
  }
  body #catalog .filters-and-sorters .content .sorters-holder .item .box {
    padding: unset;
    position: fixed;
    left: 0px;
    right: 0px;
    border-radius: unset;
    width: auto;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    box-shadow: 0px -4px 6px rgba(80, 80, 80, 0.24);
    align-items: center;
    background-color: #FFF;
    flex-direction: column;
    padding-top: 80px;
    transition: all 0.15s;
    background-color: #fff;
    align-items: unset;
    overflow: hidden;
    overflow-y: auto;
    bottom: -100%;
    z-index: 1;
    animation: 0.15s ease-in-out 0s 1 showBoxBottomTop;
    bottom: 0px;
    padding-top: 80px;
    height: 300px;
    top: unset;
  }
  body #catalog .filters-and-sorters .content .sorters-holder .item .box::after {
    content: " ";
    height: 4px;
    width: 32px;
    border-radius: 4px;
    background-color: #C4C4C4;
    position: absolute;
    top: 20px;
    left: 50%;
    margin-left: -16px;
  }
  body #catalog .filters-and-sorters .content .sorters-holder .item .box::before {
    content: "Ordenar resultados";
    position: absolute;
    top: 45px;
    left: 0px;
    right: 0px;
    text-align: center;
    font-size: 18px;
  }
  body #catalog .filters-and-sorters .applied-filters {
    display: none;
  }
  body #catalog .catalog-items {
    padding-left: 16px;
    padding-right: 16px;
    display: grid;
    gap: 0.75rem;
    grid-template-columns: repeat(auto-fit, minmax(48%, 0.2fr));
    padding-top: 40px;
    padding-bottom: 20px;
  }
  body #catalog .load-more-holder {
    margin-bottom: 40px;
  }
  body #catalog .catalog-empty {
    width: auto;
    padding-left: 16px;
    padding-right: 16px;
    text-align: center;
  }
  body #catalog .catalog-end img {
    width: 120px;
    height: 120px;
  }
  body #catalog .catalog-end .btn {
    font-size: 12px;
  }
}
@media screen and (max-width: 1080px) {
  body .product-gallery {
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
  }
  body .product-gallery img {
    display: flex;
  }
}
@media screen and (max-width: 1080px) {
  body .product-discount {
    background-color: #EB6B2F;
    color: #FFF;
    height: 24px;
    width: 48px;
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 1080px) {
  body .product-variants {
    overflow: hidden;
  }
}
@media screen and (max-width: 1080px) {
  body .product-prices {
    margin-bottom: 20px;
  }
  body .product-prices big {
    font-size: 24px;
  }
  body .product-prices small {
    font-size: 14px;
  }
}
@media screen and (max-width: 1080px) {
  body .product-hearth {
    width: 30%;
  }
}
@media screen and (max-width: 1080px) {
  body .product-texts span {
    font-size: 12px;
  }
  body .product-texts h2 {
    font-size: 12px;
    line-height: 16px;
    height: 30px;
  }
  body .product-texts h3 {
    font-size: 12px;
    margin-bottom: 11px;
  }
  body .product-texts a {
    color: #1289C5;
    font-size: 14px;
    display: none;
  }
  body .product-texts .product-hearth {
    display: none;
  }
}
@media screen and (max-width: 1080px) {
  body .product-increaser .container button svg {
    width: 18px;
    height: 18px;
  }
}
@media screen and (max-width: 1080px) {
  body .product-item .product-increaser-holder .product-increaser + .product-buttons button {
    padding: 0px;
  }
}
@media screen and (max-width: 1080px) {
  body #detail .content {
    flex-direction: column;
    padding: 0px;
    max-width: calc(100vw - 32px);
    margin: 0px;
    padding-left: 16px;
    padding-right: 16px;
  }
  body #detail .content .holder-gallery {
    width: unset;
  }
  body #detail .content .holder-info {
    width: unset;
  }
  body #detail .content .holder-info .product-prices {
    justify-content: space-between;
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    padding: 10px 0px;
  }
  body #detail .content .holder-info .increaser-and-button {
    flex-direction: column;
  }
  body #detail .content .holder-info .increaser-and-button .product-increaser {
    width: unset;
    margin-bottom: 20px;
  }
  body #detail .content .holder-info .increaser-and-button .product-buttons {
    width: unset;
    margin-bottom: 40px;
  }
  body #detail .content .holder-info .increaser-and-button .product-buttons .btn {
    background-color: #007FC7;
    justify-content: space-between;
  }
  body #detail .content .holder-info .increaser-and-button .product-buttons .btn small {
    display: flex;
  }
  body #detail .content .holder-info .increaser-and-button .product-buttons legend {
    display: none;
  }
  body #detail .content .holder-characteristics h2 {
    margin-bottom: 30px;
  }
  body #detail .content .holder-characteristics .table {
    justify-content: space-between;
  }
  body #detail .content .holder-characteristics .table .item {
    width: 48%;
    margin-right: 0px;
  }
  body #detail .holder-opinions .content .title-and-button .btn {
    display: none;
  }
  body #detail .holder-opinions .content .opinion-form {
    width: unset;
  }
  body #detail .holder-opinions .opinions {
    width: calc(100vw - 24px);
    max-width: unset;
    padding: 20px 10px;
  }
  body #detail .holder-opinions .opinions .opinion {
    width: calc(100% - 32px) !important;
  }
  body #detail .holder-opinions .bottom-button {
    flex-direction: column-reverse;
    margin-top: 30px;
  }
  body #detail .holder-related {
    padding: 40px 0px 0px 0px;
  }
  body #detail .holder-related .content {
    padding: 0px;
    max-width: unset;
  }
  body #detail .holder-related .content .title-and-controllers {
    width: calc(100% - 32px);
    padding-left: 16px;
    padding-right: 16px;
  }
  body #detail .holder-related .content .product-related-swiper {
    width: calc(100% - 32px);
    padding: 20px 10px;
  }
  body #detail .bottom-button {
    display: flex;
    padding: 0px 40px;
  }
  body #detail .bottom-button .btn {
    height: 58px;
  }
}
@media screen and (max-width: 1080px) {
  body .user-template .user-template-side {
    display: none;
  }
  body .user-template .user-template-content {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  body .user-template .user-template-content .content h1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  body .user-template .user-template-content .content h1 svg {
    display: flex;
  }
  body .user-template .user-template-content .content .user-profile .card {
    flex-wrap: wrap;
    overflow: hidden;
    margin-bottom: 30px;
  }
  body .user-template .user-template-content .content .user-profile .card .user-card {
    align-items: flex-start;
  }
  body .user-template .user-template-content .content .user-profile .card .user-card .img-holder {
    width: 85px;
    height: 85px;
  }
  body .user-template .user-template-content .content .user-profile .card .user-card .info-holder {
    width: calc(100% - 100px);
    margin-top: 10px;
  }
  body .user-template .user-template-content .content .user-profile .card .user-card .info-holder .userid-and-tag .titles {
    margin-right: 0px;
  }
  body .user-template .user-template-content .content .user-profile .card .user-card .info-holder .userid-and-tag .tag {
    display: none;
  }
  body .user-template .user-template-content .content .user-profile .card .user-card .table-row {
    width: 100%;
    margin: unset;
    padding: unset;
    margin-top: 20px;
    justify-content: space-between;
  }
  body .user-template .user-template-content .content .user-profile .card .user-card .table-row .table-col {
    overflow: hidden;
  }
  body .user-template .user-template-content .content .user-profile .card .user-card .table-row .table-col:nth-child(1) {
    display: none;
  }
  body .user-template .user-template-content .content .user-profile .card .user-card .table-row .table-col:nth-child(2) {
    width: 45%;
  }
  body .user-template .user-template-content .content .user-profile .card .user-card .table-row .table-col:nth-child(3) {
    width: 20%;
  }
  body .user-template .user-template-content .content .user-profile .card .user-card .table-row .table-col:nth-child(4) {
    width: 20%;
  }
  body .user-template .user-template-content .content .user-profile .card .user-card .table-row .table-col label {
    font-size: 12px;
  }
  body .user-template .user-template-content .content .user-profile .card .user-card .table-row .table-col span {
    font-size: 11px;
  }
  body .user-template .user-template-content .content .user-profile .region-and-seller {
    flex-direction: column;
  }
  body .user-template .user-template-content .content .user-profile .region-and-seller .is-holder {
    width: 100%;
  }
  body .user-template .user-template-content .content .user-profile .region-and-seller .holder-seller .seller-card .info-holder {
    flex-wrap: wrap;
  }
  body .user-template .user-template-content .content .user-profile .region-and-seller .holder-seller .seller-card .info-holder .titles h2 {
    padding-left: 22px;
  }
  body .user-template .user-template-content .content .user-profile .region-and-seller .holder-seller .seller-card .info-holder .whatsapp {
    height: 40px;
  }
  body .user-template .user-template-content .content .nothing {
    height: calc(100vh - calc(110px + 120px));
  }
  body .user-template .user-template-content .content .nothing h2 {
    text-align: center;
  }
  body .user-template .user-template-content .content .nothing p {
    width: unset;
  }
  body .user-template .user-template-content .content .nothing .search-keyword {
    width: 90%;
  }
}
@media screen and (max-width: 1080px) {
  body .address-card .table-row {
    padding-left: 0px;
  }
}
@media screen and (max-width: 1080px) {
  body #addresses .title-and-button {
    display: flex;
    flex-direction: column;
  }
  body #addresses .title-and-button h1 {
    width: 100%;
    margin-bottom: 20px;
  }
  body #addresses .title-and-button .btn {
    width: 150px;
    height: 40px;
    align-self: flex-end;
  }
  body #addresses .addresses-holder {
    flex-direction: column;
  }
  body #addresses .addresses-holder .address-card {
    width: calc(100% - 32px);
  }
}
@media screen and (max-width: 1080px) {
  body #panel-pwa {
    padding-top: 30px;
    padding-left: 16px;
    padding-right: 16px;
  }
  body #panel-pwa h1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    margin-bottom: 20px;
    padding-right: 10px;
    font-weight: 400;
  }
  body #panel-pwa h1 svg:first-child {
    margin-right: 10px;
    color: #EB6B2F !important;
  }
  body #panel-pwa h1 svg:last-child {
    position: absolute;
    right: 20px;
  }
  body #panel-pwa .instructions-holder img {
    width: 100%;
  }
  body #panel-pwa .video-holder {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  body #panel-pwa + .close-btn {
    display: none !important;
  }
}
@media screen and (max-width: 1080px) {
  body #panel-menu {
    padding: 0px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    flex: 1;
    max-height: fit-content;
  }
  body #panel-menu .in-store {
    display: flex;
    background-color: #1B97D5;
    width: 100%;
    height: 36px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    margin-bottom: 20px;
  }
  body #panel-menu .in-store svg {
    color: #fff !important;
    margin-right: 5px;
  }
  body #panel-menu label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
  }
  body #panel-menu label span {
    margin-left: 10px;
  }
  body #panel-menu nav {
    padding-left: 22px;
    padding-right: 22px;
    display: flex;
    flex-direction: column;
  }
  body #panel-menu nav a {
    border-bottom: 1px solid #F2F2F2;
    height: 55px;
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: #505050;
    cursor: pointer;
    position: relative;
  }
  body #panel-menu nav a span {
    margin-left: 10px;
  }
  body #panel-menu nav a svg {
    color: #898989;
  }
  body #panel-menu nav a svg:last-child {
    position: absolute;
    right: 0px;
  }
  body #panel-menu nav a.pwa-isntall-btn {
    display: none;
  }
  body #panel-menu .promo-applier {
    max-width: unset;
    flex: 1;
    border-radius: 0px;
  }
  body #panel-menu .promo-applier h2 {
    font-size: 18px;
    line-height: 24px;
    margin: 0px;
    margin-bottom: 5px;
  }
  body #panel-menu .promo-applier p {
    text-align: center;
    line-height: 18px;
    font-size: 14px;
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 1080px) {
  body #panel-products {
    background-color: #F9FCFE;
    height: 100%;
    padding-top: 30px;
  }
  body #panel-products .flyout-categories {
    display: flex;
    flex-direction: column;
  }
  body #panel-products .flyout-categories h2 {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 22px;
    padding-right: 22px;
    margin-bottom: 20px;
  }
  body #panel-products .flyout-categories h2 svg {
    display: flex;
    width: 20px !important;
    margin-right: 10px;
  }
  body #panel-products .flyout-categories h2 svg:last-child {
    position: absolute;
    right: 0px;
  }
  body #panel-products .flyout-categories .categories-and-promos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  body #panel-products .flyout-categories .categories-and-promos .holder-categories {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: unset;
    padding-left: 22px;
    padding-right: 22px;
  }
  body #panel-products .flyout-categories .categories-and-promos .holder-categories p {
    display: flex;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 20px;
    padding-right: unset;
  }
  body #panel-products .flyout-categories .categories-and-promos .holder-categories .item {
    display: flex;
    flex-direction: column;
    width: 90px;
    margin-bottom: 15px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  body #panel-products .flyout-categories .categories-and-promos .holder-categories .item .img-holder {
    width: 70px;
    height: 70px;
    margin-bottom: 5px;
  }
  body #panel-products .flyout-categories .categories-and-promos .holder-categories .item label {
    display: flex;
    margin: 0px;
    font-size: 14px;
  }
  body #panel-products .flyout-categories .categories-and-promos #promos-swiper {
    margin-top: 20px;
  }
  body #panel-products + .close-btn {
    display: none !important;
  }
}
@media screen and (max-width: 1080px) {
  body #panel-collections {
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 30px;
    padding-right: 10px;
  }
  body #panel-collections .flyout-collections h2 {
    font-size: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    width: 100%;
  }
  body #panel-collections .flyout-collections h2 svg {
    display: flex;
    width: 20px;
    margin-right: 10px;
  }
  body #panel-collections .flyout-collections h2 svg:last-child {
    position: absolute;
    right: 0px;
    margin-right: 0px;
  }
  body #panel-collections .flyout-collections p {
    padding-right: unset;
    font-size: 14px;
    line-height: 18px;
    padding-right: 0px;
  }
  body #panel-collections .flyout-collections .items {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  body #panel-collections .flyout-collections .items button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 150px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    border-radius: 20px;
    overflow: hidden;
  }
  body #panel-collections .flyout-collections .items button::before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 20, 39, 0.55);
    content: " ";
  }
  body #panel-collections .flyout-collections .items button span {
    color: #FFFFFF;
    position: relative;
    font-size: 22px;
  }
  body #panel-collections + .close-btn {
    display: none !important;
  }
}
@media screen and (max-width: 1080px) {
  body #user-panel {
    padding-top: 40px;
  }
  body #user-panel h1 {
    font-size: 18px;
    margin-bottom: 20px;
    padding-right: 10px;
  }
  body #user-panel .user-card {
    width: auto;
  }
  body #user-panel hr {
    margin: 15px 0px 0px 5px;
  }
  body #user-panel nav a {
    position: relative;
  }
  body #user-panel nav a svg:last-child {
    position: absolute;
    right: 0px;
  }
}
@media screen and (max-width: 1080px) {
  body #panel-search {
    padding-top: 40px;
    background-color: #F9FCFE;
    height: 100%;
    padding-left: 22px;
    padding-right: 22px;
  }
  body #panel-search h2 {
    display: flex;
    flex-direction: row;
    font-size: 18px;
    align-items: center;
    position: relative;
  }
  body #panel-search h2 svg {
    margin-right: 10px;
  }
  body #panel-search h2 svg:last-child {
    position: absolute;
    right: 0px;
    margin-right: unset;
  }
  body #panel-search .search-area {
    display: flex;
    flex-direction: column;
    height: calc(100% - 30px);
    justify-content: center;
  }
  body #panel-search .search-area .search-keyword {
    margin-bottom: 50px;
  }
  body #panel-search .search-area .btn {
    height: 50px;
    margin-bottom: 15px;
  }
}
@media screen and (max-width: 1080px) {
  body #panel-advanced {
    background-color: #F9FCFE;
    height: 100%;
    padding: 40px 0px;
  }
  body #panel-advanced .flyout-search .content-area {
    padding-left: 22px;
    padding-right: 22px;
    width: auto;
    max-width: unset;
  }
  body #panel-advanced .flyout-search .content-area .search-keyword {
    display: none;
  }
  body #panel-advanced .flyout-search .content-area .search-categories {
    margin-bottom: 30px;
  }
  body #panel-advanced .flyout-search .content-area .search-categories .btn {
    width: 30%;
    font-size: 12px;
    height: 30px;
    text-decoration: none;
  }
  body #panel-advanced .flyout-search .content-area .search-prices {
    margin-bottom: 30px;
  }
  body #panel-advanced .flyout-search .content-area .search-prices h2 {
    font-size: 14px;
  }
  body #panel-advanced .flyout-search .content-area .search-prices .range-holder .range-slider-rail {
    width: 97%;
  }
  body #panel-advanced .flyout-search .content-area .search-prices .range-holder::before, body #panel-advanced .flyout-search .content-area .search-prices .range-holder::after {
    width: 12px;
    height: 12px;
  }
  body #panel-advanced .flyout-search .content-area .materials-and-colors {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  body #panel-advanced .flyout-search .content-area .materials-and-colors .search-materials {
    width: 100%;
  }
  body #panel-advanced .flyout-search .content-area .materials-and-colors .search-materials h2 {
    font-size: 14px;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 20px;
  }
  body #panel-advanced .flyout-search .content-area .materials-and-colors .search-materials .custom-checkbox span {
    font-size: 12px;
  }
  body #panel-advanced .flyout-search .content-area .materials-and-colors .search-colors {
    display: none;
  }
  body #panel-advanced .flyout-search .content-area .buttons-holder {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 220px;
    align-self: center;
    margin-top: 30px;
  }
  body #panel-advanced .flyout-search .content-area .buttons-holder .primary {
    height: 50px;
  }
}
@media screen and (max-width: 1080px) {
  body #panel-login {
    padding: 0px 0px;
    background-color: #001427;
    height: 100%;
  }
  body #panel-login .area-login {
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 40px;
    padding-bottom: 20px;
    background-color: #FFF;
  }
  body #panel-login .area-login h2 {
    font-size: 18px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  body #panel-login .area-login h2 svg {
    margin-right: 10px;
  }
  body #panel-login .area-register {
    padding-left: 22px;
    padding-right: 22px;
    padding-top: 30px;
    padding-bottom: 30px;
    height: 100%;
  }
  body #panel-login .area-register h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  body #panel-login .area-register p {
    font-size: 14px;
    line-height: 18px;
  }
}
@media screen and (max-width: 1080px) {
  body #cart {
    min-height: calc(100vh - 110px);
    overflow: auto;
  }
  body #cart .cart-list {
    border-radius: unset;
  }
  body #cart .cart-list .cart-list-content {
    max-height: calc(100vh - 460px);
    overflow: auto;
  }
  body #cart .cart-item .content-holder {
    position: relative;
  }
  body #cart .cart-item .content-holder h2 {
    font-size: 14px;
    margin-bottom: 10px;
    width: calc(100% - 40px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  body #cart .cart-item .content-holder .column {
    width: 100% !important;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  body #cart .cart-item .content-holder .column small {
    font-size: 12px;
    width: 100%;
    margin-bottom: 0px;
  }
  body #cart .cart-item .content-holder .column span {
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }
  body #cart .cart-item .content-holder .column span b {
    font-size: 12px;
  }
  body #cart .cart-item .content-holder .column label {
    font-size: 12px;
  }
  body #cart .cart-item .content-holder .column:last-of-type {
    margin-top: 5px;
  }
  body #cart .cart-item .content-holder .column:last-of-type .product-increaser {
    width: 50%;
  }
  body #cart .cart-item .content-holder .column:last-of-type .product-buttons {
    width: 40%;
  }
  body #cart .cart-item .content-holder .column:last-of-type .product-buttons legend b {
    font-size: 18px;
  }
  body #cart .cart-item .content-holder .icon-detelete {
    position: absolute;
    top: -8px;
    right: 0px;
    width: 32px;
    height: 32px;
    padding: 0px;
    background-color: #E8F5FB;
    border-radius: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  body #cart .cart-item .content-holder .icon-detelete svg {
    width: 16px;
  }
  body #cart .cart-template {
    flex-direction: column;
  }
  body #cart .cart-template section {
    flex-direction: column;
    width: auto;
    align-items: center;
  }
  body #cart .cart-template section:first-child {
    padding: unset;
    padding-top: 20px;
  }
  body #cart .cart-template section:last-child {
    padding: unset;
    width: unset;
    position: fixed;
    left: 0px;
    right: 0px;
    top: calc(100% - 80px);
    border-radius: unset;
    width: auto;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    overflow: hidden;
    box-shadow: 0px -4px 6px rgba(80, 80, 80, 0.24);
    align-items: center;
    background-color: #FFF;
    flex-direction: column-reverse;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 10px;
    transition: all 0.15s;
    background-color: #fff;
  }
  body #cart .cart-template section .content-header {
    flex-wrap: wrap;
  }
  body #cart .cart-template section .content-header h1 {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
  }
  body #cart .cart-template section .content-header .btn {
    justify-self: flex-end;
  }
  body #cart .cart-template section .content-header .btn.tertiary {
    display: flex;
  }
  body #cart .cart-template section .content-header .cart-stepper {
    display: none;
    width: 100%;
    margin-top: 10px;
  }
  body #cart .cart-template section .content-header .cart-stepper span::before {
    width: 76px;
  }
  body #cart .cart-template section .content-header .cart-stepper span:first-of-type::before {
    display: none;
  }
  body #cart .cart-template section .content-header .cart-stepper span.current {
    background-color: #FF7433;
  }
  body #cart .cart-template section .content-header .cart-stepper span.current::before {
    background-color: #FF7433;
  }
  body #cart .cart-template section .content-area {
    margin-top: 20px;
    height: calc(100vh - 400px);
    padding: 0px 20px;
  }
  body #cart .cart-template section .content-area #addresses .title-and-button .btn {
    background-color: #FFF;
    border: 1px solid #1289C5;
    color: #1289C5;
    font-size: 12px;
  }
  body #cart .cart-template section .content-area #addresses .title-and-button .btn svg {
    display: flex;
    margin-right: 10px;
  }
  body #cart .cart-template section .content-area #stores {
    margin-top: 20px;
    width: auto;
    align-self: unset;
  }
  body #cart .cart-template section .cart-summary {
    flex-direction: column-reverse;
    width: 100%;
    padding-top: 0px;
    box-shadow: unset !important;
  }
  body #cart .cart-template section .cart-summary .btn {
    background-color: #007FC7;
    margin-top: 0px;
    margin-bottom: 30px;
    display: flex;
  }
  body #cart .cart-template section .cart-summary .cart-motivation {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
  }
  body #cart .cart-template section .cart-motivation {
    width: auto;
    display: none;
  }
  body #cart .cart-template section .cart-active-promo {
    display: none;
  }
  body #cart .cart-template section .cart-minicart h2 {
    width: 100%;
  }
  body #cart .cart-template section .cart-minicart h3 {
    width: 100%;
  }
  body #cart .cart-template section .cart-minicart .scroll-area {
    height: 200px !important;
  }
  body #cart .cart-template section .cart-minicart .scroll-area .cart-item .content-holder .column:last-of-type .product-buttons legend {
    display: flex;
    flex-direction: row;
    margin-top: -20px;
  }
  body #cart .cart-template section .cart-minicart .scroll-area .cart-item .content-holder .column:last-of-type .product-buttons legend span {
    display: flex;
  }
  body #cart .cart-template section .cart-minicart .scroll-area .cart-item .content-holder .column:last-of-type .product-buttons legend b {
    display: flex;
  }
  body #cart .cart-template.cart-step-1 section .content-header h1 {
    width: 100%;
  }
  body #cart .cart-template.cart-step-1 section .content-header .btn {
    display: none !important;
  }
  body #cart .cart-template.cart-step-1 section:last-child .buttons-holder {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  body #cart .cart-template.cart-step-1 section:last-child .buttons-holder .btn.link {
    margin: 10px 0px 10px 0px;
    height: unset;
    width: 46%;
    display: flex;
    border: 1px solid #007FC7;
    height: 40px;
    text-decoration: none;
  }
  body #cart .cart-template.cart-step-1 section:last-child .buttons-holder .btn.link:last-of-type {
    display: flex;
  }
  body #cart .cart-template.cart-step-2 section .content-header h1, body #cart .cart-template.cart-step-3 section .content-header h1 {
    width: 100%;
  }
  body #cart .cart-template.cart-step-2 section .content-header .btn, body #cart .cart-template.cart-step-3 section .content-header .btn {
    display: none !important;
  }
  body #cart .cart-template.cart-step-2 section .content-area .title-and-button, body #cart .cart-template.cart-step-3 section .content-area .title-and-button {
    flex-direction: row;
  }
  body #cart .cart-template.cart-step-2 section .content-area .title-and-button h1, body #cart .cart-template.cart-step-3 section .content-area .title-and-button h1 {
    width: unset;
    margin-bottom: unset;
  }
  body #cart .cart-template.cart-step-2 section .content-area .title-and-button h1 svg, body #cart .cart-template.cart-step-3 section .content-area .title-and-button h1 svg {
    display: none !important;
  }
  body #cart .cart-template.cart-step-2 section .content-area .method-holder .method-content, body #cart .cart-template.cart-step-3 section .content-area .method-holder .method-content {
    flex-direction: column;
  }
  body #cart .cart-template.cart-step-2 section .content-area .method-holder .method-content .method-block h2, body #cart .cart-template.cart-step-3 section .content-area .method-holder .method-content .method-block h2 {
    margin-top: 20px;
    font-size: 18px;
  }
  body #cart .cart-template.cart-step-2 section .content-area .method-holder .method-content .method-block .deposit-holder, body #cart .cart-template.cart-step-3 section .content-area .method-holder .method-content .method-block .deposit-holder {
    padding-right: 0px;
  }
  body #cart .cart-template.cart-step-2 section .content-area .method-holder .method-content .method-block .deposit-holder .deposit-row, body #cart .cart-template.cart-step-3 section .content-area .method-holder .method-content .method-block .deposit-holder .deposit-row {
    padding-right: 0px;
  }
  body #cart .cart-template.cart-step-2 section .content-area .method-holder .method-content .method-block .banks-holder, body #cart .cart-template.cart-step-3 section .content-area .method-holder .method-content .method-block .banks-holder {
    padding: 0px 10px 0px 0px;
    width: 100%;
  }
  body #cart .cart-template.cart-step-2 section .content-area .method-holder .method-content .method-block .banks-holder .bank-row img, body #cart .cart-template.cart-step-3 section .content-area .method-holder .method-content .method-block .banks-holder .bank-row img {
    width: 100px;
  }
  body #cart .cart-template.cart-step-2 section .content-area .method-holder .method-content .method-block .banks-holder .bank-row span, body #cart .cart-template.cart-step-3 section .content-area .method-holder .method-content .method-block .banks-holder .bank-row span {
    font-size: 14px;
  }
  body #cart .cart-template.cart-step-2 section:last-child, body #cart .cart-template.cart-step-3 section:last-child {
    overflow: hidden;
    top: calc(100% - 130px);
  }
  body #cart .cart-template.cart-step-2 section:last-child .buttons-holder, body #cart .cart-template.cart-step-3 section:last-child .buttons-holder {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  body #cart .cart-template.cart-step-2 section:last-child .buttons-holder .btn.link, body #cart .cart-template.cart-step-3 section:last-child .buttons-holder .btn.link {
    margin: 10px 0px 10px 0px;
    height: unset;
    width: 46%;
    display: flex;
    border: 1px solid #007FC7;
    height: 40px;
    text-decoration: none;
  }
  body #cart .cart-template.cart-step-2 section:last-child .buttons-holder .btn.link:last-of-type, body #cart .cart-template.cart-step-3 section:last-child .buttons-holder .btn.link:last-of-type {
    display: flex;
  }
  body #cart .cart-template.cart-step-4 section:last-of-type {
    display: none;
  }
  body #cart .cart-template.cart-step-4 section .content-header h1 {
    width: 100%;
  }
  body #cart .cart-template.cart-step-4 section .content-header .btn {
    display: none;
  }
  body #cart .cart-template.expanded .bg {
    visibility: visible;
    opacity: 1;
    content: " ";
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.75);
    cursor: pointer;
  }
  body #cart .cart-template.expanded section {
    padding-top: 20px;
  }
  body #cart .cart-template.expanded section:last-child {
    max-height: calc(100vh - 80px);
    z-index: 10;
    padding-top: 40px;
    flex-direction: column;
    bottom: 0px;
    top: unset;
    /*
    .cart-motivation{
        display: flex;
    }
    */
  }
  body #cart .cart-template.expanded section:last-child::after {
    width: 70px;
    height: 4px;
    border-radius: 2px;
    content: " ";
    background-color: #898989;
    top: 15px;
    position: absolute;
  }
  body #cart .cart-template.expanded section:last-child .cart-summary {
    flex-direction: row !important;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  body #cart .cart-template.expanded section:last-child .cart-summary article {
    width: 48%;
  }
  body #cart .cart-template.expanded section:last-child .cart-summary .btn {
    margin-bottom: 10px;
  }
  body #cart .cart-step-auth {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  body #cart .cart-step-auth .content {
    max-width: unset;
  }
  body #cart .cart-step-auth .content .header h1 {
    font-size: 24px;
  }
  body #cart .cart-step-auth .content .header .btn {
    width: 180px;
    font-size: 14px;
  }
  body #cart .cart-step-auth .content .login-holder {
    height: auto;
  }
  body #cart .cart-step-auth .content .login-holder #login {
    flex-direction: column;
    height: unset;
  }
  body #cart .cart-step-auth .content .login-holder #login .area-login {
    width: unset;
    margin-bottom: 40px;
  }
  body #cart .cart-step-auth .content .login-holder #login .area-register {
    width: unset;
  }
  body #cart .cart-step-auth .content .btn {
    margin-top: 0px;
  }
  body #cart .cart-empty {
    padding-bottom: 0px;
    height: auto !important;
  }
  body #cart .cart-empty .cart-empty-message .btn {
    margin-bottom: 20px;
  }
  body #cart .cart-empty .shuffled-products .slide-title {
    margin-bottom: 15px;
  }
  body #cart .cart-empty .shuffled-products .holder .slide-item {
    padding: 15px;
    max-width: unset !important;
    width: calc(50% - 30px) !important;
  }
}
@media screen and (max-width: 1080px) {
  body #register {
    padding-bottom: 60px;
  }
  body #register h1 {
    width: 100%;
    font-size: 24px;
    margin-bottom: 20px;
    margin-top: 40px;
  }
  body #register .content {
    max-width: unset;
    padding-left: 16px;
    padding-right: 16px;
    flex-direction: column;
  }
  body #register .content .column {
    width: unset;
  }
  body #register .content .column p {
    display: none;
  }
  body #register .content .column img {
    display: none;
  }
  body #register .content .column .form-area .custom-input {
    width: 100%;
  }
  body #register .content .column legend {
    text-align: center;
  }
  body #register .content .column:nth-of-type(2) h1 {
    display: none;
  }
}
@media screen and (max-width: 1080px) {
  body .register-success {
    padding-left: 16px;
    padding-right: 16px;
  }
  body .register-success .content {
    max-width: unset;
    flex-direction: column;
  }
  body .register-success .content .column {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  body .register-success .content .column p {
    width: unset;
  }
  body .register-success .content .column b {
    display: flex;
    flex-direction: row;
    width: 100%;
    font-weight: 400;
    margin-bottom: 10px;
  }
  body .register-success .content .column a {
    color: #1B97D5;
    text-decoration: none;
  }
  body .register-success .content .column img {
    display: flex;
    margin-top: 40px;
    width: 70%;
    align-self: flex-end;
  }
}
@media screen and (max-width: 1080px) {
  body .forgot-password {
    padding: unset;
    position: fixed;
    left: 0px;
    right: 0px;
    border-radius: unset;
    width: auto;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    box-shadow: 0px -4px 6px rgba(80, 80, 80, 0.24);
    align-items: center;
    background-color: #FFF;
    flex-direction: column;
    padding-top: 80px;
    transition: all 0.15s;
    background-color: #fff;
    align-items: unset;
    overflow: hidden;
    overflow-y: auto;
    bottom: -100%;
    z-index: 1;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 40px;
    padding-top: 40px;
  }
  body .forgot-password::after {
    content: " ";
    height: 4px;
    width: 32px;
    border-radius: 4px;
    background-color: #C4C4C4;
    position: absolute;
    top: 20px;
    left: 50%;
    margin-left: -16px;
  }
  body .forgot-password h2 {
    text-align: center;
  }
  body .forgot-password.slide {
    bottom: 0px;
  }
}
@media screen and (max-width: 1080px) {
  body .verify-account .content {
    max-width: unset;
    padding-left: 16px;
    padding-right: 16px;
  }
  body .verify-account .content #loading {
    justify-content: flex-start;
    width: 32px;
    flex: unset;
  }
  body .verify-account .content h1 {
    justify-content: center;
    font-size: 20px;
  }
  body .verify-account .content p {
    text-align: center;
    font-size: 14px;
  }
}
@media screen and (max-width: 1080px) {
  body .guest-user {
    padding: unset;
    position: fixed;
    left: 0px;
    right: 0px;
    border-radius: unset;
    width: auto;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    box-shadow: 0px -4px 6px rgba(80, 80, 80, 0.24);
    align-items: center;
    background-color: #FFF;
    flex-direction: column;
    padding-top: 80px;
    transition: all 0.15s;
    background-color: #fff;
    align-items: unset;
    overflow: hidden;
    overflow-y: auto;
    bottom: -100%;
    z-index: 1;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 40px;
    padding-top: 60px;
  }
  body .guest-user::after {
    content: " ";
    height: 4px;
    width: 32px;
    border-radius: 4px;
    background-color: #C4C4C4;
    position: absolute;
    top: 20px;
    left: 50%;
    margin-left: -16px;
  }
  body .guest-user.slide {
    bottom: 0px;
  }
  body .guest-user h2 {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 20px;
  }
  body .guest-user p {
    font-weight: 200;
    font-size: 14px;
    margin-bottom: 40px;
  }
  body .guest-user .custom-input .field-wrapper {
    border-radius: 20px;
    background-color: #F2F2F2;
    border-color: #C4C4C4;
  }
  body .guest-user .btn-holder {
    margin-top: 30px;
  }
  body .guest-user .btn-holder .btn {
    height: 50px;
  }
}
@media screen and (max-width: 1080px) {
  body .order-box .collapser-header .content .table-row {
    flex-wrap: wrap;
  }
  body .order-box .collapser-header .content .table-row .table-col {
    width: 40%;
    margin-bottom: 15px;
  }
  body .order-box .collapser-header .content .table-row .table-col label {
    font-size: 12px;
  }
  body .order-box .collapser-header .content .table-row .table-col span {
    font-size: 14px;
  }
  body .order-box .collapser-header .content .table-row .table-col:first-of-type {
    display: flex;
  }
  body .order-box .collapser-header .content .table-row .table-col:nth-of-type(5) {
    margin-bottom: 0px;
  }
  body .order-box .order-detail .order-detail-title {
    margin: 20px 0px 20px 0px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  body .order-box .order-detail .order-detail-title span {
    font-size: 12px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0px;
  }
  body .order-box .order-detail .order-detail-title span:nth-of-type(1) {
    margin-bottom: 15px;
    margin-top: 20px;
    margin-left: 0px;
  }
  body .order-box .order-detail .order-detail-title span:nth-of-type(1) svg {
    margin-right: 5px;
  }
  body .order-box .order-detail .order-detail-title span:nth-of-type(2), body .order-box .order-detail .order-detail-title span:nth-of-type(3) {
    margin: 0px;
    width: 50%;
  }
  body .order-box .order-detail .order-detail-title span:nth-of-type(3) {
    justify-content: flex-end;
  }
  body .order-box .order-detail .order-row {
    flex-wrap: wrap;
  }
  body .order-box .order-detail .order-row .img-holder {
    width: 70px !important;
    height: 70px !important;
    margin-right: 0px;
    align-self: flex-start;
  }
  body .order-box .order-detail .order-row .sections {
    width: calc(100% - 80px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  body .order-box .order-detail .order-row .sections section {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  body .order-box .order-detail .order-row .sections section label {
    font-size: 11px;
    margin-bottom: 0px;
  }
  body .order-box .order-detail .order-row .sections section span {
    font-size: 10px;
    margin-left: 4px;
    font-weight: 500;
  }
  body .order-box .order-detail .order-row .sections section:nth-of-type(1) {
    width: 100%;
  }
  body .order-box .order-detail .order-row .sections section:nth-of-type(1) label, body .order-box .order-detail .order-row .sections section:nth-of-type(1) span {
    width: 100%;
    margin-left: 0px;
    height: 12px;
    overflow: hidden;
  }
  body .order-box .order-detail .order-row .sections section:nth-of-type(1) label {
    font-size: 11px;
  }
  body .order-box .order-detail .order-row .sections section:nth-of-type(1) span {
    font-size: 11px;
    font-weight: 300;
  }
  body .order-box .order-detail .order-row .sections section:nth-of-type(2) {
    display: none;
  }
  body .order-box .order-detail .order-row .sections section:nth-of-type(3), body .order-box .order-detail .order-row .sections section:nth-of-type(4), body .order-box .order-detail .order-row .sections section:nth-of-type(5), body .order-box .order-detail .order-row .sections section:nth-of-type(6) {
    width: 55%;
  }
  body .order-box .order-detail .order-row .sections section:nth-of-type(3), body .order-box .order-detail .order-row .sections section:nth-of-type(5) {
    width: 44%;
  }
  body .order-box .order-detail .order-row .sections section:nth-of-type(2), body .order-box .order-detail .order-row .sections section:nth-of-type(4), body .order-box .order-detail .order-row .sections section:nth-of-type(6) {
    justify-content: flex-end;
  }
}
@media screen and (max-width: 1080px) {
  body footer .footer-perks {
    flex-direction: column;
    padding-left: 16px;
    padding-right: 16px;
    gap: 5px;
    padding-top: 35px;
    padding-bottom: 35px;
    background-color: #F9FCFE;
  }
  body footer .footer-perks .title {
    display: flex;
    width: 100%;
    font-size: 22px;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 10px;
  }
  body footer .footer-perks section {
    width: 100%;
  }
  body footer .footer-perks section::after {
    display: none;
  }
  body footer .footer-perks section h2 {
    font-size: 14px;
    color: #1289C5;
    margin-bottom: 10px;
  }
  body footer .footer-perks section h2 svg {
    margin-right: 5px;
    width: 18px !important;
  }
  body footer .footer-perks section p {
    display: none;
  }
  body footer .footer-perks section small, body footer .footer-perks section .btn {
    font-size: 12px;
    padding-left: 25px;
    margin-bottom: 10px;
    margin-top: -10px;
  }
  body footer .footer-sitemap {
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    background-color: #1289C5;
    position: relative;
  }
  body footer .footer-sitemap nav {
    background: #1289C5;
    color: #FFF;
    padding-left: 16px;
    padding-right: 16px;
  }
  body footer .footer-sitemap nav h2 {
    font-size: 16px;
    margin: 0px;
    height: 48px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    justify-content: space-between;
  }
  body footer .footer-sitemap nav h2::after {
    content: " ";
    position: absolute;
    height: 1px;
    background-color: #FFFFFF50;
    width: 100%;
    bottom: 0px;
  }
  body footer .footer-sitemap nav h2:last-child::after {
    display: none;
  }
  body footer .footer-sitemap nav section {
    display: none;
    transition: all 0.35s;
  }
  body footer .footer-sitemap nav section.expanded {
    display: flex;
  }
  body footer .footer-sitemap nav section a {
    font-size: 12px;
    color: #FFF;
    display: flex;
    height: 30px;
    margin-bottom: 0px;
    align-items: center;
    text-decoration: none;
  }
  body footer .footer-sitemap nav:first-child h2 {
    display: flex;
  }
  body footer .footer-sitemap nav:first-child section img {
    display: none;
  }
  body footer .footer-copyright {
    background-color: #003D5C;
    font-size: 12px;
  }
  body footer .contact-area h3 {
    color: #FFF;
    text-align: center;
    margin-bottom: 10px;
  }
  body footer .contact-area h3::after {
    display: none;
  }
  body footer .contact-area .phone {
    margin-bottom: 10px;
    color: #fff;
    text-align: center;
    font-size: 14px;
    margin-top: 10px;
  }
  body footer .contact-area .social-links {
    justify-content: center;
    margin-bottom: 30px;
  }
  body footer .contact-area .social-links a.social-link {
    margin-bottom: 0px;
    display: flex;
  }
  body footer .contact-area .social-links a.social-link svg {
    color: #FFF !important;
  }
  body footer .promo-applier {
    border-radius: 24px;
    max-width: unset;
    width: auto;
    align-self: center;
    margin-left: 20px;
    margin-bottom: 20px;
    width: calc(100% - 100px);
  }
}


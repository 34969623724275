// SCROLLBARS
::-webkit-scrollbar {
    width: 6px;               /* width of the entire scrollbar */
}
::-webkit-scrollbar-track {
    background: transparent;        /* color of the tracking area */
}
::-webkit-scrollbar-thumb {
    background-color: #16a2e8;    /* color of the scroll thumb */
    border-radius: 2px;       /* roundness of the scroll thumb */
}

// PLACE HOLDER COLOR:
::placeholder{
    color: #EDEDED;
    font-weight: 300;
}

// DISABLE WEIRD BLUE HIGHLIGHT ON MOBILE TAP
*{
    -webkit-tap-highlight-color: transparent;
}

// VARS:
$header-height: 110px;
$header-gray-bay: 34px;
$cart-side-width: 380px;


// LOADING DASH SPINNER
.dash-spinner-32x32 { animation: dash-spinner-32x32 0.2s steps(3) infinite; display: block; width: 32px; height: 32px; background-image:url('@Assets/loading.png'); }
@keyframes dash-spinner-32x32 { from { background-position: 0px 0px; } to { background-position: 0px -96px; } }

// SIDE PADDINGS
@mixin side-paddings{
    padding-left: 32px;
    padding-right: 32px;
}

@mixin content-width{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: calc(100vw - 200px); //1280px;
    align-self: center;
}

@mixin side-panel-paddings {
    padding-left: 22px;
    padding-right: 22px;
}


@mixin slide-bottom-panel{
    padding: unset;
    position: fixed;
    left: 0px;
    right: 0px;
    border-radius: unset;
    width: auto;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    box-shadow: 0px -4px 6px rgb(80 80 80 / 24%);
    align-items: center;
    background-color: #FFF;
    flex-direction: column;
    padding-top: 80px;
    transition: all 0.15s;
    background-color: #fff;
    align-items: unset;
    overflow: hidden;
    overflow-y: auto;
    bottom: -100%;
    z-index: 1;
    &::after{
        content: ' ';
        height: 4px;
        width: 32px;
        border-radius: 4px;
        background-color: #C4C4C4;
        position: absolute;
        top: 20px;
        left: 50%;
        margin-left: -16px;
    }
}


// Auth Initials
@mixin auth-initials{
    width: 32px;
    height: 32px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-radius: 100%;
    background-color: #EB6B2F;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    //position: absolute;
    //left: -10px;
}


/* Initial styles: you can get rid of these! */
body{
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #505050;
    font-size: 16px;
    overflow: hidden;

    @media (display-mode: browser) {
        .pwa-isntall-btn{
            display: flex !important;
        }
    }

    // Inside the body, the scroll width can be thinner:
    ::-webkit-scrollbar {
        width: 2px;               /* width of the entire scrollbar */
    }

    @keyframes bouncePress{
        0% {
          transform: scale(1);
          opacity: 1;
        }
        50% {
          transform: scale(0.92);
          opacity: 0.5;
        }
        to {
          transform: scale(1);
          opacity: 1;
        }
    }
    a, button, image, .btn, i, h2, .category-item{
        position: relative;
        &:active{
            animation: bouncePress 0.2s 1 linear !important;
        }
    }




    // CTA WhatsApp
    #cta-whatsapp{
        width: 48px;
        height: 48px;
        background-color: #25d366;
        box-shadow: 1px 6px 24px 0 rgba(0, 0, 0, 0.75);
        border-radius: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        position: sticky;
        bottom: 10px;
        z-index: 1;
        left: 10px;
        @media only screen and (hover: none) and (pointer: coarse){
            //bottom: 120px;
        }
    }

    // Message to look at in fixed position
    .fixed{
        position: fixed;
        bottom: 30px;
        right: 0px;
        background-color: #000;
        color: #FFF;
        text-align: center;
        font-size: 12px;
        padding: 5px 10px;
        z-index: 9999;
    }

    // Buttons
    .btn{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 34px;
        width: 100%;
        border-radius: 29px;
        background-color: #EDEDED;
        font-size: 16px;
        &.primary{ // Orange
            background-color: #EB6B2F;
            color: #FFF;
        }
        &.secondary{ // Blue
            background-color: #1289C5;
            color: #FFF;
        }
        &.tertiary{
            background-color: #fff;
            border: 1px solid #1289C5;
            color: #1289C5;
        }
        &.link{
            background-color: transparent;
            color: #1289C5;
            text-decoration: underline;
            margin-top: 10px;
        }
    }

    // Toastify
    .Toastify{
        .Toastify__toast{
            padding: 4px;
        }
        .Toastify__toast-body{
            font-size: 12px;
            padding: 0px 4px;
        }
        .Toastify__progress-bar{
            height: 3px;
        }
    }

    // CLOSER STORE WARNING
    #closer-store-warning{
        background: #FFF1EB;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #f2f2f2;
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: -60px;
        color: #6C2300;
        font-size: 14px;
        width: auto;
        b{
            display: contents;
            font-weight: 500;
            padding: 5px 5px;
            cursor: pointer;
            &:hover{
                background-color: #FFF;
                border-radius: 4px;
            }
        }
        button{
            position: absolute;
            right: 40px;
        }
    }

    // LOADING
    #loading{
        display: flex;
        flex: 1;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        &.fullsize{
            position: fixed;
            top:0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            opacity: unset;
            background-color: rgba(0,0,0, 0.75);
            z-index: 9999;
        }
    }

    // MODAL
    #modal{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 11;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background-color: rgba(0,0,0,0.75);
        cursor: pointer;
        .close-modal{
            color: #fff !important;
            width: 25px;
            height: 25px;
            position: absolute;
            top: 10px;
            right: 10px;
        }
        .modal-confirm{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 50px 20px;
            background-color: #FFF;
            width: 300px;
            min-height: 100px;
            border-radius: 16px;
            h2{
                display: flex;
                font-size: 18px;
                text-align: center;
                margin-bottom: 32px;
            }
            h3{
                display: flex;
                font-size: 14px;
                text-align: center;
                font-weight: 400;
                color: #000;
                margin-bottom: 30px;
            }
            .buttons-holder{
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: center;
                .btn{
                    width: 50%;
                    margin-bottom: 10px;
                    &:last-child{
                        margin: 0px;
                        height: unset;
                    }
                }
            }
        }
        .modal-location{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 30px 20px;
            background-color: #FFF;
            width: 300px;
            min-height: 100px;
            border-radius: 16px;
            svg{
                margin-bottom: 20px;
            }
            h2{
                display: flex;
                font-size: 18px;
                text-align: center;
                margin-bottom: 32px;
            }
            .btn{
                margin-bottom: 15px;
                height: 58px;
                width: 80%;
                &:last-child{
                    margin-bottom: 0px;
                }
            }
            small{
                font-size: 12px;
                margin-top: 20px;
                b{
                    font-weight: 500;
                }
            }
        }
    }

    // EMPTY
    #empty{
        display: block;
        background-color: #FFF;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        border-radius: 10px;
        align-items: center;
        padding: 40px 0px;
        height: auto;
        h1{
            color: #00000035;
            height: 34px;
            line-height: 34px;
            display: block;
        }
        img{
            height: 200px;
        }
    }

    // BOX
    .box{
        background: #FFFFFF;
        box-shadow: 0px 0px 12px rgba(0, 69, 104, 0.25);
        border-radius: 8px;
        overflow: hidden;
    }

    // FLYOUT
    #flyout{
        display: flex;
        position: fixed;
        padding-top: $header-height();
        cursor: pointer;
        width: 100%;
        height: 100vh;
        &::after{
            content: ' ';
            position: absolute;
            bottom: 0px;
            left: 0;
            right: 0;
            width: 100%;
            height: 50%;
            background-color: rgba(0,0,0,0.75);
        }
        .content{
            position: relative;
            background-color: #ffff;
            width: 100%;
            height: 520px;
            cursor: auto;
            @include side-paddings();
            z-index: 1;
            box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
            padding-top: 40px;
        }
    }
    // Flyouts Content
    .flyout-categories{
        display: flex;
        flex-direction: column;
        h2{
            font-weight: 500;
            font-size: 28px;
            margin-bottom: 35px;
            svg{
                display: none;
            }
        }
        .categories-and-promos{
            display: flex;
            flex-direction: row;
            .holder-categories{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                //width: calc(100% - 400px); Descomentar cuando volvamos a tener slide de promos
                p{
                    display: flex;
                    font-size: 16px;
                    line-height: 20px;
                    padding-right: 32%;
                    margin-bottom: 60px; // Comentar cuando volvamos a tener slide de promos
                }
                .item{
                    display: flex;
                    flex-direction: row;
                    width: 280px;
                    // margin-bottom: 20px; // Descomentar cuando volvamos a tener slide de promos
                    margin-bottom: 60px;
                    align-items: center;
                    cursor: pointer;
                    .img-holder{
                        display: flex;
                        width: 70px;
                        height: 70px;
                        border-radius: 100%;
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position:  center center;
                        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
                    }
                    label{
                        display: flex;
                        margin-left: 15px;
                        font-size: 21px;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .flyout-collections{
        display: flex;
        flex-direction: column;
        h2{
            font-weight: 500;
            font-size: 28px;
            margin-bottom: 10px;
            svg{
                display: none;
            }
        }
        p{
            display: flex;
            font-size: 18px;
            line-height: 24px;
            padding-right: 32%;
        }
        .items{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
            gap: 40px;
            button{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 150px;
                height: 150px;
                background-repeat: no-repeat;
                background-size: cover;
                position: relative;
                border-radius: 10px;
                overflow: hidden;
                &::before{
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: rgba(0, 20, 39, 0.55);
                    content: ' ';
                }
                span{
                    color: #FFFFFF;
                    position: relative;
                    font-size: 20px;
                    font-weight: 500;
                }
            }
        }
    }
    .flyout-search{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        .content-area{
            @include content-width();
            display: flex;
            flex-direction: column;
            flex: 1;
            width: 710px;
            margin-bottom: 20px;
            justify-content: flex-start;
            .materials-and-colors{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .search-materials{
                    width: 60%;
                }
                .search-colors{
                    width: 30%;
                }
            }
            .buttons-holder{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 220px;
                align-self: center;
                margin-top: 10px;
                .primary{
                    height: 50px;
                }
            }
        }
    }

    // PROMOS
    #promos-swiper{
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 440px;
        .controls{
            display: flex;
            padding: 0px;
            margin: 0px;
        }
        .slider{
            padding: 0px 20px;
            .slide-item{
                padding-bottom: 10px;
                .card{
                    display: flex;
                    flex-direction: column;
                    background: linear-gradient(180deg, #000000 24.23%, #303030 77.85%);
                    border-radius: 8px;
                    padding: 40px 32px;
                    color: #FFFFFF;
                    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
                    .img-holder{
                        height: 126px;
                        width: 100%;
                        background-image: url('@Assets/promo-thumb.png');
                        background-size: contain;
                        background-repeat: no-repeat;
                        background-position: center center;
                        margin-bottom: 40px;
                    }
                    .texts-holder{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        flex-wrap: wrap;
                        h2{
                            font-size: 16px;
                            font-weight: 300;
                            margin: 0px;
                            margin-bottom: 10px;
                            width: 100%;;
                        }
                        big{
                            display: flex;
                            font-size: 64px;
                            font-weight: 700;
                            width: 45%;
                        }
                        span{
                            display: flex;
                            font-size: 16px;
                            font-weight: 700;
                            margin-left: 10px;
                            width: 50%;
                        }
                    }
                    button{
                        display: flex;
                        flex-direction: row;
                        justify-self: center;
                        align-items: center;
                        justify-content: center;
                        align-self: center;
                        height: 58px;
                        background-color: #fff;
                        border-radius: 29px;
                        text-align: center;
                        color: #000;
                        margin-top: 30px;
                        padding: 0px 24px;
                    }
                }
            }
            .swiper-pagination{
                .swiper-pagination-bullet{
                    background-color: #CCCCCC;
                    width: 40px;
                    border-radius: unset;
                    height: 4px;
                    &.swiper-pagination-bullet-active{
                        background-color: #0686C9;
                    }
                }
            }
        }
        // COMPACT VERSION
        &.compact{
            width: 100%;
            display: flex;
            justify-content: center;
            margin-top: 80px;
            .slider{
                width: 410px;
                .slide-item{
                    .card{
                        height: 88px;
                        flex-direction: row-reverse;
                        flex-wrap: wrap;
                        padding: 0px;
                        align-items: center;
                        .img-holder{
                            width: 140px;
                            height: 80px;
                            margin: 0px;
                        }
                        .texts-holder{
                            margin: 0px;
                            width: calc(100% - 150px);
                            h2{
                                font-size: 12px;
                            }
                            big{
                                font-size: 35px;
                                width: 35%;
                            }
                            span{
                                font-size: 12px;
                                font-weight: 300;
                                width: 58%;
                            }
                        }
                        button{
                            display: none;
                        }
                    }
                }
            }
        }
    }

    // STORES SELECTOR
    #stores{
        background: #FFFFFF;
        box-shadow: 0px 8px 16px rgba(0, 69, 104, 0.1);
        border-radius: 16px;
        display: flex;
        width: 400px;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 18px;
        align-self: center;
        align-items: center;
        position: relative;
        .btn-radio{
            position: absolute;
            right: 15px;
        }
        p{
            margin: 16px 0px;
            font-size: 13px;
        }
        h2{
            font-weight: 400;
            font-size: 19px;
            margin-bottom: 16px;
        }
        .btn{
            font-size: 13px;
            margin-bottom: 12px;
            width: 65%;
            &.default{
                background-color: #F5F9FA;
            }
        }
    }

    // SIDE PANEL
    @keyframes slideFromRight {
        0% {
          transform: translateX(200%);
        }
        100% {
          transform: translateX(0);
        }
      }
    #side-panel{
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(#000000, 0.75);
        width: 100%;
        height: 100vh;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        cursor: pointer;
        .side-content{
            animation: 0.15s ease-in-out 0s 1 slideFromRight;
            background-color: #fff;
            width: 445px;
            box-shadow: -8px 8px 8px rgba(0, 0, 0, 0.25);
            cursor: auto;
            display: flex;
            flex-direction: column;
            flex: 1;
            overflow-y: auto;
            overflow-x: hidden;
            .close-btn{
                display: none;
            }
        }
        .button-helper{
            animation: 0.15s ease-in-out 0s 1 slideFromRight;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: #FFF;
            height: $header-gray-bay;
            width: 160px;
            span{
                display: flex;
                margin-left: 10px;
            }
        }
    }

    // BUTTON TYPE RADIO
    .btn-radio{
        display: flex;
        border-radius: 100%;
        width: 18px;
        height: 18px;
        align-items: center;
        justify-content: center;
        border: 1px solid #898989;
        margin: 0px;
        padding: 0px;
        &.selected{
            border-color: #007FC7;
            &::after{
                content: ' ';
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background-color: #007FC7;
            }
        }
    }

    // TABLE DISPLAY
    .table-row{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: 1;
        margin-top: -45px;
        padding-left: 140px;
        .table-col{
            display: flex;
            flex-direction: column;
            padding-right: 10px;
            label{
                font-size: 14px;
                margin-bottom: 7px;
            }
            span{
                font-size: 16px;
                font-weight: 400;
            }
            &:first-child{
                span{
                    color: #34C240;
                }
            }
            &:last-child{
                span{
                    color: #EB6B2F;
                }
            }
        }
    }

    // COLLAPSER
    .custom-collpaser{
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        .collapser-header{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 0px;
            cursor: pointer;
            .content{
                display: flex;
                width: 95%;
                .table-row{
                    margin: 0px;
                }
            }
            button{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-end;
                transition: all 0.35s;
                margin: 0px;
                padding: 0px;
                margin-right: -5px;
            }
        }
        .collapser-content{
            height: 0px;
            transition: all 0.15s;
            overflow: hidden;
            &.open{
                height: unset;
            }
        }
    }

    // OVERLAY LAYER
    .overlay-layer{
        cursor: pointer;
        background-color: rgba(#000000, 0.85);
        position: fixed;
        top:0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    // INPUTS & SELECTS & TEXTAREAS
    .custom-input, .custom-textarea, .custom-file{
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-bottom: 21px;
        label{
            color: '#202020';
            margin-bottom: 8px;
            font-size: 14px;
        }
        .field-wrapper{
            display: flex;
            flex-direction: row;
            border: 1px solid #CDEDFF;
            background-color: #FFF;
            height: 40px;
            border-radius: 8px;
            align-items: center;
            padding: 0px 21px;
            overflow: hidden;
            input, select, textarea{
                width: 100%;
                display: flex;
                flex: 1;
                background-color: transparent;
                border: none;
                color: #202020;
            }
            select{
                width: 140% !important;
            }
            svg{
                cursor: pointer;
            }
        }
        &.error{
            .field-wrapper{
                border: 2px solid #CF0043;
                background-color: #FFF8F9;
            }
            input{
                color: #CF0043;
            }
        }
        &.success{
            .field-wrapper{
                border: 2px solid #16AC69;
                background-color: #FFF8F9;
            }
            input{
                color: #2B8519;
            }
        }
    }
    .custom-textarea{
        .field-wrapper{
            height: 100px;
            textarea{
                height: 80%;
                resize: none;
                padding: 0px;
                line-height: 18px;
            }
        }
    }

    // CHECKBOX
    .custom-checkbox{
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        margin-bottom: 21px;
        cursor: pointer;
        .chkbox{
            display: flex;
            width: 14px;
            height: 14px;
            border: 1px solid #898989;
            border-radius: 4px;
            margin-right: 11px;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
        span{
            color: #505050;
        }
        &.checked{
            .chkbox{
                background-color: #007FC7;
                border: 1px solid #007FC7;
            }
            span{
                color: #007FC7;
            }
        }
    }
    // RADIO
    .custom-radio{
        display: flex;
        width: 100%;
        flex-direction: row;
        align-items: center;
        margin-bottom: 21px;
        cursor: pointer;
        .radio{
            display: flex;
            width: 18px;
            height: 18px;
            border: 1px solid #898989;
            margin-right: 11px;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border-radius: 100%;
        }
        span{
            color: #505050;
        }
        input[type="radio"]{
            display: none;
        }
        &.checked{
            .radio{
                border: 1px solid #007FC7;
                span{
                    color: #007FC7;
                }
                &::after{
                    content: ' ';
                    width: 14px;
                    height: 14px;
                    background-color: #007FC7;
                    border-radius: 100%;
                }
            }
        }
    }

    // LINE-X Switch
    .linex-switch{
        border: 1px solid #898989;
        display: inline-flex;
        border-radius: 29px !important;
        margin: 0px !important;
    }

    // MAIN
    main{
        max-height: calc(100vh - 0px);
        overflow: auto;
        overflow-x: hidden;
    }

    // HEADER
    header{
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        transition: top 0.5s ease-in-out;
        height: $header-height;
        z-index: 2;
        border-bottom: 1px solid #00000010;
        background-color: #FFF;
        &.hidden{
            top: -#{$header-height};
        }
        .gray-bar{
            @include side-paddings();
            background-color: #F9FCFE;
            display: flex;
            flex-direction: row;
            height: $header-gray-bay;
            align-items: stretch;
            justify-content: space-between;
            padding-top: 8px;
            padding-bottom: 8px;
            span{
                display: flex;
                font-size: 16px;
                align-items: center;
            }
            .global-back{
                display: flex;
                align-items: center;
                padding: 0px;
                margin: 0px;
                margin-right: 20px;
                svg{
                    width: calc(#{$header-gray-bay} - 17px) !important;
                    height: calc(#{$header-gray-bay} - 17px) !important;
                    box-shadow: 0px 0px 12px rgba(0, 69, 104, 0.25);
                    border-radius: 100%;
                    padding: 5px;
                    color: #1289C5;
                }
            }
            .buttons-holder{
                display: flex;
                flex-direction: row;
                align-items: stretch;
                justify-content: flex-end;
                flex-basis: 60%;
                button{
                    background-color: transparent;
                    border: none;
                    padding: 0px;
                    margin: 0px;
                    font-size: 16px;
                    display: flex;
                    margin-left: 30px;
                    display: inline-flex;
                    flex-direction: row;
                    align-items: center;
                    &.logged-in{
                        display: flex;
                        flex-direction: row;
                        background-color: #EDEDED;
                        padding: 0px 20px 0px 20px;
                        border-radius: 20px;
                        position: relative;
                        .auth-initials{
                            @include auth-initials();
                            position: absolute;
                            left: -10px;
                        }
                    }
                    span{
                        display: inline-block;
                        margin-left: 10px;
                    }
                }
                label{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-left: 30px;
                    cursor: pointer;
                    span{
                        display: inline-block;
                        margin-left: 10px;
                    }
                }
            }
        }
        nav{
            @include side-paddings();
            display: flex;
            flex: 1;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background-color: #FFF;
            padding-top: 5px;
            padding-bottom: 5px;
            .logo-holder{
                display: inline-flex;
                width: 243px;
                height: 45px;
                background-image: url('@Assets/logo.png');
            }
            section{
                display: flex;
                flex-direction: row;
                align-items: center;
                a{
                    font-size: 16px;
                    margin-left: 30px;
                    cursor: pointer;
                    text-decoration: none;
                    color: #505050;
                    padding: 4px 4px;
                    &.in-outlet{
                        color: #FF7433;
                        background-color: #F2F2F2;
                        border-radius: 4px;
                    }
                }
                button{
                    display: inline-flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    margin-left: 20px;
                    &:first-of-type{
                        margin-left: 70px;
                    }
                    &.cart-icon{
                        position: relative;
                        span{
                            width: 25px;
                            height: 25px;
                            background-color: #EB6B2F;
                            color: #FFF;
                            text-align: center;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            border-radius: 100%;
                            font-size: 11px;
                            position: absolute;
                            bottom: -5px;
                            left: -5px;
                        }
                    }
                    .auth-initials{
                        @include auth-initials();
                    }
                    /* Hides icons that appears only in mobile */
                    &:nth-last-child(1),
                    &:nth-last-child(2),
                    &:nth-last-child(4){
                        display: none;
                    }
                }
            }
        }
    }

    // PRODUCT COMPONENTS
    .product-gallery{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        overflow: hidden;
        img{
            height: 200px;
        }
    }
    .product-discount{
        background-color: #EB6B2F;
        color: #FFF;
        height: 24px;
        width: 48px;
        text-align: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }
    .product-variants{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 20px;
        padding-bottom: 20px;
        border-bottom: 2px solid #F2F2F2;
        width: 100%;
        justify-content: flex-start;
        label{
            margin-right: 20px;
        }
        .variants{
            display: flex;
            flex-direction: row;
            padding: 10px 10px;
            z-index: 0;
            .swiper-slide{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 22px;
                height: 22px;
                line-height: unset;
                position: relative;
                padding: 0px;
                border-radius: 100%;
                cursor: pointer;
                //box-shadow: 0px 2px 4px rgba(0, 69, 104, 0.25);
                border: 1px solid #F2F2F2;
                font-size: 12px;
                &.selected{
                    color: #EB6B2F;
                    font-weight: 500;
                    &::before{
                        position: absolute;
                        content: ' ';
                        border: 2px solid #1289C5;
                        width: 30px;
                        height: 30px;
                        border-radius: 100%;
                        top: -6px;
                        left: -6px;
                    }
                }
            }
        }
    }
    .product-prices{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 32px;
        big{
            font-size: 32px;
            font-weight: 700;
            width: 70%;
            display: flex;
            flex-direction: row;
        }
        small{
            font-size: 18px;
            text-decoration: line-through;
            width: 100%;
            margin-top: 16px;
        }
        .product-discount{
            display: none;
        }
    }
    .product-hearth{
        display: inline-flex;
        width: 10%;
        justify-content: flex-end;
        svg{
            cursor: pointer;
        }
    }
    .product-texts{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        span{
            color: #EB6B2F;
            font-size: 14px;
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
        }
        h2{
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;
            margin-bottom: 16px;
            display: flex;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            height: 35px;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        h3{
            font-size: 16px;
            display: flex;
            width: 100%;
            margin-bottom: 14px;
        }
        a{
            color: #1289C5;
            font-size: 14px;
            display: none;
        }
        .product-hearth{
            display: none;
        }
    }
    .product-increaser{
        display: flex;
        flex-direction: column;
        label{
            font-size: 14px;
            display: none;
            margin-bottom: 10px;
        }
        .container{
            display: flex;
            flex-direction: row;
            border: 1px solid #C4C4C4;
            border-radius: 400px;
            height: 58px;
            align-items: center;
            justify-content: space-between;
            padding: 0px 12px;
            cursor: auto;
            button{
                margin: 0px;
                padding: 0px;
                width: auto;
                display: flex;
                flex-direction: row;
                align-items: center;
                svg{
                    margin: 0px;
                    padding: 0px;
                }
            }
            span{
                font-weight: 500;
                font-size: 18px;
            }
        }
    }
    .product-buttons{
        button{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            height: 64px;
            border-radius: 64px;
            padding: 0px 22px;
            font-size: 18px;
            small{
                font-size: 16px;
                font-weight: 200;
            }
        }
        a{
            color: #1289C5;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-top: 20px;
        }
        legend{
            display: none;
            flex-direction: row;
            justify-content: center;
            font-weight: 300;
            margin-top: 10px;
        }
    }
    .product-popup{
        display: flex;
        flex-direction: row;
        align-items: center;
        .gallery{
            display: flex;
            background-color: #F9FCFE;
            box-shadow: 0px 0px 12px rgba(0, 69, 104, 0.25);
            border-top-left-radius: 12px;
            border-bottom-left-radius: 12px;
            width: 440px;
            height: 350px;
            align-items: center;
            .product-gallery{
                display: flex;
                flex-direction: column;
                height: 95%;
                align-items: center;
                justify-content: center;
                img{
                    height: 100%;
                }
            }
        }
        .info{
            display: flex;
            flex-direction: column;
            background-color: #FFF;
            box-shadow: 0px 0px 12px rgba(0, 69, 104, 0.25);
            border-radius: 12px;
            width: calc(410px - 48px);
            padding: 30px 24px;
            min-height: 350px;
            .product-texts{
                border-bottom: 1px solid #F2F2F2;
                padding-bottom: 15px;
                span{
                    margin-bottom: 25px;
                    display: inline-flex;
                }
                h2{
                    margin-bottom: 10px;
                }
                h3{
                    margin-bottom: 10px;
                }
                .product-hearth{
                    display: flex;
                }
                a{
                    display: flex;
                }
            }
            .product-prices{
                border-bottom: 1px solid #F2F2F2;
                padding: 15px 0px;
                margin: 0px;
                small{
                    width: unset;
                }
                .product-hearth{
                    display: none;
                }
                .product-discount{
                    display: flex;
                }
            }
            .product-variants{
                margin: 0px;
                padding: 15px 0px;
                border: none;
            }
            .product-increaser{
                margin: 23px 0px;
            }
        }
    }
    .product-stars{
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        margin-bottom: 15px;
        svg{
            margin-right: 6px;
            cursor: pointer;
            &:hover{
                color: #F6C000 !important;
                & ~ svg{
                    color: #F6C000 !important;
                }
            }
        }
    }
    .product-item{
        display: flex;
        flex-direction: column;
        background-color: #FFF;
        //width: calc(270px - 36px);
        grid-column: span 1;
        box-shadow: 0px 4px 12px rgba(0, 69, 104, 0.25);
        border-radius: 8px;
        //margin-bottom: 24px;
        padding: 18px;
        align-items: center;
        text-align: left;
        position: relative;
        justify-content: space-between;
        .product-discount{
            position: absolute;
            top: 18px;
            right: 0px;
        }
        .product-increaser-holder{
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-radius: 20px;
            background-color: #fff;
            border: 1px solid #1289c5;
            color: #1289c5;
            height: 34px;
            justify-content: center;
            padding: 0px 15px;
            max-width: calc(100% - 20px);
            .product-increaser{
                display: none;
                & + .product-buttons{
                    button{
                        height: unset;
                        background-color: transparent;
                        color: #007FC7;
                        small{
                            display: none;
                        }
                        span{
                            font-size: 14px;
                        }
                    }
                    a{
                        display: none;
                    }
                }
            }
            &.in-cart{
                background-color: #1289C5;
                justify-content: space-between;
                #loading{
                    display: none;
                }
                .product-increaser{
                    display: flex;
                    width: 100%;
                    .container{
                        height: unset;
                        border: none;
                        padding: 0px;
                        border-radius: 0px;
                        svg{
                            width: 18px !important;
                            height: 18px !important;
                            color: #fff !important;
                            border-radius: 100%;
                            border: 1px solid #FFFFFF;
                        }
                        span{
                            font-size: 18px;
                            color: #FFF;
                            padding: 0px 2px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            padding: 0px 4px;
                        }
                    }
                    & + .product-buttons{
                        display: none;
                    }
                }
            }
        }
    }

    // HOME
    #home{
        padding-top: $header-height;
        position: relative;
        .home-ribbon{
            background-color: #FF7433;
            color: #FFF;
            text-align: center;
            height: 30px;
            display: none;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            font-size: 14px;
        }
        .home-banners{
            display: flex;
            cursor: pointer;
            .banner-item{
                height: 450px;
                display: flex;
                flex-direction: row;
                align-items: center;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;
            }
        }
        .home-categories{
            padding: 30px 0px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            position: relative;
            h2{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-bottom: 10px;
                font-size: 32px;
                width: 100%;
            }
            .controllers{
                position: absolute;
                width: 100%;
                height: 35px;
                top: calc(50% + 5px);
                button{
                    position: absolute;
                    left: 0px;
                    cursor: pointer;
                    &:last-child{
                        left: unset;
                        right: 0px;
                    }
                }
            }
            .slider{
                padding: 20px 0px;
                width: calc(100% - 80px);
                .swiper-wrapper{
                    .item{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        cursor: pointer;
                        .category-item{
                            width: 120px !important;
                            height: 120px !important;
                            border-radius: 100%;
                            background-position: center center;
                            background-size: cover;
                            box-shadow: 2px 2px 4px rgb(0 0 0 / 15%);
                        }
                        h2{
                            font-size: 18px;
                            width: 100%;
                            margin-top: 10px;
                        }
                    }
                }
            }
        }
        .home-special{
            background-color: #E8F5FB;
            padding: 30px 0px;
            @include side-paddings();
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            .column{
                display: flex;
                flex: 1;
                flex-direction: column;
                h2{
                    font-size: 32px;
                    font-weight: 200;
                    margin-bottom: 16px;
                }
                p{
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: 200;
                    margin-bottom: 16px;
                }
                .btn{
                    width: 250px;
                    border: 1px solid #001427;
                    height: 50px;
                    color: #001427;
                }
                .random-products{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    gap: 30px;
                    .product-holder{
                        width: 10vw;
                        height: 10vw;
                        background: #FFFFFF;
                        box-shadow: 0px 8px 16px rgba(0, 69, 104, 0.25);
                        border-radius: 16px;
                        padding: 16px;
                        display: flex;
                        cursor: pointer;
                        .img-holder{
                            display: flex;
                            flex: 1;
                            border: 1px solid #F2F2F2;
                            border-radius: 16px;
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: 90%;
                        }
                    }
                }
            }
        }
        .home-promote{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            .texts{
                @include side-paddings();
                display: flex;
                flex-direction: column;
                flex: 1;
                h2{
                    font-size: 32px;
                    font-weight: 200;
                    margin-bottom: 16px;
                }
                p{
                    font-size: 16px;
                    line-height: 18px;
                    font-weight: 200;
                    margin-bottom: 16px;
                }
                .btn{
                    width: 250px;
                    height: 50px;
                }
            }
        }
        .home-subanner{
            @include side-paddings();
            height: 330px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            background-image: url('@Assets/home/subanner/subanner.jpg');
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right top;
            position: relative;
            &::before{
                content: ' ';
                display: flex;
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                width: 100%;
                height: 100%;
                background: linear-gradient(90deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 68.63%);
            }
            .texts{
                position: relative;
                z-index: 1;
                width: 50%;
                h2{
                    font-size: 24px;
                    margin-bottom: 16px;
                }
                p{
                    font-size: 18px;
                    line-height: 22px;
                    margin-bottom: 16px;
                    color: #001427;
                }
                .btn{
                    width: 180px;
                    height: 50px;
                }
            }
        }
        .related-products{
            background-color: #F9FCFE;
            .title-and-controllers{
                flex-wrap: wrap;
                position: relative;
            }
            .product-related-swiper{
                & + .btn{
                    width: 250px;
                    height: 50px;
                    margin-top: 20px;
                }
            }
        }
        .home-promo{
            position: fixed;
            top: 200px;
            right: 0px;
            box-shadow: 2px 2px 4px rgba(108, 35, 0, 0.16);
            border-top-left-radius: 15px;
            border-bottom-left-radius: 15px;
            z-index: 10;
            width: 400px;
            background-color: #FFFBFA;
            left: calc(100% - 30px);
            display: flex;
            flex-direction: row;
            align-items: center;
            max-width: 380px;
            .band{
                background: #EB6B2F;
                overflow: hidden;
                width: 30px;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;
                display: flex;
                flex: 1;
                flex-direction: row;
                align-items: center;
                justify-content: center;;
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                cursor: pointer;
                .text{
                    color: #FFF;
                    font-size: 14px;
                    transform: rotate(-90deg);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 300px;
                    position: absolute;
                    top: 0px;
                    bottom: 0px;
                    svg{
                        margin-left: 10px;
                        margin-bottom: -5px;
                        transform: rotate(180deg);
                    }
                }
            }
            .content{
                display: flex;
                width: calc(100% - 30px);
                padding: 20px 20px 20px 50px;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                .promo-applier{
                    max-width: unset;
                    background-color: transparent;
                    border-radius: unset;
                    padding: 0px;
                    display: flex;
                    h2{
                        line-height: 28px;
                    }
                    p{

                    }
                    .custom-input{
                        .field-wrapper{
                            padding-right: 0px;
                            input[type="text"]{
                                text-transform: uppercase;
                            }
                            .btn{
                                width: 120px;
                                padding: 0px;
                            }
                        }
                    }
                }
            }
            &.open{
                left: unset;
                right: 0px;
                .band{
                    .text{
                        svg{
                            transform: unset;
                        }
                    }
                }
            }
        }
    }

    // ABOUT
    #about{
        padding-top: $header-height;
        .about-banners{
            display: flex;
            margin-bottom: 27px;
            .banner-item{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                height: 559px;
                background: linear-gradient(90deg, rgba(241,237,236,1) 60%, rgba(255,255,255,1) 70%);
                .img-holder{
                    display: flex;
                    flex-direction: column;
                    background-position: top left;
                    background-repeat: no-repeat;
                    background-size: cover;
                    width: 70%;
                }
                .texts{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 40%;
                    margin-left: 80px;
                    padding-right: 40px;
                    h1{
                        display: inline-block;
                        color: #EB6B2F;
                        font-size: 5vw;
                        font-weight: 200;
                        letter-spacing: -0.4rem;
                        position: relative;
                        height: 120px;
                        margin-bottom: 20px;
                        &::after{
                            position: absolute;
                            content: attr(data-black);
                            color: #505050;
                            top: 40%;
                            left: 25%;
                        }
                    }
                    h3{
                        font-size: 1.7vw;
                        font-weight: 200;
                        margin-bottom: 40px;
                    }
                    p{
                        font-size: 1.2vw;
                        margin-bottom: 15px;
                        font-weight: 200;
                    }
                }
            }
        }
        .about-vmv{
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 40px;
            margin-bottom: 48px;
            padding: 0px 20px;
            .item{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                width: 25rem;
                img{
                    height: 70px;
                    display: flex;
                    margin-bottom: 20px;
                }
                .texts{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    h2{
                        display: flex;
                        color: #000;
                        font-size: 2vw;
                        margin-bottom: 15px;
                    }
                    p{
                        font-size: 1.4vw;
                        font-weight: 100;
                        text-align: center;
                        line-height: 2vw;
                    }
                }
                &:last-child{
                    img{
                    }
                }
            }
        }
        .about-subanner{
            display: flex;
            flex-direction: row;
            position: relative;
            z-index: 1;
            align-items: center;
            &::after{
                content: ' ';
                position: absolute;
                background: linear-gradient(269.07deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%);
                transform: rotate(-180deg);
                left: 0px;
                top: 0px;
                bottom: 0px;
                width: 100%;
            }
            .texts{
                display: flex;
                flex-direction: column;
                position: relative;
                z-index: 2;
                padding-left: 146px;
                h2{
                    font-size: 2vw;
                    font-weight: 200;
                }
                h1{
                    font-size: 4vw;
                    color: #EB6B2F;
                    margin-bottom: 20px;
                }
                p{
                    font-size: 1.4vw;
                    font-weight: 200;
                    line-height: 2.2vw;
                    margin-bottom: 20px;
                    text-align: justify;
                    padding-right: 40px;
                }
                h3{
                    font-size: 2vw;
                    font-weight: 200;
                }
            }
            img{
                width: 50%;
            }
        }
        .about-punch{
            background-color: #F9FCFE;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            padding: 69px 0px;
            gap: 50px;
            .closer{
                display: flex;
                flex-direction: column;
                width: 30%;
                h2{
                    font-size: 2.5vw;
                    font-weight: 200;
                    margin-bottom: 20px;
                }
                p{
                    font-size: 1.8vw;
                    font-weight: 200;
                    margin-bottom: 30px;
                }
                .city-item{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 15px;
                    padding: 0px;
                    &:last-child{
                        margin-bottom: 0px;
                    }
                    big{
                        display: flex;
                        font-size: 2.5vw;
                        color: #EB6B2F;
                        font-weight: 400;
                        margin: 0px 15px 0px 10px;
                    }
                    a{
                        display: flex;
                        color: #1289C5;
                        font-size: 1vw;
                        text-decoration: underline;
                    }
                }
            }
            .likeit{
                display: flex;
                flex-direction: column;
                padding: 46px;
                background: #E8F5FB;
                border-radius: 24px;
                width: 30%;
                align-items: center;
                h2{
                    font-size: 2vw;
                    font-weight: 400;
                    margin-bottom: 16px;
                }
                h3{
                    font-size: 1.7vw;
                    line-height: 30px;
                    font-weight: 200;
                    text-align: center;
                    margin-bottom: 15px;
                }
                .btn{
                    height: 58px;
                    width: 60%;
                    margin: 0px;
                    text-decoration: none;
                }
            }
        }
    }

    // CATALOG
    #catalog{
        display: flex;
        min-height: calc(100vh - #{$header-height});
        flex-direction: column;
        background-color: #F9FCFE;
        padding-top: $header-height;
        .banner{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            height: 180px;
            background-image: url('@Assets/catalog/general-banner.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
            position: relative;
            span{
                font-size: 42px;
                color: #FFF;
                position: relative;
                border: 1px ;
                text-shadow: 0.01em 0.01em black;
                z-index: 1;
            }
            &::after{
                content: ' ';
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;
                background: rgba(0, 69, 104, 0.85);
            }
            &.is-outlet{
                background-image: url('@Assets/catalog/outlet-banner.png');
                &::after{
                    display: none;
                }
            }
        }
        .filters-and-sorters{
            display: flex;
            flex-direction: column;
            background-color: #FFF;
            min-height: 50px;
            justify-content: center;
            position: sticky;
            top: $header-height;
            z-index: 1;
            padding-top: 10px;
            transition: top 0.5s ease-in-out;
            &.hidden{
                top: 0px;
            }
            .content{
                @include side-paddings();
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                .other-filters{
                    display: none;
                }
                .filters-holder{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    h2{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        font-size: 18px;
                        font-weight: 400;
                        svg{
                            display: none;
                        }
                    }
                    .filterable-items{
                        .overflow{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            .item{
                                display: flex;
                                margin-left: 30px;
                                position: relative;
                                button{
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    border-right: 1px solid #F2F2F2;
                                    padding-right: 20px;
                                    svg{
                                        display: inline-flex;
                                        margin-left: 20px;
                                    }
                                }
                                .box{
                                    position: absolute;
                                    width: 220px;
                                    max-height: 200px;
                                    overflow: auto;
                                    top: calc(100% + 5px);
                                    z-index: 1;
                                    .custom-checkbox{
                                        height: 47px;
                                        margin: 0px;
                                        display: flex;
                                        flex-direction: row-reverse;
                                        justify-content: space-between;
                                        padding: 0px 14px;
                                        width: calc(100% - 28px);
                                        position: relative;
                                        &::after{
                                            position: absolute;
                                            height: 1px;
                                            content: ' ';
                                            background-color: #F2F2F2;
                                            width: 90%;
                                            left: 5%;
                                            bottom: 0px;
                                        }
                                        .chkbox{
                                            margin: 0px;
                                        }
                                        &:nth-child(2n){
                                            background-color: #F9FCFE;
                                        }
                                        &:last-child{
                                            &::after{
                                                display: none;
                                            }
                                        }
                                    }
                                }
                                &:last-of-type{
                                    button{
                                        border-right: none;
                                        padding-right: 0px;
                                    }
                                }
                            }
                            .linex-holder{
                                display: none;
                            }
                        }
                        .buttons-mobile{
                            display: none;
                        }
                    }
                }
                .sorters-holder{
                    button{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        svg{
                            &:last-child{
                                display: none;
                            }
                        }
                    }
                    .box{
                        position: absolute;
                        width: 220px;
                        max-height: 200px;
                        overflow: auto;
                        top: calc(100% + 5px);
                        z-index: 1;
                        right: 30px;
                        .custom-radio{
                            height: 47px;
                            margin: 0px;
                            display: flex;
                            flex-direction: row-reverse;
                            justify-content: space-between;
                            padding: 0px 14px;
                            width: calc(100% - 28px);
                            position: relative;
                            &::after{
                                position: absolute;
                                height: 1px;
                                content: ' ';
                                background-color: #F2F2F2;
                                width: 90%;
                                left: 5%;
                                bottom: 0px;
                            }
                            .chkbox{
                                margin: 0px;
                            }
                            &:nth-child(2n){
                                background-color: #F9FCFE;
                            }
                            &:last-child{
                                &::after{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
            .applied-filters{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                @include side-paddings();
                .btn{
                    width: auto;
                    padding: 0px 15px;
                    font-size: 12px;
                    margin-right: 5px;
                    margin-top: 10px;
                    margin-bottom: 10px;
                }
            }
        }
        .catalog-items{
            @include side-paddings();
            display: grid;
            gap: 1.5rem;
            //grid-auto-rows: 34rem;
            justify-content: space-between;
            grid-template-columns: repeat(auto-fit, minmax(14rem, 0.25fr));
            padding-top: 40px;
            padding-bottom: 40px;
        }
        // EMPTY
        .catalog-empty{
            width: 30%;
            display: flex;
            flex-direction: column;
            align-items: center;
            align-self: center;
            margin-top: 80px;
            h2{
                margin: 20px 0px;
            }
            .search-keyword{
                width: 100%;
            }
            .buttons-holder{
                width: 70%;
                .btn{
                    height: 48px;
                }
            }
        }
        .catalog-end{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: #FFF;
            box-shadow: 0px 4px 12px rgb(0 69 104 / 25%);
            border-radius: 8px;
            padding: 18px;
            h2{
                display: flex;
                color: #C4C4C4;
                line-height: 18px;
                text-align: center;
            }
            img{
                display: flex;
                width: 40%;
                margin-bottom: 30px;
            }
            .search-keyword{
                width: 90%;
                input{
                    text-align: center;
                    width: 100%;
                }
            }
        }
        .load-more-holder{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: 80px;
            margin-top: 40px;
            .btn{
                width: 200px;
                svg{
                    margin-right: 10px;
                }
            }
        }
    }

    // Product Detail
    #detail{
        display: flex;
        flex-direction: column;
        padding-top: calc(#{$header-height} + 60px);
        .content{
            display: flex;
            @include content-width();
            flex-direction: row;
            justify-content: space-between;
            flex-wrap: wrap;
            .holder-gallery{
                width: 50%;
                justify-content: center;
                .product-gallery{
                    justify-content: center;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    img{
                        height: 350px;
                    }
                }
            }
            .holder-info{
                width: 40%;
                .product-texts{
                    span{
                        display: flex;
                        margin-bottom: 15px;
                    }
                    .product-hearth{
                        display: flex;
                    }
                    h2{
                        margin-bottom: 0px;
                        font-weight: 400;
                    }
                    h3{
                        margin-bottom: 30px;
                    }
                }
                .product-prices{
                    justify-content: space-between;
                    big, small{
                        margin-right: 20px;
                        width: 50%;
                    }
                    .product-discount{
                        display: flex;
                    }
                    .product-hearth{
                        display: none;
                    }
                }
                .increaser-and-button{
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    .product-increaser{
                        width: 30%;
                    }
                    .product-buttons{
                        width: 65%;
                        .btn{
                            justify-content: center;
                            background-color: #EB6B2F;
                            small{
                                display: none;
                            }
                        }
                        a{
                            display: none;
                        }
                        legend{
                            display: flex;
                            span{
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
            .holder-characteristics{
                display: flex;
                flex-direction: column;
                width: 100%;
                margin-bottom: 40px;
                h2{
                    font-size: 24px;
                    font-weight: 300;
                    margin-bottom: 40px;
                }
                .table{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    .item{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        width: calc(20% - 18px);
                        margin-right: 18px;
                        margin-bottom: 10px;
                        &:last-child{
                            margin-right: 0px;
                        }
                        label, span{
                            height: 32px;
                            display: flex;
                            align-items: center;
                            background-color: #E8F5FB;
                            width: calc(50% - 32px);
                            justify-content: center;
                            padding: 0px 15px;
                            font-size: 14px;
                        }
                        label{
                            border-top-left-radius: 8px;
                            border-bottom-left-radius: 8px;
                            font-weight: 400;
                        }
                        span{
                            background-color: #F9FCFE;
                            border-top-right-radius: 8px;
                            border-bottom-right-radius: 8px;
                        }
                    }
                }
            }
        }
        .holder-opinions{
            width: 100%;
            display: flex;
            background-color:  #E8F5FB;
            padding: 30px 0px;
            flex-direction: column;
            justify-content: center;
            .title-and-button{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                align-items: center;
                h2{
                    font-size: 18px;
                }
                .tools{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    .btn{
                        width: 234px;
                        height: 58px;
                        font-weight: 400;
                        margin-right: 30px;
                        margin: 0px;
                        &.link{
                        }
                    }
                    .controllers{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        button{
                            transition: all 0.35s;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            &:hover{
                                background-color: #F2F2F2;
                            }
                        }
                    }
                }
            }
            .opinions{
                @include content-width();
                margin-top: 16px;
                padding: 20px 0px;
                display: flex;
                width: 100%;
                flex-direction: row;
                flex-wrap: wrap;
                gap: 30px;
                justify-content: space-between;
                .opinion{
                    display: flex;
                    flex-direction: row;
                    background: #FFFFFF;
                    box-shadow: 0px 4px 12px rgba(0, 69, 104, 0.25);
                    border-radius: 12px;
                    width: calc(50% - 48px) !important;
                    padding: 16px;
                    justify-content: space-between;
                    .img-holder{
                        display: flex;
                        width: 80px;
                        height: 80px;
                        border-radius: 100%;
                        background-color: #C4C4C4;
                    }
                    .info-holder{
                        display: flex;
                        width: calc(100% - 100px);
                        flex-direction: column;
                        h2{
                            color: #001427;
                            margin-bottom: 10px;
                        }
                        p{
                            color: #898989;
                            line-height: 22px;
                        }
                    }
                }
            }
            .opinion-form{
                display: flex;
                flex-direction: row;
                width: 100%;
                background-color: #FFF;
                margin-top: 30px;
                padding: 20px;
                gap: 20px;
                justify-content: space-between;
                .img-holder{
                    width: 90px;
                    height: 90px;
                    border-radius: 100%;
                    background-color: #f5f9fa;
                    font-size: 40px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                }
                .opinion-area{
                    display: flex;
                    flex: 1;
                    flex-direction: column;
                    h2{
                        color: #003D5C;
                        font-size: 16px;
                        margin-bottom: 10px;
                    }
                    .custom-textarea{
                        margin-top: 5px;
                        margin-bottom: 0px;
                        .field-wrapper{
                            background: #F9FCFE;
                            border-radius: 0px;
                            border: none;
                            textarea{
                                color: #004568;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }
        .bottom-button{
            display: none;
        }
    }

    // RELATED PRODUCTS
    .related-products{
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 40px 0px;
        overflow: hidden;
        flex-wrap: wrap;
        .title-and-controllers{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            @include side-paddings();
            h2{
                display: flex;
                font-size: 24px;
            }
            .controllers{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                button{
                    transition: all 0.35s;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    &:hover{
                        background-color: #F2F2F2;
                    }
                }
            }
        }
        .product-related-swiper{
            display: flex;
            flex-direction: row;
            padding-bottom: 60px;
            overflow-x: hidden;
            .slide-item{
                //max-width: calc(20% - 20px);
                //min-width: 240px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 15px 1vw;
                .product-item{
                    height: calc(100% - 60px);
                    width: calc(100% - 36px);
                }
            }
            & + .btn{
                width: 280px;
            }
        }
    }

    // SEARCH
    .search-keyword{
        display: flex;
        flex-direction: row;
        position: relative;
        border-bottom: 1px solid #C4C4C4;
        margin-bottom: 10px;
        input[type="text"]{
            width: calc(100% - 30px);
        }
        button{
            svg{

            }
        }
    }
    .search-categories{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 10px;
        h2{
            display: flex;
            width: 100%;
            margin-bottom: 20px;
            justify-content: center;
            align-items: center;
            text-decoration: underline;
        }
        .btn{
            display: flex;
            width: 18%;
            font-size: 14px;
            text-decoration: underline;
            height: 30px;
            margin-bottom: 10px;
            .default{
                background-color: #F9FCFE;
            }
            .secondary{
                text-decoration: none;
            }
        }
    }
    .search-prices{
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        h2{
            display: flex;
            width: 100%;
            margin-bottom: 30px;
            justify-content: center;
            align-items: center;
            text-decoration: underline;
        }
        .range-holder{
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            position: relative;
            .range-slider-rail{
                height: 36px;
                display: flex;
                width: 98%;
            }
            &::before, &::after{
                content: ' ';
                width: 16px;
                height: 16px;
                border-radius: 16px;
                background-color: #fff;
                position: absolute;
                left: -3px;
                bottom: 6px;
                border: 4px solid #C4C4C4;
                z-index: 0;
            }
            &::after{
                left: unset;
                right: -3px;
            }
            .slider-thumb{
                width: 24px;
                height: 24px;
                background-color: #1B97D5;
                border-radius: 24px;
                .slider-thumb-label{
                    position: absolute;
                    top: -28px;
                    width: 45px;
                    left: -50%;
                    text-align: center;
                    color: #505050;
                    font-weight: 200;
                    font-size: 14px;
                    line-height: 18px;
                    background-color: transparent;
                    border-bottom: 1px solid #00000035;
                }
            }
        }

    }
    .search-materials{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        h2{
            display: flex;
            width: 100%;
            margin-bottom: 10px;
            justify-content: flex-start;
            align-items: center;
            text-decoration: underline;
        }
        .custom-checkbox{
            width: 30%;
            margin: 0px 10px 10px 0px;
            span{
                font-size: 14px;
            }
        }
    }
    .search-colors{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        h2{
            display: flex;
            width: 100%;
            margin-bottom: 10px;
            justify-content: flex-start;
            align-items: center;
            text-decoration: underline;
        }
        .product-variants{
            border: none;
            padding: 0px;
            margin: 0px;
            .variants{
                flex-wrap: wrap;
                .color{
                    margin: 0px 10px 10px 0px;
                }
            }
        }
    }

    //LOGIN
    #login{
        display: flex;
        flex-direction: column;
        height: calc(100vh - #{$header-gray-bay});
        .area-login{
            display: flex;
            flex-direction: column;
            padding: 40px 32px;
            h2{
                font-size: 32px;
                margin-bottom: 20px;
                font-weight: 200;
            }
            .custom-input{
                .field-wrapper{
                    height: 50px;
                    border: 1px solid #C4C4C4;
                    border-radius: 29px;
                    input{
                        text-align: center;
                    }
                }
            }
            .buttons-holder{
                .primary{
                    height: 58px;
                }
            }
        }
        .area-register{
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 40px 32px;
            background-color: #001427;
            color: #FFF;
            h2{
                font-weight: 200;
                font-size: 28px;
                margin-bottom: 30px;
            }
            p{
                font-size: 16px;
                line-height: 24px;
                font-weight: 200;
                margin-bottom: 30px;
            }
            .btn{
                height: 58px;
                background-color: #192b3d;
                color: #FFF;
                margin-bottom: 20px;
            }
            legend{
                display: flex;
                width: 300px;
                align-self: center;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                font-weight: 200;
                border-bottom: 1px solid #FFFFFF55;
                padding-bottom: 5px;
            }
        }
    }

    // USER STYLES
    #user-panel{
        padding: 40px 0px;
        h1{
            @include side-panel-paddings();
            font-size: 32px;
            font-weight: 200;
            margin-bottom: 30px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
        hr{
            height: 1px;
            border: none;
            background-color: #F2F2F2;
            margin: 30px 0px
        }
        nav{
            @include side-panel-paddings();
            display: flex;
            flex-direction: column;
            a{
                border-bottom: 1px solid #F2F2F2;
                height: 55px;
                display: flex;
                flex-direction: row;
                align-items: center;
                text-decoration: none;
                color: #505050;
                cursor: pointer;
                span{
                    margin-left: 10px;
                }
            }
        }
        .user-card{
            @include side-panel-paddings();
            align-items: center;
            .img-holder{
                width: 88px;
                height: 88px;
            }
            .info-holder{
                width: calc(100% - 120px);
                .btn.link{
                    display: none;
                }
                .userid-and-tag{
                    h2{
                        font-size: 18px;
                        font-weight: 300;
                        margin-bottom: 5px;
                    }
                    .tag{
                        display: none;
                    }
                    .btn.link{
                        display: flex;
                    }
                    .btn-edit{
                        display: none !important;
                    }
                }
            }
            .table-row{
                display: none;
            }
        }
    }
    .user-card{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        .btn{
            &.link{
                justify-content: flex-end;
                height: unset;
                font-size: 14px;
                padding: 0px;
            }
        }
        .img-holder{
            width: 110px;
            height: 110px;
            background-color: #f5f9fa;
            border-radius: 100%;
            position: relative;
            .icon-holder{
                display: flex;
                flex-direction: row;
                width: 35px;
                height: 35px;
                border-radius: 100%;
                position: absolute;
                bottom: 0px;
                left: 0px;
                padding: 0px;
                background-color: #FFF1EB;
                justify-content: center;
                align-items: center;
                svg{
                    padding: 0px;
                    margin: 0px;
                    margin-bottom: -3px;
                    margin-left: -2px;
                }
            }
        }
        .info-holder{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            width: calc(100% - 140px);
            .userid-and-tag{
                display: flex;
                width: 100%;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                .titles{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-right: 20px;
                    h2{
                        color: #001427;
                        font-size: 24px;
                        font-weight: 400;
                    }
                    h3{
                        font-weight: 200;
                        font-size: 14px;
                    }
                    .btn{
                        &.link{
                            justify-content: flex-start;
                        }
                    }
                }
                .tag{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    padding: 0px 20px;
                    height: 42px;
                    background: #FFF1EB;
                    border-radius: 29px;
                    color: #EB6B2F;
                    font-size: 14px;
                    svg{
                        margin-right: 5px;
                    }
                }
            }
        }
    }
    // Initials in card
    .initials{
        width: 60px;
        height: 60px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content:  center;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        margin-top: -30px;
        left: 50%;
        margin-left: -30px;
        font-size: 30px;
    }
    // User template
    .user-template{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: calc(#{$header-height} + 0px);
        flex: 1;
        //height: calc(100vh - #{$header-height});
        .user-template-side{
            width: 440px;
            #user-panel{
                padding: 40px 0px 20px 0px;
                nav{
                    margin-bottom: 40px;
                }
            }
        }
        .user-template-content{
            width: calc(100% - 440px);
            background-color: #f5f9fa;
            padding: 50px 32px 24px 16px;
            overflow: auto;
            .content{
                //max-width: 980px;
                h1{
                    font-size: 24px;
                    font-weight: 200;
                    margin-bottom: 24px;
                    svg{
                        display: none;
                    }
                }
                // PROFILE
                .user-profile{
                    display: flex;
                    flex-direction: column;
                    .card{
                        background: #FFFFFF;
                        box-shadow: 0px 4px 4px rgba(188, 188, 188, 0.15);
                        border-radius: 12px;
                        display: flex;
                        flex-direction: row;
                        padding: 18px;
                        margin-bottom: 30px;
                        justify-content: space-between;
                        .user-card{
                            .img-holder{
                                width: 90px;
                                height: 90px;
                            }
                            .info-holder{
                                .userid-and-tag{
                                    .titles{
                                        width: 150px;
                                        h2{
                                            font-size: 18px;
                                        }
                                        .btn.link{
                                            display: none;
                                        }
                                    }
                                    .tag{
                                        display: none;
                                    }
                                    .btn-edit{
                                    }
                                }
                            }
                        }
                    }
                    .region-and-seller{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        .is-holder{
                            .card{
                                flex: 1; // Esto hace que ambas card tenga la misma altura...
                            }
                        }
                        .holder-region{
                            display: flex;
                            flex-direction: column;
                            width: 28%;
                            .region-card{
                                align-items: center;
                                justify-content: center;
                                .icon-holder{
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: center;
                                    align-items: center;
                                    width: 80px;
                                }
                                label{
                                    font-size: 18px;
                                    color: #001427;
                                }
                            }
                        }
                        .holder-seller{
                            display: flex;
                            flex-direction: column;
                            width: 70%;
                            .seller-card{
                                .img-holder{
                                    width: 60px;
                                    height: 60px;
                                    border-radius: 100%;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: center;
                                    background-color: #EDEDED;
                                    background-size: 70%;
                                    background-repeat: no-repeat;
                                    background-position: center center;
                                }
                                .info-holder{
                                    width: calc(100% - 110px);
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: space-between;
                                    .titles{
                                        h2{
                                            color: #001427;
                                            font-size: 16px;
                                            font-weight: 400;
                                            margin-bottom: 10px;
                                        }
                                        a{
                                            color: #1289C5;
                                            text-decoration: underline;
                                        }
                                    }
                                    .whatsapp{
                                        height: 48px;
                                        padding: 0px 20px;
                                        border: 1px solid #34C240;
                                        border-radius: 29px;
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        justify-content: center;
                                        color: #34C240;
                                        font-size: 16px;
                                        text-decoration: none;
                                        svg{
                                            margin-right: 10px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                // Nothing to show
                .nothing{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    flex: 1;
                    height: calc(100vh - calc(#{$header-height} + 120px));
                    h2{
                        font-size: 36px;
                        color: #898989;
                        font-weight: 200;
                        margin-bottom: 30px;
                    }
                    p{
                        font-size: 24px;
                        line-height: 28px;
                        width: 35%;
                        font-weight: 200;
                        color: #000;
                        text-align: center;
                        margin-bottom: 50px;
                    }
                    a{
                        color: #007FC7;
                    }
                    .search-keyword{
                        width: 60%;
                    }
                    .btn{
                        width: 200px;
                        height: 58px;
                    }
                }
            }
        }
    }

    // ORDER
    .order-box{
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgb(188 188 188 / 15%);
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        padding: 24px;
        margin-bottom: 20px;
        justify-content: space-between;
        .collapser-header{
            .content{
                .table-row{
                    padding-left: unset;
                    .table-col{
                        &:first-of-type{
                            span{
                                color: unset;
                            }
                        }
                        &:last-of-type{
                            span{
                                color: unset;
                            }
                        }
                        &:nth-last-of-type(3){
                            span{
                                color: #34C240;
                            }
                        }
                    }
                }
            }
        }
        .order-detail{
            transition: all 0.35s;
            .order-detail-title{
                margin: 40px 0px 30px 0px;
                display: flex;
                flex-direction: row;
                align-items: center;
                span{
                    display: flex;
                    font-size: 14px;
                    margin-left: 30px;
                    &:first-of-type{
                        margin-left: 5px;
                    }
                    b{
                        font-weight: 400;
                        margin-left: 5px;
                    }
                }
            }
            .order-row{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                border-bottom: 1px solid #C4C4C485;
                padding-bottom: 10px;
                margin-bottom: 10px;
                &:last-child{
                    border-bottom: none;
                }
                .img-holder{
                    width: 70px;
                    height: 70px;
                    border: 1px solid #f0f0f0;
                    border-radius: 10px;
                    margin-right: 20px;
                    background-size: contain;
                    background-position: center center;
                }
                .sections{
                    display: flex;
                    flex-direction: row;
                    width: calc(100% - 100px);
                    justify-content: space-between;
                    section{
                        display: flex;
                        flex-direction: column;
                        font-size: 14px;
                        width: 14%;
                        label{
                            display: flex;
                            flex-direction: row;
                            margin-bottom: 5px;
                        }
                        span{
                            display: flex;
                            flex-direction: row;
                            font-size: 16px;
                        }
                        &:first-of-type{
                            span{
                                color: #EB6B2F;
                                font-size: 12px;
                            }
                        }
                        &:nth-last-of-type(2){
                            span{
                                color: #34C240;
                            }
                        }
                    }
                }
            }
        }
    }

    // ADDRESS CARD
    .address-card{
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(188, 188, 188, 0.15);
        border-radius: 12px;
        padding: 16px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        h2{
            color: #0086D0;
            font-size: 14px;
            width: 70%;
        }
        .btn-delete{
            display: flex;
            align-items: center;
            justify-content: center;
        }
        label{
            display: flex;
            font-weight: 400;
            font-size: 11px;
            color: #898989;
            width: 100%;
            margin: 10px 0px 5px 0px;
        }
        p{
            display: block;
            width: 100%;
            font-size: 14px;
            margin: 0px;
            text-overflow: ellipsis;
            word-wrap: break-word;
            overflow: hidden;
            height: 28px;
            margin-bottom: 5px;
        }
        .table-row{
            display: flex;
            width: 100%;
            margin: 0px;
            padding-left: 0px;
            .table-col{
                label{
                    font-size: 11px;
                }
                span{
                    color: unset;
                    font-size: 12px;
                }
            }
        }
        .btn-edit{
            display: flex;
            width: 100%;
            padding: 0px;
            color: #FF7433;
            margin-top: 10px;
            font-size: 14px
        }
    }

    // ADDRESSES SECTION
    #addresses{
        display: flex;
        flex-direction: column;
        .title-and-button{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
            h1{
                display: flex;
                margin: 0px;
            }
            .btn{
                display: flex;
                width: 200px;
                svg{
                    display: none;
                }
            }
        }
        .addresses-holder{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            .address-card{
                width: 20%;
                margin-bottom: 20px;
                margin-right: 2%;
                &:nth-child(4n){
                    margin-right: 0px;
                }
            }
        }
    }
    // Address
    .addresss-form{
        padding: 30px;
        max-width: 600px;
        background-color: #FFF;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        border-radius: 20px;
        h2{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: 30px;
            width: 100%;
            font-size: 24px;
        }
        .custom-input{
            width: 48%;
            .field-wrapper{
                background-color: #F2F2F2;
                border: 1px solid #C4C4C4;
                border-radius: 40px;
            }
        }
        .btn-holder{
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 20px;
            .btn{
                height: 50px;
                width: 300px;
                justify-self: center;
            }
        }

    }


    // CART STEP 0
    #cart{
        display: flex;
        flex-direction: row;
        //height: calc(100vh - #{$header-height});
        //overflow: hidden;
        min-height: calc(100vh - calc(#{$header-height} + 80px));
        padding-top: $header-height;
        padding-bottom: 80px;
        background-color: #f9fcfe;
        // CART LIST
        .cart-list{
            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(188, 188, 188, 0.15);
            border-bottom-right-radius: 16px;
            padding: 15px;
            .cart-list-header{
                display: flex;
                flex-direction: row;
                align-items: center;
                h2{
                    font-size: 15px;
                    margin-left: 10px;
                    b{
                        font-weight: 400;
                    }
                }
            }
            .cart-list-content{
                padding: 10px 0px;
            }
        }
        // CART ITEM
        .cart-item{
            position: relative;
            display: flex;
            flex-direction: row;
            padding: 20px 0px;
            justify-content: space-between;
            .img-holder{
                display: flex;
                width: 90px;
                height: 90px;
                border: 1px solid #C4C4C4;
                border-radius: 8px;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
            }
            .content-holder{
                display: flex;
                flex-direction: row;
                width: calc(100% - 120px);
                flex-wrap: wrap;
                h2{
                    display: flex;
                    font-size: 18px;
                    width: 100%;
                }
                .column{
                    display: flex;
                    flex-direction: column;
                    width: 31%;
                    align-items: flex-start;
                    justify-content: center;
                    padding: 0px;
                    small{
                        font-size: 14px;
                        color: #EB6B2F;
                        margin-bottom: 10px;
                    }
                    span{
                        color: #001427;
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        align-items: center;
                        b{
                            font-weight: 200;
                            margin: 0px 15px 0px 5px;
                        }
                    }
                    label{
                        margin-bottom: 10px;
                    }
                    .icon-detelete{}
                    &:last-of-type{
                        width: 60%;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-items: center;
                        label{
                            display: flex;
                        }
                        .product-increaser{
                            height: 34px;
                            display: flex;
                            width: 30%;
                            justify-content: center;
                            span{
                                width: 60%;
                                justify-content: center;
                            }
                        }
                        .product-buttons{
                            display: flex;
                            flex-direction: column;
                            width: 60%;
                            .btn, a{
                                display: none;
                            }
                            legend{
                                display: flex;
                                flex: 1;
                                margin: 0px;
                                flex-direction: column;
                                span{
                                    display: flex;
                                    width: 100%;
                                    font-size: 14px;
                                    margin-bottom: 10px;
                                }
                                b{
                                    font-weight: 700;
                                    font-size: 24px;
                                }
                            }
                        }
                    }
                }
            }
            &::before{
                content: ' ';
                position: absolute;
                top: 0px;
                left: 1.5%;
                width: 97%;
                height: 1px;
                background-color: #F2F2F2;
            }
        }
        // STEP-AUTH
        .cart-step-auth{
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            overflow-y: auto;
            .content{
                @include content-width();
                justify-content: center;
                .header{
                    display: flex;
                    width: 100%;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;
                    h1{
                        display: flex;
                        width: 50%;
                        font-size: 24px;
                        font-weight: 300;
                    }
                    .btn{
                        justify-content: flex-end;
                        margin: 0px;
                        width: 120px;
                    }
                }
                .login-holder{
                    display: flex;
                    margin-bottom: 40px;
                    justify-content: space-between;
                    flex-direction: row;
                    #login{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        height: 55vh;
                        width: 100%;
                        .area-login{
                            display: flex;
                            width: 45%;
                            flex: unset;
                            box-shadow: 0px 8px 16px rgba(0, 69, 104, 0.25);
                            border-radius: 16px;
                        }
                        .area-register{
                            display: flex;
                            width: 40%;
                            flex: unset;
                            box-shadow: 0px 8px 16px rgba(0, 69, 104, 0.25);
                            border-radius: 16px;
                            text-align: center;
                        }
                    }
                }
                .btn{

                }
            }
        }
        // CART MOTIVATION
        .cart-motivation{
            background: conic-gradient(from 194.33deg at 59.07% 55.67%, #FD23BA -43.81deg, #FD5261 98.4deg, #FD23BA 316.19deg, #FD5261 458.4deg);
            box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.15);
            border-radius: 8px;
            width: 100%;
            display: flex;
            flex-direction: column;
            color: #FFF;
            padding: 16px;
            h2{
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 15px;
            }
            h3{
                font-size: 16px;
                margin-bottom: 15px;
            }
            h4{
                font-weight: 700;
                font-size: 21px;
                big{
                    font-size: 42px;
                }
            }
        }
        // CART MINICART
        .cart-minicart{
            background: #FFFFFF;
            box-shadow: 0px 4px 4px rgba(188, 188, 188, 0.15);
            border-radius: 12px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            padding: 16px;
            margin-bottom: 16px;
            width: 100%;
            h2{
                display: flex;
                flex-direction: row;
                flex: 1;
                align-items: center;
                justify-content: space-between;
                font-size: 24px;
                font-weight: 300;
                margin-bottom: 10px;
                .btn{
                    display: flex;
                    margin: 0px;
                    padding: 0px;
                    width: auto;
                }
            }
            h3{
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 16px;
                font-weight: 200;
                margin-bottom: 10px;
                svg{
                    margin-right: 10px;
                }
                b{
                    font-weight: 300;
                }
            }
            .scroll-area{
                display: flex;
                flex-direction: column;
                height: 100px;
                overflow: auto;
                .cart-item{
                    padding: 10px 0px;
                    .img-holder{
                        width: 80px;
                        height: 80px;
                    }
                    .content-holder{
                        align-items: center;
                        width: calc(100% - 90px);
                        h2{
                            font-size: 14px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2; /* number of lines to show */
                                    line-clamp: 2;
                            -webkit-box-orient: vertical;
                            border: 1px solid transparent;
                        }
                        .column{
                            display: none;
                            width: 100%;
                            height: 15px;
                            &:nth-of-type(2){
                                display: flex;
                                .product-increaser{
                                    display: none;
                                }
                                .product-buttons{
                                    width: 100%;
                                    legend{
                                        flex-direction: row;
                                        justify-content: flex-start;
                                        span{
                                            display: flex;
                                            width: auto;
                                            margin-right: 10px;
                                        }
                                        b{
                                            width: auto;
                                            font-size: 14px !important;
                                            font-weight: 400;
                                        }
                                    }
                                }
                            }
                        }
                        .icon-detelete{
                            display: none;
                        }
                    }
                }
            }
        }
        // CART TEMPLATE
        .cart-template{
            display: flex;
            flex-direction: row;
            width: 100vw;
            justify-content: space-between;
            gap: 20px;
            .bg{
                visibility: hidden;
                opacity: 0;
            }
            section{
                padding-top: 40px;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                width: calc(100vw - (#{$cart-side-width} - 20px));
                &:first-child{
                    padding-right: 20px;
                }
                &:last-child{
                    width: calc(#{$cart-side-width} - 60px);
                    align-items: flex-start;
                    padding-right: 60px;
                }
                .content-header{
                    display: flex;
                    flex-direction: row;
                    width: 90%;
                    justify-content: space-between;
                    align-items: center;
                    h1{
                        display: flex;
                        font-size: 24px;
                        font-weight: 300;
                        width: 40%;
                        align-items: center;
                        svg{
                            margin-right: 10px;
                        }
                    }
                    .btn{
                        width: 150px;
                        margin: 0px;
                        &.tertiary{
                            display: none;
                        }
                    }
                    //STEPPER
                    $steps-count: 3;
                    $step-bullet-width-height: 24px;
                    $step-line-width: 56px;
                    .cart-stepper{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        width: calc((#{$step-line-width} * #{$steps-count}) + (#{$step-bullet-width-height} * #{$steps-count}) - 20px);
                        span{
                            display: flex;
                            width: $step-bullet-width-height;
                            height: $step-bullet-width-height;
                            background-color: #C4C4C4;
                            border-radius: 100%;
                            align-items: center;
                            justify-content: center;
                            position: relative;
                            &::before{
                                position: absolute;
                                display: flex;
                                content: ' ';
                                width: $step-line-width;
                                height: 4px;
                                background-color: #C4C4C4;
                                right: calc(100% + 5px);
                            }
                            &:first-of-type{
                                &::before{
                                    display: none;
                                }
                            }
                            &.current{
                                background-color: #FF7433;
                                &::before{
                                    background-color: #FF7433;
                                }
                            }
                        }
                    }
                }
                .content-area{
                    width: 90%;
                    display: flex;
                    flex-direction: column;
                    margin-top: 30px;
                    h1{
                        svg{
                            display: none;
                        }
                    }
                    // #ADDRESSES
                    #addresses{
                        .title-and-button{
                            .btn{
                                background-color: #FFF;
                                border: 1px solid #1289C5;
                                color: #1289C5;
                                svg{
                                    display: flex;
                                    margin-right: 10px;
                                }
                            }
                        }
                    }

                    // COMPLETED
                    .cart-compeleted{
                        h1{
                            font-size: 20px;
                            margin: 20px 0px 40px 0px;
                        }
                        p{
                            font-size: 16px;
                            line-height: 20px;
                            margin-bottom: 40px;
                        }
                        .btn{
                            width: 220px;
                            height: 50px;
                        }
                    }
                }

                // CART SUMMARY
                .cart-summary{
                    background: #FFFFFF;
                    box-shadow: 0px 0px 12px rgba(0, 69, 104, 0.25);
                    border-radius: 12px;
                    padding: 14px;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;
                    width: 100%;
                    h2{
                        font-size: 24px;
                        font-weight: 200;
                        margin-bottom: 30px;
                    }
                    article{
                        display: flex;
                        flex-direction: column;
                        margin-bottom: 20px;
                        label{
                            font-size: 14px;
                            margin-bottom: 5px;
                        }
                        span{
                            color: #34C240;
                            font-size: 18px;
                            font-weight: 700;
                        }
                        &:nth-of-type(2){
                            span{
                                text-decoration: line-through;
                                color: #505050;
                                font-weight: 300;
                            }
                        }
                        &:last-of-type{
                            span{
                                color: #505050;
                            }
                        }
                    }
                    .btn{
                        margin-top: 0px;
                        height: 58px;
                    }
                    .cart-motivation{
                        display: none;
                    }
                }
                .cart-motivation{
                    margin-top: 20px;
                }
                .cart-active-promo{
                    margin-top: 20px;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    padding: 16px;
                    h2{
                        color: #898989;
                        font-size: 16px;
                    }
                    h3{
                        color: #34C240;
                        font-size: 24px;
                        font-weight: 500;
                    }
                }
            }
            // CART-STEPS
            &.cart-step-1,
            &.cart-step-2,
            &.cart-step-3{
                .content-area{
                    overflow: auto;
                    padding-bottom: 40px;
                    //padding-right: 10px;
                }
                section{
                    &:nth-of-type(1){
                        #addresses + h2{
                            margin-top: 30px;
                        }
                    }
                    &:nth-of-type(2){
                        .cart-summary{
                            margin-bottom: 16px;
                            h2{
                                font-size: 24px;
                                margin-bottom: 15px;
                            }
                            article{
                                display: none;
                                margin-bottom: 20px;
                                &:nth-last-of-type(1),
                                &:nth-last-of-type(2){
                                    display: flex;
                                }
                                &:last-of-type{
                                    margin-bottom: 0px;
                                }
                            }
                            .btn{
                                display: none;
                            }
                        }
                        .cart-motivation{
                            margin-bottom: 15px;
                        }
                        .buttons-holder{
                            display: flex;
                            flex-direction: column;
                            width: calc(100% + 32px); // 16px padding de cada lado de los otros elementos
                            .btn{
                                height: 58px;
                                margin: 0px;
                                &.link{
                                    &:last-of-type{
                                        display: none;
                                    }
                                }
                            }
                        }
                        .cart-minicart{
                            .scroll-area{
                                height: 250px;
                            }
                        }
                    }
                    .method-holder{
                        background: #FFFFFF;
                        box-shadow: 0px 4px 4px rgba(188, 188, 188, 0.15);
                        border-radius: 16px;
                        margin-bottom: 25px;
                        padding: 16px;
                        padding-top: 24px;
                        display: flex;
                        flex-direction: column;
                        .method-header{
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            position: relative;
                            justify-content: space-between;
                            cursor: pointer;
                            h2{
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;
                                margin: 0px;
                                svg{
                                    display: flex;
                                    margin-right: 10px;
                                }
                            }
                            &::after{
                                content: ' ';
                                display: flex;
                                position: absolute;
                                width: 20px;
                                height: 20px;
                                border-radius: 100%;
                                border: 2px solid #898989;
                                right: 0px;
                            }
                        }
                        .method-content{
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            height: 0px;
                            overflow: hidden;
                            transition: all 0.15s;
                            .method-block{
                                display: flex;
                                flex: 1;
                                flex-direction: column;
                                align-items: stretch;
                                h2{
                                    display: flex;
                                    width: 100%;
                                    font-size: 24px;
                                    margin-bottom: 20px;
                                    font-weight: 200;
                                }
                                .logos-holder{
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: center;
                                    width: 100%;
                                    margin-bottom: 20px;
                                    img{
                                        margin: 0px 10px;
                                    }
                                }
                                p{
                                    color: #898989;
                                    font-size: 14px;
                                    margin-bottom: 20px;
                                }
                                h3{
                                    font-size: 18px;
                                    margin-bottom: 20px;
                                    font-weight: 200;
                                }
                                .banks-holder{
                                    display: flex;
                                    flex-direction: column;
                                    width: calc(100% - 60px);
                                    padding: 0px 30px;
                                    .bank-row{
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        justify-content: space-between;
                                        margin-bottom: 20px;
                                        img{

                                        }
                                        span{
                                            font-size: 18px;
                                            font-weight: 200;
                                        }
                                    }
                                }
                                .gray-box{
                                    display: flex;
                                    flex-direction: column;
                                    background-color: #F9FCFE;
                                    padding: 18px 18px;
                                    border-radius: 16px;
                                    h2{
                                        font-size: 18px;
                                        font-weight: 400;
                                        margin-bottom: 15px;
                                    }
                                    p{
                                        font-weight: 200;
                                        margin-bottom: 15px;
                                        &:last-child{
                                            margin-bottom: 0px;
                                        }
                                    }
                                }
                                // Depósito y/o transferencia
                                .deposit-holder{
                                    display: flex;
                                    flex-direction: row;
                                    flex: 1;
                                    align-items: center;
                                    justify-content: space-between;
                                    flex-wrap: wrap;
                                    margin-bottom: 10px;
                                    padding-right: 30px;
                                    img{
                                        display: flex;
                                        margin-bottom: 15px;
                                    }
                                    .deposit-row{
                                        display: flex;
                                        flex-direction: row;
                                        justify-content: space-between;
                                        width: 100%;
                                        align-items: center;
                                        margin-bottom: 10px;
                                        p{
                                            display: flex;
                                            margin: 0px;
                                        }
                                        span{
                                            display: flex;
                                            margin: 0px;
                                            margin-right: 10px;
                                        }
                                        button{

                                        }
                                    }
                                }
                                // Conekta

                                .inputs-row{
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                    .custom-input{
                                        margin-right: 20px;
                                        &:last-child{
                                            margin-right: 0px;
                                        }
                                        label{
                                            font-size: 14px;
                                            font-weight: 300;
                                            color: unset;
                                        }
                                        .field-wrapper{
                                            border: 1px solid #C4C4C4;
                                            border-radius: 29px;
                                            background: #F9FCFE;
                                        }
                                    }
                                }
                                .custom-checkbox{
                                    margin: 10px 0px 15px 0px;
                                }
                                input[type="checkbox"]{
                                    + .custom-input{
                                        display: none;
                                        label{
                                            font-size: 14px;
                                            font-weight: 300;
                                            color: #007FC7;
                                        }
                                        .field-wrapper{
                                            border: 1px solid #C4C4C4;
                                            border-radius: 29px;
                                            background: #F9FCFE;
                                        }
                                    }
                                    &:checked{
                                        + .custom-input{
                                            display: flex;
                                        }
                                    }
                                }
                            }
                        }
                        &.chosen{
                            .method-header{
                                &::after{
                                    border: 2px solid #007FC7;
                                }
                                &::before{
                                    content: ' ';
                                    width: 14px;
                                    height: 14px;
                                    position: absolute;
                                    background-color: #007FC7;
                                    right: 5px;
                                    border-radius: 100%;
                                }
                            }
                            .method-content{
                                height: auto;
                                margin-top: 30px;
                            }
                        }
                    }
                }
            }
            &.cart-step-4{
                section{
                    &:first-child{
                        background-color: #FFF;
                        .cart-stepper{
                            .current{
                                background-color: #34C240;
                                &::before{
                                    background-color: #1B97D5;
                                }
                            }
                        }
                    }
                    &:last-child{
                        .cart-summary{
                            article{
                                &:nth-of-type(1),
                                &:nth-of-type(2){
                                    display: none;
                                }
                            }
                            .btn{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        // CART EMPTY
        .cart-empty{
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #FFF;
            width: 100%;
            padding-top: 40px;
            padding-bottom: 40px;
            //overflow-y: auto;
            //overflow-x: hidden;
            overflow: hidden;
            .cart-empty-message{
                display: flex;
                flex-direction: column;
                align-items: center;
                max-width: 600px;
                @include side-paddings();
                h1{
                    font-size: 24px;
                    margin-bottom: 20px;
                    text-align: center;
                }
                h2{
                    font-size: 18px;
                    line-height: 26px;
                    text-align: center;
                    margin-bottom: 40px;
                }
                .btn{
                    margin-bottom: 120px;
                    height: 58px;
                    width: 50%;
                }
                .search-keyword{
                    width: 100%;
                }
            }
            .shuffled-products{
                display: flex;
                flex-direction: column;
                width: 100%;
                justify-content: center;
                .slide-title{
                    font-size: 20px;
                    text-align: center;
                    margin-bottom: 40px;
                }
                .holder{
                    width: 100%;
                    .slide-item{
                        padding: 15px;
                        max-width: calc(20vw - 30px);
                        width: unset;
                    }
                }
            }
        }
    }

    // PROMO APPLIER
    .promo-applier{
        background-color: #FFF1EB;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        padding: 24px 32px;
        max-width: 280px;
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2{
            color: #EB6B2F;
            font-size: 24px;
            line-height: 36px;
            font-weight: 400;
            text-align: center;
            margin-bottom: 15px;
        }
        p{
            color: #505050;
            font-weight: 200;
            line-height: 22px;
            margin-bottom: 15px;
        }
        .custom-input{
            label{
                font-size: 14px;
            }
            .field-wrapper{
                border-color: #C4C4C4;
                height: 50px;
                border-radius: 26px;
                background-color: #F9FCFE;
                input{
                    text-align: center;
                    text-transform: uppercase;
                }
            }
        }
        .btn{
            height: 50px;
        }
    }

    // Business
    #business{
        padding-top: $header-height;
        .business-banner{
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 500px;
            background-image: url('@Assets/business/banner.jpg');
            background-repeat: no-repeat;
            //background-position: center -160px;
            background-size: cover;
            padding: 0px 100px;
            justify-content: flex-end;
            h2, h3{
                display: flex;
                font-size: 80px;
                color: #EB6B2F;
                line-height: 60px;
                font-weight: 100;
                width: 520px;
            }
            h3{
                color: #505050;
                text-align: right;
                margin-top: 0px;
            }
        }
        .business-why{
            display: flex;
            flex-direction: column;
            padding: 60px 100px;
            align-items: center;
            h2{
                font-size: 32px;
                font-weight: 200;
                margin-bottom: 50px
            }
            .why-block{
                display: flex;
                flex-direction: row;
                justify-content: center;
                gap: 60px;
                section{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 25%;
                    padding: 0px 50px;
                    img{
                        display: flex;
                        margin-bottom: 30px;
                        height: 80px;
                    }
                    p{
                        display: flex;
                        font-size: 24px;
                        font-weight: 200;
                        text-align: center
                    }
                }
            }
        }
        .business-calculator{
            background-color: #EB6B2F;
            @include side-paddings();
            padding-top: 24px;
            padding-bottom: 24px;
            color: #fff;
            display: none;
            flex-direction: row;
            justify-content: center;
            gap: 150px;
            font-weight: 200;
            align-items: center;
            section{
                width: 550px;
                display: flex;
                flex-direction: column;
                h2{
                    font-size: 80px;
                    margin-bottom: 24px;
                }
                p{
                    font-size: 18px;
                    line-height: 26px;
                    margin-bottom: 30px;
                }
            }
            .download-area{
                display: flex;
                flex-direction: column;
                h3{
                    font-size: 24px;
                    margin-bottom: 16px;
                }
                .btn{
                    height: 58px;
                    border-radius: 29px;
                    width: 100%;
                    color: #505050;
                    text-decoration: none;
                }
            }
            aside{
                width: 420px;
                background: #EF763D;
                border-radius: 24px;
                padding: 24px;
                display: flex;
                flex-direction: column;
                h2{
                    text-align: center;
                    font-size: 32px;
                    margin-bottom: 18px;
                }
                fieldset{
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 10px;
                    label{
                        font-size: 14px;
                        font-weight: 300;
                        margin-bottom: 10px;
                    }
                    input{
                        border: 1px solid #EB6B2F;
                        border-radius: 29px;
                        height: 50px;
                        background-color: #FFF;
                        text-align: center;
                        font-size: 32px;
                        color: #505050;
                    }
                }
                p{
                    font-size: 18px;
                    line-height: 26px;
                    margin-bottom: 10px;
                    b{
                        font-weight: 500;
                    }
                }
                big{
                    display: flex;
                    justify-content: center;
                    font-size: 80px;
                    margin-bottom: 20px;
                }
                small{
                    line-height: 22px;
                }
                & + .download-area{
                    display: none;
                }
            }
        }
        .business-start{
            background-color: #F9FCFE;
            display: flex;
            flex-direction: column;
            padding: 34px 100px;
            font-weight: 200;
            h2{
                font-size: 32px;
                margin-bottom: 16px;
            }
            h3{
                font-size: 18px;
                margin-bottom: 40px;
            }
            .blocks{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: 120px;
                section{
                    display: flex;
                    flex-direction: column;
                    width: 25%;
                    .icon-holder{
                        align-self: center;
                        position: relative;
                        margin-bottom: 26px;
                        &::after{
                            content: ' ';
                            position: absolute;
                            width: 56px;
                            height: 56px;
                            border-radius: 100%;
                            background-color: #FFF1EB;
                            left: 5px;
                            top: 5px;
                        }
                        svg{
                            position: relative;
                            z-index: 1;
                        }
                    }
                    h2{
                        font-size: 24px;
                        font-weight: 400;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        b{
                            background-color: #EB6B2F;
                            color: #FFF;
                            display: flex;
                            width: 32px;
                            height: 32px;
                            align-items: center;
                            justify-content: center;
                            border-radius: 100%;
                            margin-right: 10px;
                            font-size: 18px;
                        }
                    }
                    p{
                        font-size: 18px;
                        line-height: 26px;
                    }
                }
            }
        }
        .business-reasons{
            padding: 64px 100px;
            font-weight: 200;
            display: flex;
            flex-direction: column;
            h2{
                font-size: 32px;
                margin-bottom: 16px;
            }
            h3{
                font-size: 18px;
                margin-bottom: 32px;
            }
            .blocks{
                display: flex;
                flex-direction: row;
                gap: 120px;
                section{
                    width: 25%;
                    h2{
                        font-size: 24px;
                        font-weight: 300;
                        margin-bottom: 8px;
                    }
                    p{
                        font-size: 18px;
                        line-height: 26px;
                    }
                }
            }
        }
        .business-subanner{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;
            align-items: center;
            font-weight: 200;
            &::after{
                content: ' ';
                position: absolute;
                z-index: 0;
                background: linear-gradient(269.07deg, #F9FCFE 60%, rgba(255, 255, 255, 0) 100%);
                transform: rotate(-180deg);
                top: 0px;
                left: 0px;
                width: 100%;
                height: 100%;
            }
            .texts{
                position: relative;
                z-index: 1;
                display: flex;
                flex-direction: column;
                padding-left: 100px;
                width: 35rem;
                h2{
                    font-size: 32px;
                    margin-bottom: 16px;
                    font-weight: 300;
                }
                p{
                    font-size: 18px;
                    line-height: 27px;
                    margin-bottom: 32px
                }
                .buttons-holder{
                    display: flex;
                    flex-direction: row;
                    gap: 30px;
                    .btn{
                        height: 58px;
                        border-radius: 29px;
                        font-weight: 300;
                    }
                }
            }
            img{

            }
        }
    }

    // FAQ's
    #faqs{
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: $header-height;
        flex-wrap: wrap;
        @include side-paddings();
        .search-area{
            padding: 25px 0px;
            align-items: center;
            width: 100%;
            display: flex;
            flex-direction: column;
            .content{
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 600px;
                font-weight: 200;
                h1{
                    font-size: 32px;
                    margin-bottom: 15px;
                }
                p{
                    font-size: 18px;
                    line-height: 26px;
                    text-align: center
                }
                fieldset{
                    border: 1px solid #C4C4C4;
                    border-radius: 29px;
                    background: #F9FCFE;
                    display: flex;
                    flex-direction: row;
                    width: 80%;
                    align-self: center;
                    margin: 20px 0px;
                    input{
                        background-color: transparent;
                        border: none;
                        width: 100%;
                        padding: 0px 20px;
                    }
                    .btn{
                        width: 138px;
                    }
                }
            }
        }
        .questions-area{
            display: flex;
            flex-direction: row;
            position: relative;
            width: 100%;
            aside{
                display: flex;
                flex-direction: column;
                padding-right: 50px;
                flex-basis: 25rem;
                h2{
                    font-size: 24px;
                    margin-bottom: 42px;
                }
                .group{
                    display: flex;
                    flex-direction: column;
                    h3{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        font-size: 18px;
                        font-weight: 400;
                        margin: 20px 0px;
                        svg{
                            margin-right: 10px;
                        }
                    }
                    .q-item{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        border-bottom: 1px solid #F2F2F2;
                        padding: 10px 0px;
                        justify-content: space-between;
                        line-height: 24px;
                        cursor: pointer;
                        &:last-child{
                            margin-bottom: 30px;
                        }
                        &.current{
                            font-weight: 400;
                            svg{
                                color: #007FC7 !important;
                            }
                        }
                    }
                    .a-item{
                        display: none;
                    }
                }
            }
            .answer-holder{
                position: sticky;
                background: #FFFBFA;
                padding: 40px 32px;
                max-height: 350px;
                margin-top: 110px;
                top: 140px;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                h4{
                    font-size: 24px;
                    margin-bottom: 40px;
                    display: flex;
                    text-align: left;
                    width: 100%;
                }
                p{
                    font-size: 18px;
                    line-height: 26px;
                    margin-bottom: 20px;
                    text-align: left;
                    width: 100%;
                    a{
                        color: #007FC7;
                        font-size: 18px;
                        text-decoration: underline;
                    }
                }
                .contact-holder{
                    margin-top: 40px;
                    width: 300px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    h2{
                        font-size: 26px;
                        line-height: 32px;
                        font-weight: 200;
                        text-align: center;
                        margin-bottom: 10px;
                    }
                    .whatsapp{
                        height: 58px;
                        color: #FFF;
                        background-color: #34C240;
                        border-radius: 29px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        text-decoration: none;
                        svg{
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }

    // SUCURSALES
    #branches{
        padding-top: $header-height;
        display: flex;
        flex-direction: column;
        @include side-paddings();
        padding-bottom: 80px;
        h1{
            font-size: 32px;
            margin: 25px 0px 25px 0px;
        }
        .content{
            display: flex;
            flex-direction: row;
            aside{
                width: 25rem;
                section{
                    display: flex;
                    flex-direction: column;
                    h2{
                        display: flex;
                        flex-direction: row;
                        background-color: #EB6B2F;
                        color: #fff;
                        height: 40px;
                        font-size: 18px;
                        align-items: center;
                        padding: 0px 30px;
                        font-weight: 500;
                        svg{
                            margin-right: 10px;
                        }
                    }
                    address{
                        background: #FFFBFA;
                        padding: 20px;
                        cursor: pointer;
                        position: relative;
                        &::after{
                            content: ' ';
                            height: 1px;
                            background-color: #FFF1EB;
                            width: 90%;
                            position: absolute;
                            bottom: 0px;
                        }
                        &:last-child{
                            &::after{
                                display: none;
                            }
                        }
                        h3{
                            font-size: 16px;
                            font-weight: 300;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 12px;
                            transition: all 0.35s;
                            svg{
                                transition: all 0.35s;
                            }
                        }
                        p{
                            display: flex;
                            font-size: 14px;
                            line-height: 18px;
                            font-weight: 200;
                        }
                        .btn{
                            display: none;
                        }
                        &.selected{
                            h3{
                                font-weight: 500;
                                svg{
                                    color: #FF7433 !important;
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                }
            }
            .map-holder{
                width: 80rem;
                display: flex;
                flex-direction: column;
                .map-info-window{
                    max-width: 150px;
                }
                .select-branch{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding: 20px 40px;
                    border-radius: 5px;
                    width: 400px;
                    align-self: center;
                    background-color: #FCFCFC;
                    h2{
                        font-family: 18px;
                        margin-top: 20px;
                        text-align: center;
                        color: #898989;
                        font-weight: 200;
                    }
                }
            }
        }
    }

    // REGISTER
    #register{
        background-color: #f5f9fa;
        padding-top: $header-height;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        h1{
            width: 100%;
            font-size: 24px;
            margin-bottom: 20px;
            margin-top: 40px;
        }
        .content{
            @include content-width();
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 4%;
            justify-content: space-between;
            .column{
                width: 46%;
                p{
                    font-size: 18px;
                    line-height: 24px;
                }
                .form-area{
                    padding: 40px 30px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    gap: 4%;
                    margin-bottom: 30px;
                    .custom-input{
                        width: 46%;
                        .field-wrapper{
                            border-color: #C4C4C4;
                            background-color: #F5F9FA;
                            border-radius: 20px;
                        }
                    }
                }
                .button-holder{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    margin-top: 20px;
                    .btn{
                        display: flex;
                        width: 70%;
                        height: 50px;
                    }
                }
                legend{
                    font-size: 14px;
                    margin-bottom: 15px;
                    font-weight: 300;
                    a{
                        color: #007FC7;
                        text-decoration: none;
                    }
                }
            }
        }
    }
    // REGISTER SUCCESS
    .register-success{
        padding-top:  calc(#{$header-height} + 40px);
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        .content{
            @include content-width();
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            .column{
                h2{
                    color: #001427;
                    font-size: 24px;
                    margin: 24px 0px
                }
                p{
                    font-size: 18px;
                    line-height: 24px;
                    width: 500px;
                    margin-bottom: 80px;
                }
                b{
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    font-weight: 400;
                    margin-bottom: 10px;
                }
                a{
                    color: #1B97D5;
                    text-decoration: none;
                }
                img{}
            }
        }
    }


    // FORGOT PASSWORD
    .forgot-password{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #FFF;
        width: 340px;
        padding: 40px 20px;
        border-radius: 20px;
        h2{
            font-size: 24px;
            margin-bottom: 15px;
        }
        p{
            font-size: 14px;
            text-align: center;
            margin-bottom: 40px;
        }
        .custom-input{
            label{
                text-align: center;
            }
            .field-wrapper{
                background-color: #F9FCFE;
                border-radius: 20px;
                border-color: #C4C4C4;
                input{
                    text-align: center;
                }
            }
        }
        .btn{
            height: 50px;
        }
    }

    // VERIFY ACCOUNT:
    .verify-account{
        padding-top: $header-height;
        background-color: #F5F9FA;
        display: flex;
        flex-direction: row;
        justify-content: center;
        .content{
            @include content-width();
            padding-top: 40px;
            padding-bottom: 40px;
            #loading{
                justify-content: flex-start;
                width: 32px;
                flex: unset;
            }
            h1{
                font-size: 24px;
                margin-bottom: 20px;
                display: flex;
                flex-direction: row;
                align-items: center;
                span{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-left: 10px;
                }
            }
            p{
                font-size: 18px;
            }
        }
    }

    // RECOVER PASSWORD
    .recover-password{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: calc(#{$header-height} + 40px) 0px 40px 0px;
        background-color: #f5f9fa;
        .box{
            width: 320px;
            padding: 30px 20px;
            h1{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 30px;
                font-size: 20px;
                svg{
                    margin-right: 10px;
                }
            }
            .custom-input{
                .field-wrapper{
                    border-radius: 20px;
                    input{

                    }
                }
            }
            .buttons-holder{
                margin-top: 20px;
                .btn{
                    height: 50px;
                    &.link{
                        height: 20px;
                    }
                }
            }
        }
    }

    // GUEST USER
    .guest-user{
        max-width: 420px;
        padding: 30px 20px;
        text-align: center;
        h2{
            font-weight: 400;
            font-size: 18px;
            margin-bottom: 20px;
        }
        p{
            font-weight: 200;
            font-size: 14px;
            margin-bottom: 40px;
        }
        .custom-input{
            .field-wrapper{
                border-radius: 20px;
                background-color: #F2F2F2;
                border-color: #C4C4C4;
                input{
                }
            }
        }
        .btn-holder{
            margin-top: 30px;
            .btn{
                height: 50px;
            }
        }
    }

    // DETALLE EN POPUP
    .pop-detail{
        background-color: #FFF;
        width: 100%;
        height: 100%;
        overflow-x: hidden;
        max-height: 100vh;
        position: relative;
        .close-btn{
            top: 10px;
            right: 10px;
            padding: 0px;
            position: fixed;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            z-index: 9999;
            box-shadow: 0px 2px 6px rgba(80, 80, 80, 0.24);
            border-radius: 100%;
            svg{
                background-color: #F2F2F2;
                padding: 10px;
                width: 18px;
                height: 18px;
                border-radius: 100%;
            }
        }
        #detail{
            padding-top: 20px;
        }
    }

    // FOOTER
    footer{
        .footer-perks{
            .title{
                display: none
            }
            @include side-paddings();
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 40px;
            padding-top: 56px;
            padding-bottom: 56px;
            section{
                display: flex;
                flex-direction: column;
                width: 25%;
                position: relative;
                &::after{
                    content: ' ';
                    position: absolute;
                    top:0px;
                    width: 1px;
                    background-color: #f2f2f2;
                    right: -10px;
                    height: 100%;
                }
                &:last-child{
                    &::after{
                        display: none;
                    }
                }
                h2{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    font-weight: 500;
                    font-size: 1.6vw;
                    margin-bottom: 14px;
                    svg{
                        margin-right: 20px;
                    }
                }
                p{
                    font-size: 1vw;
                    line-height: 24px;
                    margin-bottom: 15px;
                }
                small{
                    font-size: 0.9vw;
                    color: #EB6B2F;
                }
                .btn{
                    padding: 0px;
                    justify-content: flex-start;
                    height: unset;
                    margin: 0px;
                    font-size: 1vw;
                }
            }
        }
        .footer-sitemap{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            @include side-paddings();
            padding-bottom: 40px;
            padding-right: 0px;
            nav{
                display: flex;
                flex-direction: column;
                img{
                    display: flex;
                    margin-bottom: 20px;
                }
                h2{
                    font-weight: 700;
                    font-size: 1.4vw;
                    margin-bottom: 16px;
                    margin-top: 30px;
                }
                section{
                    display: flex;
                    flex-direction: column;
                    a{
                        color: #898989;
                        text-decoration: underline;
                        margin-bottom: 10px;
                        cursor: pointer;
                        font-size: 1vw;
                    }
                }
                &:first-child{
                    h2{
                        display: none;
                    }
                }
            }
        }
        .footer-copyright{
            background-color: #EB6B2F;
            height: 32px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: #FFF;
            font-weight: 200;
            font-size: 1vw;
        }

        .contact-area{
            display: flex;
            flex-direction: column;
            h3{
                font-size: 16px;
                font-weight: 400;
                margin-top: 20px;
            }
            .phone{
                color: #0976AD;
                margin-top: 20px;
            }
            .social-links{
                margin-top: 10px;
                display: flex;
                flex-direction: row;
                gap: 30px;
                .social-link{}
            }
        }
    }
}


/****************************************
    MOBILE VERSION
****************************************/
@media screen and (max-width: 1080px) {
    body{
        // SIDE PADDINGS
        @mixin side-paddings{
            padding-left: 16px;
            padding-right: 16px;
        }

        // SIDE PANEL
        @keyframes slideFromRight {
            0% {
                transform: translateX(-200%);
            }
            100% {
                transform: translateX(0%);
            }
        }
        #side-panel{
            align-items: flex-start;
            .close-btn{
                display: flex !important;
                position: absolute;
                width: 35px;
                height: 35px;
                z-index: 1;
                right: 0px;
                top: 0px;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                padding: 0px;
                svg{
                    color: #CCC;
                }
            }
            .side-content{
                width: 100%;
            }
            .button-helper{
                display: none;
            }
            // PROMOS IN SIDE PANEL
            #promos-swiper{
                .slider{
                    .slide-item{
                        .card{
                            .img-holder{
                                width: 80px !important;
                                height: 60px !important;
                            }
                            .texts-holder{
                                width: calc(100% - 85px) !important;
                                h2{
                                    padding: 0px !important;
                                }
                                big{
                                    font-size: 30px !important;
                                }
                                span{
                                }
                            }
                        }
                    }
                }
            }

        }

        // MAIN
        main{
            max-height: -webkit-fill-available; // Works in iOS only
            position: relative;
            // When in cart
            &:has(.cart-template){
                overflow: hidden;
                #cta-whatsapp{
                    display: none;
                }
            }
        }
        @media only screen and (hover: none) and (pointer: coarse){
            &:not([data-browser="Safari"]){
                main{
                    max-height: calc(100vh - 60px);
                    @media all and (display-mode: standalone) {
                        max-height: calc(100vh - 0px);
                    }
                }
            }
        }

        // HEADER
        header{
            .gray-bar{
                padding: 0px;
                @include side-paddings();
                span{
                    font-size: 11px;
                }
                .buttons-holder{
                    display: none;
                }
            }
            nav{
                padding: 0px;
                justify-content: space-between;
                @include side-paddings();
                .logo-holder{
                    padding: 0px;
                    width: 40px;
                    background-image: url('@Assets/logo-mobile.png');
                    background-repeat: no-repeat;
                    background-position: center center;
                }
                section{
                    flex-direction: row;
                    justify-content: flex-end;
                    a{
                        display: none;
                    }
                    button{
                        margin-left: 30px;
                        padding: 0px;
                        &:nth-last-child(1),
                        &:nth-last-child(2),
                        &:nth-last-child(4){
                            display: flex;
                        }
                        &:first-of-type{
                            margin-left: 0px;
                        }
                    }
                }
            }
        }

        // HOME
        #home{
            .home-banners{
                .banner-item{
                    height: 550px; //225px;

                }
            }
            .home-special{
                flex-direction: column;
                .column{
                    &:first-child{
                        margin-bottom: 40px;
                        .btn{
                            width: 100%;
                        }
                    }
                    .random-products{
                        gap: 15px;
                        .product-holder{
                            width: 20vw;
                            height: 20vw;
                        }
                    }
                }
            }
            .home-promote{
                flex-direction: column;
                img{
                    width: 100%;
                    margin-bottom: 24px;
                }
                .texts{
                    width: calc(100% - 32px);
                    padding-bottom: 30px;
                    .btn{
                        width: 100%;
                    }
                }
            }
            .home-subanner{
                background-position: left bottom;
                align-items: unset;
                padding-top: 40px;
                height: unset;
                background-size: cover;
                &::before{
                    background: linear-gradient(180deg, #FFFFFF 30%, rgba(255, 255, 255, 0) 68.63%);
                }
                .texts{
                    width: 100%;
                    margin-bottom: 50px;
                }
            }
            .home-categories{
                .slider{
                    .swiper-wrapper{
                        .item{
                            .category-item{
                                width: 90px !important;
                                height: 90px !important;
                            }
                        }
                    }
                }
            }
            .home-promo{
                display: none;
            }
        }

        // ABOUT
        #about{
            .about-banners{
                .banner-item{
                    flex-direction: column;
                    height: unset;
                    background: linear-gradient(180deg, rgba(241,237,236,1) 30%, rgba(255,255,255,1) 80%);
                    .img-holder{
                        width: 100%;
                        height: 400px;
                        background-size: cover;
                        position: relative;
                        &::after{
                            content: ' ';
                            position: absolute;
                            left: 0px;
                            right: 0px;
                            bottom: 0px;
                            width: 100%;
                            height: 100%;
                            background: linear-gradient(360deg, #f1edec 1%, #FFFFFF01 50%);
                        }
                    }
                    .texts{
                        @include side-paddings();
                        width: unset;
                        margin: 0px;
                        h1{
                            font-size: 56px;
                            margin-top: 10px;
                            letter-spacing: -0.2rem;
                            height: 85px;
                            &::after{
                                left: 5%;
                                top: 40%;
                            }
                        }
                        h3{
                            font-size: 24px;
                            margin-bottom: 25px;
                        }
                        p{
                            font-size: 16px;
                        }
                    }
                }
            }
            .about-vmv{
                flex-direction: column;
                width: auto;
                gap: 20px;
                .item{
                    flex-direction: row;
                    align-items: flex-start;
                    justify-content: flex-start;
                    width: auto;
                    position: relative;
                    img{
                        height: unset;
                        width: 60px;
                    }
                    .texts{
                        padding-left: 30px;
                        align-items: flex-start;
                        h2{
                            font-size: 24px;
                        }
                        p{
                            font-size: 16px;
                            line-height: 20px;
                            text-align: left;
                        }
                    }
                }
            }
            .about-subanner{
                flex-direction: column-reverse;
                align-items: flex-end;
                &::after{
                    display: none;
                }
                img{
                    width: 100%;
                    position: relative;
                }
                .texts{
                    padding: 20px 40px;
                    h2{
                        font-size: 32px;
                        margin-bottom: 10px;
                    }
                    h1{
                        font-size: 42px;
                        font-weight: 200;
                    }
                    p{
                        font-size: 16px;
                        line-height: 20px;
                        padding: unset;
                    }
                    h3{
                        font-size: 28px;
                        line-height: 36px;
                        text-align: center;
                    }
                }
            }
            .about-punch{
                @include side-paddings();
                flex-direction: column-reverse;
                padding-bottom: 30px;
                padding-top: 0px;
                background-color: transparent;
                gap: 30px;
                .likeit{
                    width: unset;
                    h2{
                        font-size: 24px;
                        text-align: center;
                    }
                    h3{
                        font-size: 16px;
                        line-height: 20px;
                    }
                    .btn{
                        width: 100%;
                    }
                }
                .closer{
                    width: unset;
                    h2{
                        font-size: 32px;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 18px;
                        line-height: 24px;
                    }
                    .city-item{
                        big{
                            font-size: 24px;
                        }
                        a{
                            font-size: 14px;
                        }
                    }
                }
            }
        }

        // CLOSER STORE
        #closer-store-warning{
            font-size: 12px;
            padding: 0px;
            @include side-paddings();
            padding-right: 30px;
            b{
                display: contents;
                width: auto;
                margin: 0px 2px;
            }
            button{
                right: 16px;
            }
        }

        // Business
        #business{
            .business-banner{
                padding: 0px;
                @include side-paddings();
                background-position: top center;
                height: 300px;
                h2{
                    width: unset;
                    font-size: 52px;
                }
                h3{
                    width: unset;
                    font-size: 52px;
                }
            }
            .business-why{
                padding: 20px 0px;
                @include side-paddings();
                h2{
                    font-size: 26px;
                    text-align: center;
                    margin-bottom: 30px;
                }
                .why-block{
                    flex-direction: column;
                    gap: 10px;
                    section{
                        width: unset;
                        padding: 0px;
                        flex-direction: row;
                        align-items: center;
                        border-bottom: 1px solid #F2F2F2;
                        padding-bottom: 5px;
                        margin-bottom: 5px;
                        &:last-child{
                            border-bottom: none;
                        }
                        img{
                            width: 35px;
                            height: unset;
                            margin-bottom: 0px;
                        }
                        p{
                            font-size: 16px;
                            line-height: 20px;
                            padding-left: 20px;
                            text-align: left;
                        }
                    }
                }
            }
            .business-calculator{
                flex-direction: column;
                padding: 20px 0px;
                gap: unset;
                @include side-paddings();
                section{
                    width: unset;
                    padding-bottom: 0px;
                    h2{
                        font-size: 40px;
                        text-align: center;
                    }
                    p{
                        margin-bottom: 0px;
                    }
                }
                .download-area{
                    display: none;
                }
                aside{
                    width: auto;
                    padding: 20px 0px;
                    margin-top: 20px;
                    width: 95%;
                    @include side-paddings();
                    h2{
                        font-size: 24px;
                    }
                    fieldset{
                        input{
                            width: calc(100% - 4px);
                            font-size: 24px;
                        }
                    }
                    p{
                        font-size: 14px;
                        line-height: 20px;
                    }
                    big{
                        font-size: 60px;
                    }
                    small{
                        font-size: 14px;
                        line-height: 18px;
                    }
                    & + .download-area{
                        display: flex;
                        margin-top: 20px;
                        text-align: center;
                        h3{
                            font-size: 18px;
                        }
                    }
                }
            }
            .business-start{
                padding: 30px 0px;
                @include side-paddings();
                background-color: transparent;
                h2{
                    font-size: 24px;
                }
                h3{
                    font-size: 16px;
                    line-height: 20px;
                }
                .blocks{
                    gap: 20px;
                    flex-direction: column;
                    section{
                        width: unset;
                        background-color: #F9FCFE;
                        border-radius: 24px;
                        padding: 20px 10px;
                        .icon-holder{
                            margin-bottom: 10px;
                            svg{
                                width: 30px;
                            }
                            &::after{
                                width: 40px;
                                height: 40px;
                            }
                        }
                        h2{
                            font-size: 18px;
                            margin-bottom: 5px;
                            b{
                                width: 22px;
                                height: 22px;
                                font-size: 14px;
                            }
                        }
                        p{
                            font-size: 14px;
                            line-height: 18px;
                        }
                    }
                }
            }
            .business-reasons{
                display: none;
            }
            .business-subanner{
                flex-direction: column-reverse;
                &::after{
                    background: linear-gradient(269.07deg, #F9FCFE 0%, rgba(255, 255, 255, 0) 40%);
                }
                img{
                    width: 100%;
                }
                .texts{
                    width: auto;
                    padding: 20px 0px;
                    @include side-paddings();
                    h2{
                        font-size: 24px;
                    }
                    p{
                        font-size: 16px;
                        line-height: 20px;
                    }
                    .buttons-holder{
                        flex-direction: column;
                        gap: 20px;
                    }
                }
            }
        }

        // SUCURSALES
        #branches{
            padding: 30px 0px;
            padding-top: $header-height;
            h1{
                text-align: center;
                font-size: 22px;
                line-height: 28px;
                @include side-paddings();
            }
            .content{
                flex-direction: column-reverse;
                aside{
                    width: 100%;
                    section{
                        address{
                            h3{
                                svg{
                                    display: none;
                                }
                            }
                            .btn{
                                display: flex;
                                float: right;
                                width: 130px;
                                height: 30px;
                                margin-top: 10px;
                            }
                        }
                    }
                }
                .map-holder{
                    width: 100%;
                    height: 250px;
                    .map-info-window{
                    }
                }
            }
        }

        // FAQ's
        #faqs{
            padding: 0px;
            padding-top: $header-height;
            .search-area{
                width: 100%;
                @include side-paddings();
                .content{
                    width: unset;
                    h1{
                        font-size: 24px;
                    }
                    p{
                        font-size: 14px;
                        line-height: 18px;
                    }
                    fieldset{
                        width: 100%;
                        input{
                            width: calc(100% - 40px);
                        }
                    }
                }
            }
            .questions-area{
                flex-direction: column;
                width: 100%;
                aside{
                    width: auto;
                    padding: 0px;
                    h2{
                        font-size: 20px;
                        margin-bottom: 20px;
                        @include side-paddings();
                    }
                    .group{
                        h3{
                            font-size: 16px;
                            @include side-paddings();
                            margin-top: 20px;
                        }
                        .q-item{
                            @include side-paddings();
                            border-bottom: none;
                            &.current{
                                background: #FFFBFA;
                                &+.a-item{
                                    background: #FFFBFA;
                                    display: flex;
                                    padding: 0px 20px 20px 20px;
                                    @include side-paddings();
                                    font-size: 14px;
                                    line-height: 18px;
                                    p{
                                        text-align: justify;
                                    }
                                }
                            }
                        }
                    }
                }
                .answer-holder{
                    top: unset;
                    margin-top: unset;
                    width: auto;
                    padding: 40px 10px;
                    min-height: unset;
                    background-color: transparent;
                    h4, p{
                        display: none;
                    }
                    .contact-holder{
                        padding: 0px;
                        margin: 0px;
                    }
                }
            }
        }

        // CATALOG
        #catalog{
            padding-bottom: 120px;
            .filters-and-sorters{
                z-index: 10;
                .content{
                    @include side-paddings();
                    .other-filters{
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        gap: 20px;
                        height: 34px;
                        a{
                            font-size: 14px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: flex-start;
                            margin-bottom: 10px;
                            padding: 0px 10px;
                            background-color: #007FC7;;
                            border-radius: 29px;
                            color: #fff;
                            padding: 0px 15px;
                            font-size: 12px;
                            height: 34px;
                            svg{
                                color: #fff;
                                margin-right: 5px;
                            }
                        }
                    }
                    .filters-holder{
                        h2{
                            cursor: pointer;
                            border: 1px solid #1289C5;
                            border-radius: 29px;
                            color: #1289C5;
                            font-size: 16px;
                            height: 40px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            width: 42vw;
                            svg{
                                display: flex;
                                margin-left: 10px;
                            }
                            &.active-filters{
                                background-color: #007FC7;
                                color: #FFF;
                                svg{
                                    color: #FFF !important;
                                }
                            }
                        }
                        .overlay{
                            position: fixed;
                            width: 100%;
                            height: 100%;
                            top: 0px;
                            left: 0px;
                            bottom: 0px;
                            right: 0px;
                            background-color: rgba(0,0,0,0.75);
                            z-index: 1;
                            .close-btn{
                                width: 30px;
                                height: 30px;
                                position: absolute;
                                right: 10px;
                                top: 5px;
                                svg{
                                    color: #CCC;
                                }
                            }
                        }
                        .filterable-items{
                            @include slide-bottom-panel();
                            &.open{
                                height: calc(100vh - 230px);
                                bottom: 0PX;;
                            }
                            &::before{
                                content: 'Filtrar resultados';
                                position: absolute;
                                top: 45px;
                                left: 0px;
                                right: 0px;
                                text-align: center;
                                font-size: 18px;
                            }
                            .overlay-layer{
                                display: none;
                            }
                            .overflow{
                                display: flex;
                                flex-direction: column;
                                height: calc(100vh - 400px);
                                overflow-y: auto;
                                overflow-x: hidden;
                                .item{
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: space-between;
                                    width: auto;
                                    margin: 0px;
                                    flex-wrap: wrap;
                                    margin-bottom: 20px;
                                    button{
                                        display: flex;
                                        flex-direction: row;
                                        //justify-content: space-between;
                                        width: 100vw;
                                        height: 40px;
                                        border-bottom: 1px solid #F2F2F2;
                                        @include side-paddings();
                                        span{
                                            display: flex;
                                            flex-direction: row;
                                            font-size: 18px;
                                        }
                                        svg{
                                            margin: 0px;
                                            position: absolute;
                                            right: 10px;
                                            transition: all 0.35s;
                                        }
                                    }
                                    .box{
                                        position: unset;
                                        top: unset;
                                        border-radius: unset;
                                        box-shadow: unset;
                                        max-height: unset;
                                        width: 100%;
                                        .custom-checkbox{
                                            border-bottom: none;
                                            font-size: 14px;
                                            &:nth-of-type(2n){
                                                background-color: #F9FCFE;
                                            }
                                        }
                                    }
                                    &.open{
                                        button{
                                            font-weight: 400;
                                            svg{
                                                transform: rotate(90deg);
                                            }
                                        }
                                    }
                                }
                                .linex-holder{
                                    display: flex;
                                    align-self: center;
                                    @include side-paddings();
                                    label{
                                        display: flex;
                                        flex-direction: row;
                                        align-items: center;
                                        span{
                                            margin-left: 10px;
                                        }
                                    }
                                }
                            }
                            .buttons-mobile{
                                @include side-paddings();
                                display: flex;
                                flex-direction: column;
                                margin-top: 20px;
                                .primary{
                                    height: 50px;
                                }
                            }
                        }
                    }
                    .sorters-holder{
                        .overlay-layer{
                            &::after{
                                position: absolute;
                                content: '✕';
                                height: 20px;
                                position: absolute;
                                right: 10px;
                                top: 5px;
                                color: #CCC;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;
                                font-size: 20px;
                                line-height: 20px;
                                vertical-align: middle;
                            }
                            .close-btn{
                                width: 30px;
                                height: 30px;
                                position: absolute;
                                right: 10px;
                                top: 5px;
                                svg{
                                    color: #CCC;
                                }
                            }
                        }
                        .item{
                            display: flex;
                            margin: 0px;
                            button{
                                cursor: pointer;
                                border: 1px solid #1289C5;
                                border-radius: 29px;
                                color: #1289C5;
                                font-size: 18px;
                                height: 40px;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                justify-content: center;
                                width: 42vw;
                                text-align: center;
                                svg{
                                    display: none;
                                    &:nth-of-type(2){
                                        display: flex;
                                        margin-left: 5px;
                                    }
                                }
                                span{
                                    font-size: 16px;
                                }
                            }
                            // SIDE PANEL
                            @keyframes showBoxBottomTop {
                                0% {
                                    transform: translateY(200%);
                                }
                                100% {
                                    transform: translateY(0%);
                                }
                            }
                            .box{
                                @include slide-bottom-panel();
                                animation: 0.15s ease-in-out 0s 1 showBoxBottomTop;
                                bottom: 0px;
                                padding-top: 80px;
                                height: 300px;
                                top: unset;
                                &::before{
                                    content: 'Ordenar resultados';
                                    position: absolute;
                                    top: 45px;
                                    left: 0px;
                                    right: 0px;
                                    text-align: center;
                                    font-size: 18px;
                                }
                            }
                        }
                        .custom-radio{
                            //width: calc(100% - 28px);
                        }
                    }
                }
                .applied-filters{
                    display: none;
                }
            }
            .catalog-items{
                @include side-paddings();
                display: grid;
                gap: 0.75rem;
                grid-template-columns: repeat(auto-fit, minmax(48%, 0.2fr));
                padding-top: 40px;
                padding-bottom: 20px;
            }
            .load-more-holder{
                margin-bottom: 40px;
            }
            // EMPTY
            .catalog-empty{
                width: auto;
                @include side-paddings();
                text-align: center;
            }
            .catalog-end{
                //display: none;
                img{
                    width: 120px;
                    height: 120px;
                }
                .btn{
                    font-size: 12px;
                }
            }
        }

        // PRODUCT COMPONENTS
        .product-gallery{
            display: flex;
            flex-direction: row;
            justify-content: center;
            overflow: hidden;
            img{
                display: flex;
            }
        }
        .product-discount{
            background-color: #EB6B2F;
            color: #FFF;
            height: 24px;
            width: 48px;
            text-align: center;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
        }
        .product-variants{
            overflow: hidden;
        }
        .product-prices{
            margin-bottom: 20px;
            big{
                font-size: 24px;
            }
            small{
                font-size: 14px;
            }
        }
        .product-hearth{
            width: 30%;
        }
        .product-texts{
            span{
                font-size: 12px;
            }
            h2{
                font-size: 12px;
                line-height: 16px;
                height: 30px;
            }
            h3{
                font-size: 12px;
                margin-bottom: 11px;
            }
            a{
                color: #1289C5;
                font-size: 14px;
                display: none;
            }
            .product-hearth{
                display: none;
            }
        }
        .product-increaser{
            .container{
                button{
                    svg{
                        width: 18px;
                        height: 18px;
                    }
                }
            }
        }
        .product-item{
            .product-increaser-holder{
                .product-increaser{
                    & + .product-buttons{
                        button{
                            padding: 0px;
                        }
                    }
                }
            }
        }

        // PRODUCT DETAIL
        #detail{
            .content{
                flex-direction: column;
                padding: 0px;
                max-width: calc(100vw - 32px);
                margin: 0px;
                @include side-paddings();
                .holder-gallery{
                    width: unset;
                }
                .holder-info{
                    width: unset;
                    .product-prices{
                        justify-content: space-between;
                        border-top: 1px solid #f2f2f2;
                        border-bottom: 1px solid #f2f2f2;
                        padding: 10px 0px;
                    }
                    .increaser-and-button{
                        flex-direction: column;
                        .product-increaser{
                            width: unset;
                            margin-bottom: 20px;
                        }
                        .product-buttons{
                            width: unset;
                            margin-bottom: 40px;
                            .btn{
                                background-color: #007FC7;
                                justify-content: space-between;
                                small{
                                    display: flex;
                                }
                            }
                            legend{
                                display: none;
                            }
                        }
                    }
                }
                .holder-characteristics{
                    h2{
                        margin-bottom: 30px;
                    }
                    .table{
                        justify-content: space-between;
                        .item{
                            width: 48%;
                            margin-right: 0px;
                        }
                    }
                }
            }
            .holder-opinions{
                .content{
                    .title-and-button{
                        .btn{
                            display: none;
                        }
                    }
                    .opinion-form{
                        width: unset;
                    }
                }
                .opinions{
                    width: calc(100vw - 24px);
                    max-width: unset;
                    padding: 20px 10px;
                    .opinion{
                        width: calc(100% - 32px) !important;
                    }
                }
                .bottom-button{
                    flex-direction: column-reverse;
                    margin-top: 30px;
                }
            }
            .holder-related{
                padding: 40px 0px 0px 0px;
                .content{
                    padding: 0px;
                    max-width: unset;
                    .title-and-controllers{
                        width: calc(100% - 32px);
                        @include side-paddings();
                    }
                    .product-related-swiper{
                        width: calc(100% - 32px);
                        padding: 20px 10px;
                    }
                }
            }
            .bottom-button{
                display: flex;
                padding: 0px 40px;
                .btn{
                    height: 58px;
                }
            }
        }

        // User template
        .user-template{
            .user-template-side{
                display: none;
            }
            .user-template-content{
                width: 100%;
                @include side-paddings();
                .content{
                    h1{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        svg{
                            display: flex;
                        }
                    }
                    // PROFILE
                    .user-profile{
                        .card{
                            flex-wrap: wrap;
                            overflow: hidden;
                            margin-bottom: 30px;
                            .user-card{
                                align-items: flex-start;
                                .img-holder{
                                    width: 85px;
                                    height: 85px;
                                }
                                .info-holder{
                                    width: calc(100% - 100px);
                                    margin-top: 10px;
                                    .userid-and-tag{
                                        .titles{
                                            margin-right: 0px;
                                        }
                                        .tag{
                                            display: none;
                                        }
                                    }
                                }
                                .table-row{
                                    width: 100%;
                                    margin: unset;
                                    padding: unset;
                                    margin-top: 20px;
                                    justify-content: space-between;
                                    .table-col{
                                        overflow: hidden;
                                        &:nth-child(1){
                                            display: none;
                                        }
                                        &:nth-child(2){
                                            width: 45%;
                                        }
                                        &:nth-child(3){
                                            width: 20%;
                                        }
                                        &:nth-child(4){
                                            width: 20%;
                                        }
                                        label{
                                            font-size: 12px;
                                        }
                                        span{
                                            font-size: 11px;
                                        }
                                    }
                                }
                            }
                        }
                        // Region
                        .region-and-seller{
                            flex-direction: column;
                            .is-holder{
                                width: 100%;
                            }
                            .holder-seller{
                                .seller-card{
                                    .info-holder{
                                        flex-wrap: wrap;
                                        .titles{
                                            h2{
                                                padding-left: 22px;
                                            }
                                        }
                                        .whatsapp{
                                            height: 40px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    // Nothing to show
                    .nothing{
                        height: calc(100vh - calc(110px + 120px));
                        h2{
                            text-align: center;
                        }
                        p{
                            width: unset;
                        }
                        .search-keyword{
                            width: 90%;
                        }
                    }
                }
            }
        }

        // ADDRESS CARD
        .address-card{
            .table-row{
                padding-left: 0px;
            }
        }

        // ADDRESSES SECTION
        #addresses{
            .title-and-button{
                display:flex;
                flex-direction: column;
                h1{
                    width: 100%;
                    margin-bottom: 20px;
                }
                .btn{
                    width: 150px;
                    height: 40px;
                    align-self: flex-end;
                }
            }
            .addresses-holder{
                flex-direction: column;
                .address-card{
                    width: calc(100% - 32px);
                }
            }
        }


        // PANEL PWA
        #panel-pwa{
            padding-top: 30px;
            @include side-paddings();
            h1{
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 18px;
                margin-bottom: 20px;
                padding-right: 10px;
                font-weight: 400;
                svg{
                    &:first-child{
                        margin-right: 10px;
                        color: #EB6B2F !important;
                    }
                    &:last-child{
                        position: absolute;
                        right: 20px;
                    }
                }
            }
            .instructions-holder{
                img{
                    width: 100%;
                }
            }
            .video-holder{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
            }
            & + .close-btn{
                display: none !important;
            }
        }

        // PANEL MENU
        #panel-menu{
            padding: 0px 0px 0px 0px;
            display: flex;
            flex-direction: column;
            flex: 1;
            max-height: fit-content;
            .in-store{
                display: flex;
                background-color: #1B97D5;
                width: 100%;
                height: 36px;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                color: #fff;
                cursor: pointer;
                font-size: 14px;
                margin-bottom: 20px;
                svg{
                    color: #fff !important;
                    margin-right: 5px;
                }
            }
            label{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-bottom: 15px;
                span{
                    margin-left: 10px;
                }
            }
            nav{
                @include side-panel-paddings();
                display: flex;
                flex-direction: column;
                a{
                    border-bottom: 1px solid #F2F2F2;
                    height: 55px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    text-decoration: none;
                    color: #505050;
                    cursor: pointer;
                    position: relative;
                    span{
                        margin-left: 10px;
                    }
                    svg{
                        color: #898989;
                        &:last-child{
                            position: absolute;
                            right: 0px;
                        }
                    }
                    &.pwa-isntall-btn{
                        display: none;
                    }
                }
            }
            .promo-applier{
                max-width: unset;
                flex: 1;
                border-radius: 0px;
                h2{
                    font-size: 18px;
                    line-height: 24px;
                    margin: 0px;
                    margin-bottom: 5px;
                }
                p{
                    text-align: center;
                    line-height: 18px;
                    font-size: 14px;
                    margin-bottom: 5px;
                }
            }
        }

        // PANEL PRODUCTS
        #panel-products{
            background-color: #F9FCFE;
            height: 100%;
            padding-top: 30px;
            .flyout-categories{
                display: flex;
                flex-direction: column;
                h2{
                    font-size: 20px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    @include side-panel-paddings();
                    margin-bottom: 20px;
                    svg{
                        display: flex;
                        width: 20px !important;
                        margin-right: 10px;
                        &:last-child{
                            position: absolute;
                            right: 0px;
                        }
                    }
                }
                .categories-and-promos{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    .holder-categories{
                        display: flex;
                        flex-direction: row;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        width: unset;
                        @include side-panel-paddings();
                        p{
                            display: flex;
                            font-size: 14px;
                            line-height: 18px;
                            margin-bottom: 20px;
                            padding-right: unset;
                        }
                        .item{
                            display: flex;
                            flex-direction: column;
                            width: 90px;
                            margin-bottom: 15px;
                            align-items: center;
                            justify-content: center;
                            cursor: pointer;
                            .img-holder{
                                width: 70px;
                                height: 70px;
                                margin-bottom: 5px;
                            }
                            label{
                                display: flex;
                                margin: 0px;
                                font-size: 14px;
                            }
                        }
                    }
                    #promos-swiper{
                        margin-top: 20px;
                    }
                }
            }
            & + .close-btn{
                display: none !important;
            }
        }

        // PANEL COLLECTIONS
        #panel-collections{
            @include side-panel-paddings();
            padding-top: 30px;
            padding-right: 10px;
            .flyout-collections{
                h2{
                    font-size: 20px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 20px;
                    width: 100%;
                    svg{
                        display: flex;
                        width: 20px;
                        margin-right: 10px;
                        &:last-child{
                            position: absolute;
                            right: 0px;
                            margin-right: 0px;
                        }
                    }
                }
                p{
                    padding-right: unset;
                    font-size: 14px;
                    line-height: 18px;
                    padding-right: 0px;
                }
                .items{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    margin-top: 20px;
                    button{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        width: 150px;
                        height: 150px;
                        background-repeat: no-repeat;
                        background-size: cover;
                        position: relative;
                        border-radius: 20px;
                        overflow: hidden;
                        &::before{
                            position: absolute;
                            top: 0;
                            left: 0;
                            right: 0;
                            bottom: 0;
                            background-color: rgba(0, 20, 39, 0.55);
                            content: ' ';
                        }
                        span{
                            color: #FFFFFF;
                            position: relative;
                            font-size: 22px;
                        }
                    }
                }
            }
            & + .close-btn{
                display: none !important;
            }
        }

        // PANEL USERS
        #user-panel{
            padding-top: 40px;
            h1{
                font-size: 18px;
                margin-bottom: 20px;
                padding-right: 10px;
            }
            .user-card{
                width: auto;
            }
            hr{
                margin: 15px 0px 0px 5px;
            }
            nav{
                a{
                    position: relative;
                    svg{
                        &:last-child{
                            position: absolute;
                            right: 0px;
                        }
                    }
                }
            }
        }

        // PANEL SEARCH
        #panel-search{
            padding-top: 40px;
            background-color: #F9FCFE;
            height: 100%;
            @include side-panel-paddings();
            h2{
                display: flex;
                flex-direction: row;
                font-size: 18px;
                align-items: center;
                position: relative;
                svg{
                    margin-right: 10px;
                    &:last-child{
                        position: absolute;
                        right: 0px;
                        margin-right: unset;
                    }
                }
            }
            .search-area{
                display: flex;
                flex-direction: column;
                height: calc(100% - 30px);
                justify-content: center;
                .search-keyword{
                    margin-bottom: 50px;
                }
                .btn{
                    height: 50px;
                    margin-bottom: 15px;
                }
            }
        }

        // PANEL ADVANCED SEARCH
        #panel-advanced{
            background-color: #F9FCFE;
            height: 100%;
            padding: 40px 0px;
            .flyout-search{
                .content-area{
                    @include side-panel-paddings();
                    width: auto;
                    max-width: unset;
                    .search-keyword{
                        display: none;
                    }
                    .search-categories{
                        margin-bottom: 30px;
                        .btn{
                            width: 30%;
                            font-size: 12px;
                            height: 30px;
                            text-decoration: none;
                        }
                    }
                    .search-prices{
                        margin-bottom: 30px;
                        h2{
                            font-size: 14px;
                        }
                        .range-holder{
                            .range-slider-rail{
                                width: 97%;
                            }
                            &::before, &::after{
                                width: 12px;
                                height: 12px;
                            }
                        }
                    }
                    .materials-and-colors{
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        .search-materials{
                            width: 100%;
                            h2{
                                font-size: 14px;
                                display: flex;
                                width: 100%;
                                justify-content: center;
                                margin-bottom: 20px;
                            }
                            .custom-checkbox{
                                span{
                                    font-size: 12px;
                                }
                            }
                        }
                        .search-colors{
                            display: none;
                        }
                    }
                    .buttons-holder{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        width: 220px;
                        align-self: center;
                        margin-top: 30px;
                        .primary{
                            height: 50px;
                        }
                        .link{}
                    }
                }
            }
        }

        // PANEL LOGIN
        #panel-login{
            padding: 0px 0px;
            background-color: #001427;
            height: 100%;
            .area-login{
                @include side-panel-paddings();
                padding-top: 40px;
                padding-bottom: 20px;
                background-color: #FFF;
                h2{
                    font-size: 18px;
                    margin-bottom: 20px;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    svg{
                        margin-right: 10px;
                    }
                }
            }
            .area-register{
                @include side-panel-paddings();
                padding-top: 30px;
                padding-bottom: 30px;
                height: 100%;
                h2{
                    font-size: 18px;
                    margin-bottom: 10px;
                }
                p{
                    font-size: 14px;
                    line-height: 18px;
                }
            }
        }

        // CART STYLES
        #cart{
            min-height: calc(100vh - #{$header-height});
            overflow: auto;
            // CART LIST
            .cart-list{
                border-radius: unset;
                .cart-list-content{
                    max-height: calc(100vh - 460px);
                    overflow: auto;
                }
            }
            // CART ITEM
            .cart-item{
                .content-holder{
                    position: relative;
                    h2{
                        font-size: 14px;
                        margin-bottom: 10px;
                        width: calc(100% - 40px);
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    .column{
                        width: 100% !important;
                        flex-direction: row;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        small{
                            font-size: 12px;
                            width: 100%;
                            margin-bottom: 0px;
                        }
                        span{
                            font-size: 12px;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-between;
                            width: 100%;
                            b{
                                font-size: 12px;
                            }
                        }
                        label{
                            font-size: 12px;
                        }
                        &:last-of-type{
                            margin-top: 5px;
                            label{
                            }
                            .product-increaser{
                                width: 50%;
                            }
                            .product-buttons{
                                width: 40%;
                                legend{
                                    b{
                                        font-size: 18px;
                                    }
                                }
                            }
                        }
                    }
                    .icon-detelete{
                        position: absolute;
                        top: -8px;
                        right: 0px;
                        width: 32px;
                        height: 32px;
                        padding: 0px;
                        background-color: #E8F5FB;
                        border-radius: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        svg{
                            width: 16px;
                        }
                    }
                }
            }
            // CART TEMPLATE
            .cart-template{
                flex-direction: column;
                section{
                    flex-direction: column;
                    width: auto;
                    align-items: center;
                    &:first-child{
                        padding: unset;
                        padding-top: 20px;
                    }
                    // Se convierte en el panel bottom en Mobile!
                    &:last-child{
                        padding: unset;
                        width: unset;
                        position: fixed;
                        left: 0px;
                        right: 0px;
                        top: calc(100% - 80px);
                        border-radius: unset;
                        width: auto;
                        border-top-left-radius: 25px;
                        border-top-right-radius: 25px;
                        overflow: hidden;
                        box-shadow: 0px -4px 6px rgba(80, 80, 80, 0.24);
                        align-items: center;
                        background-color: #FFF;
                        flex-direction: column-reverse;
                        @include side-paddings();
                        padding-top: 10px;
                        transition: all 0.15s;
                        background-color: #fff;
                    }
                    .content-header{
                        flex-wrap: wrap;
                        h1{
                            width: 100%;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            margin-bottom: 20px;
                        }
                        .btn{
                            justify-self: flex-end;
                            &.tertiary{
                                display: flex;
                            }
                        }
                        //STEPPER
                        $steps-count: 4;
                        $step-bullet-width-height: 24px;
                        $step-line-width: 76px;
                        .cart-stepper{
                            display: none;
                            width: 100%;
                            margin-top: 10px;
                            span{
                                &::before{
                                    width: $step-line-width;
                                }
                                &:first-of-type{
                                    &::before{
                                        display: none;
                                    }
                                }
                                &.current{
                                    background-color: #FF7433;
                                    &::before{
                                        background-color: #FF7433;
                                    }
                                }
                            }
                        }
                    }
                    .content-area{
                        margin-top: 20px;
                        height: calc(100vh - 400px);
                        padding: 0px 20px;
                        // #ADDRESSES
                        #addresses{
                            .title-and-button{
                                .btn{
                                    background-color: #FFF;
                                    border: 1px solid #1289C5;
                                    color: #1289C5;
                                    font-size: 12px;
                                    svg{
                                        display: flex;
                                        margin-right: 10px;
                                    }
                                }
                            }
                        }
                        #stores{
                            margin-top: 20px;
                            width: auto;
                            align-self: unset;
                        }
                    }

                    // CART SUMMARY
                    .cart-summary{
                        flex-direction: column-reverse;
                        width: 100%;
                        padding-top: 0px;
                        box-shadow: unset !important;
                        .btn{
                            background-color: #007FC7;
                            margin-top: 0px;
                            margin-bottom: 30px;
                            display: flex;
                        }
                        .cart-motivation{
                            display: flex;
                            width: 100%;
                            margin-bottom: 20px;
                        }
                    }
                    // CART MOTIVATION
                    .cart-motivation{
                        width: auto;
                        display: none;
                    }
                    // CART ACTIVE PROMO
                    .cart-active-promo{
                        display: none;
                    }
                    // MINICART
                    .cart-minicart{
                        h2{
                            width: 100%;
                        }
                        h3{
                            width: 100%;
                        }
                        .scroll-area{
                            height: 200px !important;
                            .cart-item{
                                .content-holder{
                                    .column{
                                        &:last-of-type{
                                            .product-buttons{
                                                legend{
                                                    display: flex;
                                                    flex-direction: row;
                                                    margin-top: -20px;
                                                    span{
                                                        display:flex;
                                                    }
                                                    b{
                                                        display:flex;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                // CART-STEP-1
                &.cart-step-1{
                    section{
                        .content-header{
                            h1{
                                width: 100%;
                            }
                            .btn{
                                display: none !important;
                            }
                        }
                        &:last-child{
                            .buttons-holder{
                                width: 100%;
                                flex-direction: row;
                                flex-wrap: wrap;
                                justify-content: space-between;
                                margin-bottom: 20px;
                                .btn{
                                    &.link{
                                        margin: 10px 0px 10px 0px;
                                        height: unset;
                                        width: 46%;
                                        display: flex;
                                        border: 1px solid #007FC7;
                                        height: 40px;
                                        text-decoration: none;
                                        &:last-of-type{
                                            display: flex;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                // CART-STEP-2
                // CART-STEP-3
                &.cart-step-2,
                &.cart-step-3{
                    section{
                        .content-header{
                            h1{
                                width: 100%;
                            }
                            .btn{
                                display: none !important;
                            }
                        }
                        .content-area{
                            .title-and-button{
                                flex-direction: row;
                                h1{
                                    width: unset;
                                    margin-bottom: unset;
                                    svg{
                                        display: none !important;
                                    }
                                }
                            }
                            .method-holder{
                                .method-content{
                                    flex-direction: column;
                                    .method-block{
                                        h2{
                                            margin-top: 20px;
                                            font-size: 18px;
                                        }
                                        .deposit-holder{
                                            padding-right: 0px;
                                            .deposit-row{
                                                padding-right: 0px;
                                            }
                                        }
                                        .banks-holder{
                                            padding: 0px 10px 0px 0px;
                                            width: 100%;
                                            .bank-row{
                                                img{
                                                    width: 100px;
                                                }
                                                span{
                                                    font-size: 14px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        &:last-child{
                            overflow: hidden;
                            top: calc(100% - 130px); // Más espacio porque hay 3 botones
                            .buttons-holder{
                                width: 100%;
                                flex-direction: row;
                                flex-wrap: wrap;
                                justify-content: space-between;
                                margin-bottom: 20px;
                                .btn{
                                    &.link{
                                        margin: 10px 0px 10px 0px;
                                        height: unset;
                                        width: 46%;
                                        display: flex;
                                        border: 1px solid #007FC7;
                                        height: 40px;
                                        text-decoration: none;
                                        &:last-of-type{
                                            display: flex;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                // CART STEP 4
                &.cart-step-4{
                    section{
                        &:last-of-type{
                            display: none;
                        }
                        .content-header{
                            h1{
                                width: 100%;
                            }
                            .btn{
                                display: none;
                            }
                        }
                    }
                }
                // Expande el panel mobile:
                &.expanded{
                    .bg{
                        visibility: visible;
                        opacity: 1;
                        content: ' ';
                        position: fixed;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100vh;
                        z-index: 2;
                        background-color: rgba(0,0,0,0.75);
                        cursor: pointer;
                    }
                    section{
                        padding-top: 20px;
                        &:last-child{
                            max-height: calc(100vh - 80px);
                            z-index: 10;
                            padding-top: 40px;
                            flex-direction: column;
                            bottom: 0px;
                            top: unset;
                            &::after{
                                width: 70px;
                                height: 4px;
                                border-radius: 2px;
                                content: ' ';
                                background-color: #898989;
                                top: 15px;
                                position: absolute;
                            }
                            .cart-summary{
                                flex-direction: row !important;
                                flex-wrap: wrap;
                                justify-content: space-between;
                                article{
                                    width: 48%;
                                }
                                .btn{
                                    margin-bottom: 10px;
                                }
                            }
                            /*
                            .cart-motivation{
                                display: flex;
                            }
                            */
                        }
                    }
                }
            }
            // STEP-AUTH
            .cart-step-auth{
                @include side-paddings();
                padding-top: 40px;
                padding-bottom: 40px;
                .content{
                    max-width: unset;
                    .header{
                        h1{
                            font-size: 24px;
                        }
                        .btn{
                            width: 180px;
                            font-size: 14px;
                        }
                    }
                    .login-holder{
                        height: auto;
                        #login{
                            flex-direction: column;
                            height: unset;
                            .area-login{
                                width: unset;
                                margin-bottom: 40px;
                            }
                            .area-register{
                                width: unset;
                            }
                        }
                    }
                    .btn{
                        margin-top: 0px;
                    }
                }
            }

            // CART EMPTY
            .cart-empty{
                padding-bottom: 0px;
                height: auto !important;
                //display: none;
                .cart-empty-message{
                    .btn{
                        margin-bottom: 20px;
                    }
                }
                .shuffled-products{
                    .slide-title{
                        margin-bottom: 15px;
                    }
                    .holder{
                        .slide-item{
                            padding: 15px;
                            max-width: unset !important;
                            width: calc(50% - 30px) !important;
                        }
                    }
                }
            }
        }

        // REGISTER
        #register{
            padding-bottom: 60px;
            h1{
                width: 100%;
                font-size: 24px;
                margin-bottom: 20px;
                margin-top: 40px;
            }
            .content{
                max-width: unset;
                @include side-paddings();
                flex-direction: column;
                .column{
                    width: unset;
                    p{
                        display: none;
                    }
                    img{
                        display: none;
                    }
                    .form-area{
                        .custom-input{
                            width: 100%;
                        }
                    }
                    legend{
                        text-align: center;
                    }
                    &:nth-of-type(2){
                        h1{
                            display: none;
                        }
                    }
                }
            }
        }
        // REGISTER SUCCESS
        .register-success{
            @include side-paddings();
            .content{
                max-width: unset;
                flex-direction: column;
                .column{
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    p{
                        width: unset;
                    }
                    b{
                        display: flex;
                        flex-direction: row;
                        width: 100%;
                        font-weight: 400;
                        margin-bottom: 10px;
                    }
                    a{
                        color: #1B97D5;
                        text-decoration: none;
                    }
                    img{
                        display: flex;
                        margin-top: 40px;
                        width: 70%;
                        align-self: flex-end;
                    }
                }
            }
        }

        // FORGOT PASSWORD
        .forgot-password{
            @include slide-bottom-panel();
            @include side-paddings();
            padding-bottom: 40px;
            padding-top: 40px;
            h2{
                text-align: center;
            }
            &.slide{
                bottom: 0px;
            }
        }

        // VERIFY ACCOUNT:
        .verify-account{
            .content{
                max-width: unset;
                @include side-paddings();
                #loading{
                    justify-content: flex-start;
                    width: 32px;
                    flex: unset;
                }
                h1{
                    justify-content: center;
                    font-size: 20px;
                }
                p{
                    text-align: center;
                    font-size: 14px;
                }
            }
        }

        // GUEST USER
        .guest-user{
            @include slide-bottom-panel();
            @include side-paddings();
            padding-bottom: 40px;
            padding-top: 60px;
            &.slide{
                bottom: 0px;
            }
            h2{
                font-weight: 400;
                font-size: 18px;
                margin-bottom: 20px;
            }
            p{
                font-weight: 200;
                font-size: 14px;
                margin-bottom: 40px;
            }
            .custom-input{
                .field-wrapper{
                    border-radius: 20px;
                    background-color: #F2F2F2;
                    border-color: #C4C4C4;
                }
            }
            .btn-holder{
                margin-top: 30px;
                .btn{
                    height: 50px;
                }
            }
        }

        // ORDER
        .order-box{
            .collapser-header{
                .content{
                    .table-row{
                        flex-wrap: wrap;
                        .table-col{
                            width: 40%;
                            margin-bottom: 15px;
                            label{
                                font-size: 12px;
                            }
                            span{
                                font-size: 14px;
                            }
                            &:first-of-type{
                                display: flex;
                            }
                            &:nth-of-type(5){
                                margin-bottom: 0px;
                            }
                        }
                    }
                }
            }
            .order-detail{
                .order-detail-title{
                    margin: 20px 0px 20px 0px;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    span{
                        font-size: 12px;
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin: 0px;
                        &:nth-of-type(1){
                            margin-bottom: 15px;
                            margin-top: 20px;
                            margin-left: 0px;
                            svg{
                                margin-right: 5px;
                            }
                        }
                        &:nth-of-type(2),
                        &:nth-of-type(3){
                            margin: 0px;
                            width: 50%;
                        }
                        &:nth-of-type(3){
                            justify-content: flex-end;
                        }
                    }
                }
                .order-row{
                    flex-wrap: wrap;
                    &:last-child{
                    }
                    .img-holder{
                        width: 70px !important;
                        height: 70px !important;
                        margin-right: 0px;
                        align-self: flex-start;
                    }
                    .sections{
                        width: calc(100% - 80px);
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        section{
                            flex-direction: row;
                            justify-content: space-between;
                            align-items: center;
                            flex-wrap: wrap;
                            margin-bottom: 10px;
                            label{
                                font-size: 11px;
                                margin-bottom: 0px;
                            }
                            span{
                                font-size: 10px;
                                margin-left: 4px;
                                font-weight: 500;
                            }
                            &:nth-of-type(1){
                                width: 100%;
                                label, span{
                                    width: 100%;
                                    margin-left: 0px;
                                    height: 12px;
                                    overflow: hidden;
                                }
                                label{
                                    font-size: 11px;
                                }
                                span{
                                    font-size: 11px;
                                    font-weight: 300;
                                }
                            }
                            &:nth-of-type(2){
                                display: none;
                            }
                            &:nth-of-type(3),
                            &:nth-of-type(4),
                            &:nth-of-type(5),
                            &:nth-of-type(6){
                                width: 55%;
                            }
                            &:nth-of-type(3),
                            &:nth-of-type(5){
                                width: 44%;
                            }
                            &:nth-of-type(2),
                            &:nth-of-type(4),
                            &:nth-of-type(6){
                                justify-content: flex-end;
                            }
                        }
                    }
                }
            }
        }

        // FOOTER
        footer{
            .footer-perks{
                .title{
                    display: flex;
                    width: 100%;
                    font-size: 22px;
                    flex-direction: row;
                    justify-content: center;
                    margin-bottom: 10px;
                }
                flex-direction: column;
                @include side-paddings();
                gap: 5px;
                padding-top: 35px;
                padding-bottom: 35px;
                background-color: #F9FCFE;
                section{
                    width: 100%;
                    &::after{
                        display: none;
                    }
                    h2{
                        font-size: 14px;
                        color: #1289C5;
                        margin-bottom: 10px;
                        svg{
                            margin-right: 5px;
                            width: 18px !important;
                        }
                    }
                    p{
                        display: none;
                    }
                    small, .btn{
                        font-size: 12px;
                        padding-left: 25px;
                        margin-bottom: 10px;
                        margin-top: -10px;
                    }
                }
            }
            .footer-sitemap{
                flex-direction: column;
                margin: 0px;
                padding: 0px;
                background-color: #1289C5;
                position: relative;
                nav{
                    background: #1289C5;
                    color: #FFF;
                    @include side-paddings();
                    h2{
                        font-size: 16px;
                        margin: 0px;
                        height: 48px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        position: relative;
                        justify-content: space-between;
                        &::after{
                            content: ' ';
                            position: absolute;
                            height: 1px;
                            background-color: #FFFFFF50;
                            width: 100%;
                            bottom: 0px;
                        }
                        &:last-child{
                            &::after{
                                display: none;
                            }
                        }
                    }
                    section{
                        display: none;
                        transition: all 0.35s;
                        &.expanded{
                            display: flex;
                        }
                        a{
                            font-size: 12px;
                            color: #FFF;
                            display: flex;
                            height: 30px;
                            margin-bottom: 0px;
                            align-items: center;
                            text-decoration: none;
                        }
                    }
                    &:first-child{
                        h2{
                            display: flex;
                        }
                        section{
                            img{
                                display: none;
                            }
                        }
                    }
                }
            }
            .footer-copyright{
                background-color: #003D5C;
                font-size: 12px;
            }
            .contact-area{
                h3{
                    color: #FFF;
                    text-align: center;
                    margin-bottom: 10px;
                    &::after{
                        display: none;
                    }
                }
                .phone{
                    margin-bottom: 10px;
                    color: #fff;
                    text-align: center;
                    font-size: 14px;
                    margin-top: 10px;
                }
                .social-links{
                    justify-content: center;
                    margin-bottom: 30px;
                    a.social-link{
                        margin-bottom: 0px;
                        display: flex;
                        svg{
                            color: #FFF !important;
                        }
                    }
                }
            }
            .promo-applier{
                border-radius: 24px;
                max-width: unset;
                width: auto;
                align-self: center;
                margin-left: 20px;
                margin-bottom: 20px;
                width: calc(100% - 100px);
            }
        }
    }
}